import sideBar6 from '../../assets/utils/images/sidebar/city1.jpg'

export interface UIState {
  backgroundColor: string
  headerBackgroundColor: string
  enableMobileMenu: boolean
  enableMobileMenuSmall: boolean
  enableBackgroundImage: boolean
  enableClosedSidebar: boolean
  enableFixedHeader: boolean
  enableHeaderShadow: boolean
  enableSidebarShadow: boolean
  enableFixedFooter: boolean
  enableFixedSidebar: boolean
  colorScheme: string
  backgroundImage: string
  backgroundImageOpacity: string
  enablePageTitleIcon: boolean
  enablePageTitleSubheading: boolean
  enablePageTabsAlt: boolean
}

export const UIInitialState: UIState = {
  backgroundColor: '',
  headerBackgroundColor: process.env.REACT_APP_HEADER_BACKGROUND_COLOR || '',
  enableMobileMenu: false,
  enableMobileMenuSmall: false,
  enableBackgroundImage: false,
  enableClosedSidebar: false,
  enableFixedHeader: true,
  enableHeaderShadow: true,
  enableSidebarShadow: true,
  enableFixedFooter: true,
  enableFixedSidebar: true,
  colorScheme: 'white',
  backgroundImage: sideBar6,
  backgroundImageOpacity: 'opacity-06',
  enablePageTitleIcon: true,
  enablePageTitleSubheading: true,
  enablePageTabsAlt: true,
}
