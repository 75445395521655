import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { UserInitialState, LoggedInUserActions } from '.'

export default reducerWithInitialState(UserInitialState)
.case(LoggedInUserActions.setLoggedInUser, (state, loggedInUser) => {

  return {
    ...state,
    ...loggedInUser,
  }
})
