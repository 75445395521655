import * as firebase from 'firebase/app'
import 'firebase/auth'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import config from './config/config'
import { LoggedInUserActions } from './store/loggedInUser'

// Initialize Firebase
firebase.initializeApp(config.firebaseConfig)

const withAuth = <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) => {
    const dispatch = useDispatch()
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    firebase.auth().onAuthStateChanged(function(firebaseUser) {
      setIsAuthenticated(firebaseUser != null)

      if (firebaseUser == null || firebaseUser.email == null) {
        if (
          window.location.hostname === 'localhost' &&
          process.env.REACT_APP_LOCAL_USER_EMAIL &&
          process.env.REACT_APP_LOCAL_USER_PASS
        ) {
          return firebase
            .auth()
            .signInWithEmailAndPassword(process.env.REACT_APP_LOCAL_USER_EMAIL, process.env.REACT_APP_LOCAL_USER_PASS)
        } else {
          return (window.location.href = `/sso#/signin/${window.encodeURIComponent(window.location.href.replace(window.location.origin, ''))}`)
        }
      }
      dispatch(
        LoggedInUserActions.setLoggedInUser({
          id: firebaseUser.uid,
          email: firebaseUser.email,
          photoURL: firebaseUser.photoURL,
          hasEmailPassAccount: firebaseUser.providerData.some(p => p != null && p.providerId === 'password'),
        })
      )
    })

    return isAuthenticated ? <Component {...props} /> : <></>
  }

export default withAuth
