import React from 'react'
import './assets/base.scss'

import Main from './core/templates/Main'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { appReducer, AppState } from './store'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ja from '@gunosy/media-admin-components/locales/ja.json'
import withApollo from './withApollo'
import withAuthN from './withAuthN'
import withAuthZ from './withAuthZ'

const logger = (createLogger as any)()
const middleware =
  process.env.NODE_ENV === 'development' ? composeWithDevTools(applyMiddleware(logger, thunk)) : applyMiddleware(thunk)
const store = createStore(appReducer, {}, middleware) as Store<AppState>
const MainApp = withAuthN(withApollo(withAuthZ(Main)))

i18n.use(initReactI18next).init({
  resources: {
    ja: { translation: ja },
  },
  lng: 'ja',
  fallbackLng: 'ja',
  interpolation: { escapeValue: false },
  nsSeparator: '::',
})

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <HashRouter>
        <MainApp />
      </HashRouter>
    </Provider>
  )
}

export default App
