
enum Role {
  Admin = 'admin',
  IAM_Admin = 'iam:admin',
  IAM_Viewer = 'iam:viewer',
  Insight_Admin = 'insight:admin',
  Insight_Editor = 'insight:editor',
  Insight_Viewer = 'insight:viewer',
  Insight_PublisherAdmin = 'insight:publisher_admin',
  Insight_PublisherEditor = 'insight:publisher_editor',
}

export default Role
