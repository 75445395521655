import cx from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import ResizeDetector from 'react-resize-detector'
import AppMain from '../../core/templates/AppMain'
import { SelectUIState } from '../../store/uiState'

// TODO: なんかわからんけど定義してあった。reduxにはなかった。値の変更もしてなかった
const closedSmallerSidebar = false

const Main: React.FC = () => {
  const uiState = useSelector(SelectUIState)
  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    enablePageTabsAlt,
  } = uiState
  return (
    <ResizeDetector
      render={({ width }) => (
        <div
          className={cx(
            'app-container app-theme-' + colorScheme,
            { 'fixed-header': enableFixedHeader },
            { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
            { 'fixed-footer': enableFixedFooter },
            { 'closed-sidebar': enableClosedSidebar || width < 1250 },
            {
              'closed-sidebar-mobile': closedSmallerSidebar || width < 1250,
            },
            { 'sidebar-mobile-open': enableClosedSidebar },
            { 'body-tabs-shadow-btn': enablePageTabsAlt }
          )}
        >
          <AppMain />
        </div>
      )}
    />
  )
}

export default Main
