import { combineReducers } from 'redux'
import { UIState } from './uiState'
import { UserState } from './loggedInUser'
import LoggedInUserReducers from './loggedInUser/reducers'
import UIStateReducers from './uiState/reducers'

export interface AppState {
  loggedInUser: UserState
  uiState: UIState
}

export const appReducer = combineReducers<AppState>({
  loggedInUser: LoggedInUserReducers,
  uiState: UIStateReducers,
})
