import { TableCellRenderer } from 'react-table'
import { toast } from 'react-toastify'
import { Period } from '../core/atoms/PeriodSelector'
import { Option } from '../core/atoms/Select'
import { CellType } from '../core/atoms/TableCell'
import { ReleaseMonth } from './time'

export type Optional<T> = {
  [P in keyof T]?: T[P]
}
export type ErrorOf<T> = {
  [P in keyof T]?: string
}
export type BooleanOf<T> = {
  [P in keyof T]?: boolean
}
export type ObjectOf<T, K> = {
  [P in keyof T]: K
}
// 時系列系、table/graphで用いる
export type TimeSeries<T extends {}> = T & {
  date: string
}
// WithMediumId
export type WithMediumId<T extends {}> = T & {
  mediumId: number
}
export interface FieldDisplayOption {
  width?: number
  header?: TableCellRenderer
  title: string
  formatter?: (num: number) => string // ex) パーセント表示など
  prefix?: string // ex) ￥,$
  suffix?: string // ex) %
  cellType?: CellType // セルの表示形式
  cell?: TableCellRenderer // カスタムセル（上記cellTypeで表現できない場合）
  sortable?: boolean // ソートできるか
}

// ObjectOf<T, FieldDisplayOption> なObjectから SelectOptionに変換する
export function mapFieldDisplayOptionObjectToSelectOption<T>(fdo: Map<keyof T, FieldDisplayOption>): Option[] {
  return [...fdo.keys()].map(key => ({ label: fdo.get(key)?.title as string, value: key }))
}

// Keyを入れ替える
export function replaceKey<T extends { [key: string]: any }>(
  headerNameKeyMap: Map<keyof T, string>,
  data: T
): { [key: string]: string } {
  let acc: { [key: string]: any } = {}
  for (const [key, value] of headerNameKeyMap) {
    acc[value] = data[key]
  }
  return acc
}

// 足し上げる
export interface ClickDemographics {
  genderId: number // 1,2
  ageId: number // 1-6
  click: number
}
export interface Watch3sDemographics {
  genderId: number // 1,2
  ageId: number // 1-6
  watch3s: number
}
export function sumClickDemographics(acc: [number[], number[]], cd: ClickDemographics): [number[], number[]] {
  if (cd.ageId == 0 || cd.genderId == 0) {
    return acc
  }
  acc[cd.genderId - 1][cd.ageId - 1] = cd.click + (acc[cd.genderId - 1][cd.ageId - 1] || 0)
  return acc
}
export function sumWatch3sDemographics(acc: [number[], number[]], cd: Watch3sDemographics): [number[], number[]] {
  if (cd.ageId == 0 || cd.genderId == 0) {
    return acc
  }
  acc[cd.genderId - 1][cd.ageId - 1] = cd.watch3s
  return acc
}

export function calcPercent(numer: number, denom: number): number {
  if (denom === 0) {
    return 0
  }
  return Number((Math.floor((numer / denom) * 1000) / 10).toFixed(1)) // 小数第1位未満切り捨て
}

export function fmtIdentify(num: number): string {
  return num.toString()
}

export function sum(x: number, y: number): number {
  return x + y
}

export async function graphqlErrorHandler(func: () => Promise<void>) {
  try {
    await func()
  } catch (e) {
    console.error(e)
    if (e.graphQLErrors) {
      for (let err of e.graphQLErrors) {
        switch (err.extensions?.code) {
          case 'FORBIDDEN':
            toast.warn(`権限がありません: ${err.extensions.message}`)
            break
        }
      }
    }
    return Promise.reject(e)
  }
}

export function getRageDate(s: any = new Date(), e: any = new Date()): Date[] {
  let ret = []
  let cursorT = new Date(s)
  const eD = new Date(e)
  while (cursorT.getTime() <= eD.getTime()) {
    ret.push(cursorT)
    cursorT = new Date(cursorT.getTime() + 1000 * 60 * 60 * 24)
  }
  return ret
}

export function foldToIndice<T, K extends T>(
  range: T[],
  indice: number[],
  folder: (calculated: K, base: T) => K,
  initialize: () => K
): K[] {
  return indice.reduce((acc: K[], e: number, i) => {
    let ret = initialize()
    // 最後
    if (i === indice.length - 1) {
      ret = range.slice(e, range.length).reduce(folder, initialize())
    } else {
      ret = range.slice(e, indice[i + 1]).reduce(folder, initialize())
    }
    acc.push(ret)
    return acc
  }, [])
}

export function foldPeriod(range: string[], identifier: (dt: Date) => number): [string[], number[]] {
  const indice: number[] = []
  return [
    range.reduce((acc: string[], e: string, i): string[] => {
      if (acc.length === 0) {
        indice.push(i)
        return [e]
      }
      if (identifier(new Date(acc[acc.length - 1])) !== identifier(new Date(e))) {
        indice.push(i)
        acc.push(e)
      }
      return acc
    }, []),
    indice,
  ]
}

export function getFoldPeriodIdentifier(period: Period): (dt: Date) => number {
  switch (period) {
    case Period.Daily:
      return getUniqueDateNum
    case Period.Weekly:
      return getUniqueWeekNum
    case Period.Monthly:
      return getUniqueMonthNum
  }
}
// 日別
export function getUniqueDateNum(dt: Date): number {
  const zero2 = zeroPadding.bind({}, 2)
  const d = new Date(dt)
  return Number(`${d.getFullYear()}${zero2(d.getMonth() + 1)}${zero2(d.getDate())}`)
}
// 週別
export function getUniqueWeekNum(dt: Date): number {
  const d = new Date(dt)
  return d.getFullYear() * 100 + getISO8601WeekNum(dt)
}
// 月別
export function getUniqueMonthNum(dt: Date): number {
  const zero2 = zeroPadding.bind({}, 2)
  const d = new Date(dt)
  return Number(`${d.getFullYear()}${zero2(d.getMonth() + 1)}`)
}

export function getISO8601WeekNum(dt: Date): number {
  const d = new Date(dt)
  let dowOffset = 0 // starting Day (0-6)
  const newYear = new Date(d.getFullYear(), 0, 1)
  let day = newYear.getDay() - dowOffset
  day = day >= 0 ? day : day + 7
  const daynum =
    Math.floor(
      (d.getTime() - newYear.getTime() - (d.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000
    ) + 1
  let weeknum
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1
    if (weeknum > 52) {
      let nYear = new Date(d.getFullYear() + 1, 0, 1)
      let nday = nYear.getDay() - dowOffset
      nday = nday >= 0 ? nday : nday + 7
      weeknum = nday < 4 ? 1 : 53
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7)
  }
  return weeknum
}

export function fmtCurrency(num: number): string {
  return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

export function fmtPercent(num: number): string {
  return (num * 100).toFixed(1)
}

export function calcCTR(click: number, imp: number): number {
  if (imp === 0) {
    return 0
  }
  return Number((Math.floor((click / imp) * 1000) / 1000).toFixed(3)) // 小数第3位未満切り捨て
}

export function divide(a: number, b: number, pow: number): number {
  if (b === 0) {
    return 0
  }
  const dividend = a / b
  return Number(dividend.toFixed(pow))
}

export function zeros(len: number): number[] {
  const ret = []
  for (let i = 0; i < len; i++) {
    ret.push(0)
  }
  return ret
}

export function calcCPM(revenue: number, click: number): number {
  if (click === 0) {
    return 0
  }
  return Number((Math.floor((revenue / click) * 1000 * 10) / 10).toFixed(1)) // 小数第1位未満切り捨て
}

export function truncate(str: string, num: number, _marker?: string) {
  let marker = _marker || '...'
  if (!str) {
    return ''
  }
  if (str.length >= num) {
    return str.substring(0, num) + marker
  } else {
    return str
  }
}

export function queryParse<T extends object>(
  text?: string,
  sep: string = '&',
  eq: string = '=',
  isDecode?: boolean
): T {
  text = text || window.location.search.substr(1)
  var decode = isDecode ? decodeURIComponent : (a: any) => a
  return text.split(sep).reduce<T>((obj: {}, v): T => {
    var pair = v.split(eq)
    let val = decode(pair[1])
    try {
      // 配列
      if (val.startsWith('[')) {
        val = JSON.parse(val)
      }
    } catch (e) {}
    //@ts-ignore
    obj[pair[0]] = val
    return obj as T
  }, {} as T)
}

export function queryStringify(value: {}, sep?: string, eq?: string, isEncode?: boolean) {
  sep = sep || '&'
  eq = eq || '='
  var encode = isEncode ? encodeURIComponent : (a: any) => a
  return Object.keys(value)
    .filter((key: string) => {
      //@ts-ignore
      return encode(value[key]) !== null && encode(value[key]) !== undefined
    })
    .map((key: string) => {
      //@ts-ignore
      let val = encode(value[key])
      if (typeof val === 'object') {
        val = JSON.stringify(val)
      }
      return key + eq + val
    })
    .join(sep)
}

export function filterObject(obj: {}, predicate: (value: any) => boolean) {
  let result = {}

  for (let key in obj) {
    //@ts-ignore
    if (obj.hasOwnProperty(key) && predicate(obj[key])) {
      //@ts-ignore
      result[key] = obj[key]
    }
  }
  return result
}

export function sortObject(unordered: object): object {
  return Object.keys(unordered)
    .sort()
    .reduce((acc, key) => {
      //@ts-ignore
      acc[key] = unordered[key]
      return acc
    }, {})
}

export function fmtYM_JP(_d: any): string {
  const d = new Date(_d)
  return `${d.getFullYear()}年${d.getMonth() + 1}月`
}
export function fmtYMD_JP(_d: any): string {
  const d = new Date(_d)
  return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`
}
export function fmtYM_HYPHEN(_d: any): string {
  const d = new Date(_d)
  const zero2 = zeroPadding.bind(undefined, 2)
  return `${d.getFullYear()}-${zero2(d.getMonth() + 1)}`
}
export function fmtMD(_d: any): string {
  const zero2 = zeroPadding.bind({}, 2)
  const d = new Date(_d)
  return `${zero2(d.getMonth() + 1)}/${zero2(d.getDate())}`
}
export function fmtMD_HMS(_d: any): string {
  const zero2 = zeroPadding.bind({}, 2)
  const d = new Date(_d)
  return `${zero2(d.getMonth() + 1)}/${zero2(d.getDate())} ${zero2(d.getHours())}:${zero2(d.getMinutes())}:${zero2(
    d.getSeconds()
  )}`
}
export function fmtYMD_HMS(_d: any): string {
  const zero2 = zeroPadding.bind({}, 2)
  const d = new Date(_d)
  return `${d.getFullYear()}/${zero2(d.getMonth() + 1)}/${zero2(d.getDate())} ${zero2(d.getHours())}:${zero2(
    d.getMinutes()
  )}:${zero2(d.getSeconds())}`
}
export function fmtYMD(_d: any): string {
  const zero2 = zeroPadding.bind({}, 2)
  const d = new Date(_d)
  return `${d.getFullYear()}-${zero2(d.getMonth() + 1)}-${zero2(d.getDate())}`
}
// 72 => '1:12'
export function fmtHMS(seconds: number): string {
  const zero2 = zeroPadding.bind({}, 2)
  const sec = zero2(seconds % 60)
  let min = Math.floor(seconds / 60)
  if (min >= 60) {
    const hour = Math.floor(min / 60)
    return `${hour}:${zero2(min % 60)}:${sec}`
  }
  return `${min}:${sec}`
}
export function zeroPadding(LEN: number, NUM: number) {
  return (Array(LEN).join('0') + NUM).slice(-LEN)
}

export function getMonthOptions(
  { untilThisMonth, fromThisMonth, asc }: { untilThisMonth?: string; fromThisMonth?: Date; asc?: boolean } = {
    untilThisMonth: ReleaseMonth,
    fromThisMonth: new Date(),
    asc: false,
  }
): any[] {
  let fromMonth = fromThisMonth || new Date()
  const until = new Date(untilThisMonth || ReleaseMonth)
  const pred = () => (asc ? until.getTime() >= fromMonth.getTime() : until.getTime() <= fromMonth.getTime())

  const options = []
  while (pred()) {
    let y = fromMonth.getUTCFullYear()
    let m = fromMonth.getUTCMonth() + 1
    const label = `${y}年${('00' + m).slice(-2)}月`
    const value = `${y}-${('00' + m).slice(-2)}`

    options.push({ label, value })
    // 入れ替え
    if (asc) {
      if (m === 12) {
        ++y
        m = 1
      } else {
        ++m
      }
    } else {
      if (m === 1) {
        --y
        m = 12
      } else {
        --m
      }
    }
    fromMonth = new Date(`${y}-${('00' + m).slice(-2)}`)
  }

  return options
}

export function getMediaOptions(): any[] {
  return [
    { value: 1, label: 'グノシー' },
    { value: 2, label: 'ニュースパス' },
    { value: 3, label: 'ルクラ' },
    { value: 9, label: 'auサービスToday' },
  ]
}

export function getMediaOptionsForCreation(): any[] {
  return [
    { value: 1, label: 'グノシー' },
    { value: 2, label: 'ニュースパス' },
    { value: 9, label: 'auサービスToday' },
  ]
}

// 自然数: input type="number" は 'e','+','.','-' の記号を受け付けてしまうので排除する
export function onKeyDownFilteringForNaturalNumberOnInputTypeNumberForm(
  e: React.KeyboardEvent<HTMLInputElement>
): void {
  if (e.keyCode === 69 || e.keyCode === 189 || e.keyCode === 190 || e.keyCode === 187) {
    e.preventDefault()
  }
}

// 正の整数: input type="number" は 'e','+','.','-' の記号を受け付けてしまうので排除する
export function onKeyDownFilteringForIntegerOnInputTypeNumberForm(e: React.KeyboardEvent<HTMLInputElement>): void {
  if (e.keyCode === 69 || e.keyCode === 189 || e.keyCode === 190) {
    e.preventDefault()
  }
}

// 正の数: input type="number" は 'e','+','.','-' の記号を受け付けてしまうので排除する
export function onKeyDownFilteringForPositiveFloatOnInputTypeNumberForm(
  e: React.KeyboardEvent<HTMLInputElement>
): void {
  console.log(e.keyCode)
  if (e.keyCode === 69 || e.keyCode === 189 || e.keyCode === 187) {
    e.preventDefault()
  }
}

export function convertToNumber(str: string): number | undefined {
  if (str !== '') {
    if (!isNaN(Number(str))) {
      return Number(str)
    }
  }
  return undefined
}
