import { DashboardHeader, HeaderDotsForAnnouncement, HeaderUserBox } from '@gunosy/media-admin-components'
import * as firebase from 'firebase/app'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import SweetAlert from 'sweetalert-react'
import { useAnnouncementsQuery, useReadAnnouncementsMutation } from '../../gql/graphql-client-api'
import { SelectLoggedInUser } from '../../store/loggedInUser'
import { SelectUIState, UIStateActions } from '../../store/uiState'
import Nav from '../molecules/VerticalNavWrapper'
import AppSidebar from '../organisms/DashboardSidebar'

interface AnnouncementModalModel {
  id: number
  title: string
  body: string
  updatedAt: string
  closeButtonText?: string | null
}
const now = new Date().toISOString()

const Dashboard: React.FC = ({ children }) => {
  const history = useHistory()
  const [warnUnreadAnnouncements, setWarnUnreadAnnouncements] = useState<AnnouncementModalModel[]>([])
  const dispatch = useDispatch()
  const uiState = useSelector(SelectUIState)
  let { enableClosedSidebar, headerBackgroundColor } = uiState
  const loggedInUser = useSelector(SelectLoggedInUser)

  const announcementsQ = useAnnouncementsQuery({
    variables: {
      limit: 100,
      offset: 0,
      param: {
        enabled: true,
        startAt: now,
        endAt: now,
      },
    },
  })
  const [readAnnouncementsFunc] = useReadAnnouncementsMutation()

  useEffect(() => {
    if (announcementsQ.data != null) {
      // 'warn' かつ 未読 => モーダル表示
      if (announcementsQ.data.announcements.nodes.find(n => n.level === 'warn' && !n.isRead)) {
        setWarnUnreadAnnouncements(announcementsQ.data.announcements.nodes.filter(n => n.level === 'warn' && !n.isRead))
      }
    }
  }, [announcementsQ.data])
  const { nodes: as = [] } = announcementsQ.data?.announcements || {}

  return (
    <Fragment>
      <DashboardHeader
        // パブリッシャーはinsight画面上に専用のユーザー管理ページがあるので、あえてメガメニューを表示する必要がない
        hideMenu={loggedInUser.roles.some(r => r.indexOf('insight:publisher_') !== -1)}
        abac={loggedInUser.abac}
        isHamburgerOpen={enableClosedSidebar}
        headerBackgroundColor={headerBackgroundColor}
        toggleIsHamburgerOpen={flag => dispatch(UIStateActions.setEnableClosedSidebar(flag))}
        right={
          <>
            <HeaderDotsForAnnouncement
              items={as.map(a => ({
                id: a.id,
                title: a.title,
                body: a.body,
                publishedAt: a.updatedAt,
                isRead: a.isRead,
                type: a.level,
              }))}
              onRead={() => {}}
              onClick={a => {
                history.push('/announcements/' + a.id)
              }}
            />
            <HeaderUserBox
              email={loggedInUser.email}
              photoURL={loggedInUser.photoURL}
              showChangeEmail={loggedInUser.hasEmailPassAccount}
              showChangePassword={loggedInUser.hasEmailPassAccount}
              onSignOut={() => firebase.auth().signOut()}
            />
          </>
        }
      />
      <div className="app-main">
        <AppSidebar NavContent={Nav} />
        <div className="app-main__outer">
          <div className="app-main__inner">{children}</div>
        </div>
      </div>
      <SweetAlert
        style={{ maxHeight: '50vh', overflowY: 'scroll', margin: 'auto' }}
        title={warnUnreadAnnouncements.length > 0 ? warnUnreadAnnouncements[0].title : ''}
        confirmButtonColor=""
        show={warnUnreadAnnouncements.length > 0}
        text={warnUnreadAnnouncements.length > 0 ? warnUnreadAnnouncements[0].body : ''}
        html={true}
        type="info"
        confirmButtonText={warnUnreadAnnouncements[0]?.closeButtonText || 'OK'}
        onConfirm={() => {
          setWarnUnreadAnnouncements(warnUnreadAnnouncements.slice(1))
          readAnnouncementsFunc({
            variables: {
              ids: [warnUnreadAnnouncements[0].id],
            },
          })
        }}
      />
    </Fragment>
  )
}

export default Dashboard
