import React, { Fragment, lazy, Suspense, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { Slide, toast, ToastContainer } from 'react-toastify'
import Dashboard from '../../core/templates/Dashboard'
import { SelectLoggedInUser, SelectLoggedInUserIsStateReady } from '../../store/loggedInUser'
import PageLoader from '../atoms/PageLoader'

// PageComponent
const Reports = lazy(() => import('../../core/pages/reports'))
const ReportsPublisherRanking = lazy(() => import('../../core/pages/reports/publisher-ranking'))
const ReportsArticles = lazy(() => import('../../core/pages/reports/articles'))
const ReportsArticleDetail = lazy(() => import('../../core/pages/reports/article-detail'))
const ReportsVideo = lazy(() => import('../../core/pages/reports/video'))
const ReportsVideoDetail = lazy(() => import('../../core/pages/reports/video-detail'))

const ManagementPublishers = lazy(() => import('../../core/pages/managements/publishers'))
const ManagementPublisherEdit = lazy(() => import('../../core/pages/managements/publishers/edit'))
const ManagementPublisherCreateFromStaging = lazy(() =>
  import('../../core/pages/managements/publishers/create-from-staging')
)
const ManagementFeeds = lazy(() => import('../../core/pages/managements/feeds'))
const ManagementFeedsForm = lazy(() => import('../../core/pages/managements/feeds/edit'))
const ManagementFeedsCreateFromStaging = lazy(() => import('../../core/pages/managements/feeds/create-from-staging'))
const ManagementArticle = lazy(() => import('../../core/pages/managements/article'))

const Users = lazy(() => import('../../core/pages/users'))
const UsersEdit = lazy(() => import('../../core/pages/users/edit'))
const UsersInvite = lazy(() => import('../../core/pages/users/invite'))
const Agreements = lazy(() => import('../../core/pages/managements/agreements'))
const AgreementsEdit = lazy(() => import('../../core/pages/managements/agreements/edit'))
const AgreementsCreate = lazy(() => import('../../core/pages/managements/agreements/create'))

const BankAccounts = lazy(() => import('../../core/pages/bank-accounts'))
const BankAccountsCreate = lazy(() => import('../../core/pages/bank-accounts/create'))

const HistoriesAgreementPayments = lazy(() => import('../../core/pages/histories/payments-by-agreement'))
const HistoriesPublisherPayments = lazy(() => import('../../core/pages/histories/payments-by-publisher'))
const HistoriesPaymentsForPublishers = lazy(() => import('../../core/pages/histories/payments-for-publishers'))

const Announcements = lazy(() => import('../../core/pages/announcements'))
const AnnouncementsForm = lazy(() => import('../../core/pages/announcements/edit'))
const AnnouncementShow = lazy(() => import('../../core/pages/announcements/show'))

const AdjustmentDividend = lazy(() => import('../../core/pages/adjustments/dividend'))
const AdjustmentDividendCreate = lazy(() => import('../../core/pages/adjustments/dividend/create'))

const ManagementFeedsEdit = (props: React.LazyExoticComponent<React.FC>) => (
  <ManagementFeedsForm isEdition={true} {...props} />
)
const AnnouncementsEdit = (props: React.LazyExoticComponent<React.FC>) => (
  <AnnouncementsForm isEdition={true} {...props} />
)

// withAuthz がAPIから認可情報を取得し終わるまでローディング画面見せる
const BeforeAuthzPageLoader = ({ children }: { children: React.ReactNode }) => {
  const isStateReady = useSelector(SelectLoggedInUserIsStateReady)
  if (isStateReady) {
    return <>{children}</>
  }
  return <PageLoader />
}

// Google Analytics
const WithTracker = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  const loggedInUser = useSelector(SelectLoggedInUser)
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'prd' && loggedInUser.id !== '') {
      ReactGA.set({
        page: location.pathname + location.hash,
        userId: loggedInUser.id,
      })
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.hash,
      })
    }
  }, [loggedInUser, location.pathname, location.hash])
  return <>{children}</>
}

const AppMain: React.FC = () => (
  <Fragment>
    <BeforeAuthzPageLoader>
      <WithTracker>
        <Dashboard>
          {/* /reports */}
          <Suspense fallback={PageLoader}>
            <Route path="/reports/dashboard/:condition?" component={Reports} />
          </Suspense>
          <Suspense fallback={PageLoader}>
            <Route path="/reports/publisher-ranking/:condition?" component={ReportsPublisherRanking} />
          </Suspense>
          <Suspense fallback={PageLoader}>
            <Switch>
              <Route
                exact
                path="/reports/articles/:articleId(\d+)/:publisherId(\d+)/:condition?"
                component={ReportsArticleDetail}
              />
              <Route exact path="/reports/articles/:condition?" component={ReportsArticles} />
            </Switch>
          </Suspense>
          <Suspense fallback={PageLoader}>
            <Switch>
              <Route
                exact
                path="/reports/video/:videoId(\d+)/:publisherId(\d+)/:condition?"
                component={ReportsVideoDetail}
              />
              <Route exact path="/reports/video/:condition?" component={ReportsVideo} />
            </Switch>
          </Suspense>

          {/* /managements */}
          <Suspense fallback={PageLoader}>
            <Switch>
              <Route
                path="/managements/agreements/edit/:id(\d+)"
                component={(props: any) => <AgreementsEdit isEdition={true} {...props} />}
              />
              <Route path="/managements/agreements/create" component={AgreementsCreate} />
              <Route path="/managements/agreements/:condition?" component={Agreements} />
            </Switch>
          </Suspense>
          <Suspense fallback={PageLoader}>
            <Switch>
              <Route
                path="/managements/publishers/edit/:id(\d+)"
                component={(props: any) => <ManagementPublisherEdit isEdition={true} {...props} />}
              />
              <Route path="/managements/publishers/create" component={ManagementPublisherEdit} />
              <Route
                path="/managements/publishers/create-from-staging"
                component={ManagementPublisherCreateFromStaging}
              />
              <Route path="/managements/publishers/:condition?" component={ManagementPublishers} />
            </Switch>
          </Suspense>
          <Suspense fallback={PageLoader}>
            <Switch>
              <Route path="/managements/feeds/edit/:id(\d+)" component={ManagementFeedsEdit} />
              <Route path="/managements/feeds/create" component={ManagementFeedsForm} />
              <Route path="/managements/feeds/create-from-staging" component={ManagementFeedsCreateFromStaging} />
              <Route path="/managements/feeds/:condition?" component={ManagementFeeds} />
            </Switch>
          </Suspense>
          <Suspense fallback={PageLoader}>
            <Route exact path="/managements/articles/:condition?" component={ManagementArticle} />
          </Suspense>

          <Suspense fallback={PageLoader}>
            <Switch>
              <Route path="/bank-accounts/create" component={BankAccountsCreate} />
              <Route path="/bank-accounts/:condition?" component={BankAccounts} />
            </Switch>
          </Suspense>

          {/* /announcements */}
          <Suspense fallback={PageLoader}>
            <Switch>
              <Route exact path="/announcements" component={Announcements} />
              <Route path="/announcements/edit/:id(\d+)" component={AnnouncementsEdit} />
              <Route path="/announcements/create" component={AnnouncementsForm} />
              <Route path="/announcements/:id(\d+)" component={AnnouncementShow} />
            </Switch>
          </Suspense>

          {/* /payments */}
          <Suspense fallback={PageLoader}>
            <Route path="/histories/payments-by-agreement/:condition?" component={HistoriesAgreementPayments} />
            <Route path="/histories/payments-by-publisher/:condition?" component={HistoriesPublisherPayments} />
            <Route path="/histories/payments-for-publishers/:condition?" component={HistoriesPaymentsForPublishers} />
          </Suspense>

          {/* /adjustments */}
          <Suspense fallback={PageLoader}>
            <Switch>
              <Route path="/adjustments/dividend/create/:id(\d+)" component={AdjustmentDividendCreate} />
              <Route path="/adjustments/dividend/:condition?" component={AdjustmentDividend} />
            </Switch>
          </Suspense>

          {/* /payments */}
          <Suspense fallback={PageLoader}>
            <Switch>
              <Route exact path="/users/edit/:userID" component={UsersEdit} />
              <Route exact path="/users/invite" component={UsersInvite} />
              <Route exact path="/users/:condition?" component={Users} />
            </Switch>
          </Suspense>

          {/* できてないやつ */}
          <Suspense fallback={PageLoader}>
            <Route exact path="/dashboards" component={() => <div style={{ fontSize: '100px' }}>// TODO...</div>} />
          </Suspense>
        </Dashboard>

        {/* Default */}
        <Route exact path="/" render={() => <Redirect to="/reports/dashboard" />} />
      </WithTracker>
    </BeforeAuthzPageLoader>
    <ToastContainer hideProgressBar={true} position={toast.POSITION.BOTTOM_LEFT} transition={Slide} />
  </Fragment>
)

export default AppMain
