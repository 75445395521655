import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Date: any,
  Time: any,
  Month: any,
};


export type Agreement = {
   __typename?: 'Agreement',
  id: Scalars['Int'],
  isTerminated: Scalars['Boolean'],
  name: Scalars['String'],
  mediumId: Scalars['Int'],
  organizationId: Scalars['Int'],
  code: Scalars['String'],
  inhouseAgreementField?: Maybe<InhouseAgreementField>,
  notes?: Maybe<InhouseNote>,
  terminatedAt?: Maybe<Scalars['Month']>,
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
  organization: Organization,
  /** 紐づくすべての銀行口座 */
  agreementBankAccounts: Array<AgreementBankAccount>,
  /** 現在使用中の銀行口座 */
  inUseAgreementBankAccount?: Maybe<AgreementBankAccount>,
};

export type AgreementBankAccount = {
   __typename?: 'AgreementBankAccount',
  id: Scalars['Int'],
  agreementId: Scalars['Int'],
  bankAccountId: Scalars['Int'],
  bankAccount: BankAccount,
  usedFromMonth: Scalars['Month'],
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
  organizationId: Scalars['Int'],
};

export type AgreementConditionParam = {
  userUid?: Maybe<Scalars['String']>,
  agreementIds?: Maybe<Array<Scalars['Int']>>,
  publisherId?: Maybe<Scalars['Int']>,
  organizationId?: Maybe<Scalars['Int']>,
  mediumId?: Maybe<Scalars['Int']>,
  terminatedAt?: Maybe<Scalars['Month']>,
};

export type AgreementConnection = {
   __typename?: 'AgreementConnection',
  total: Scalars['Int'],
  nodes: Array<Agreement>,
};


export type AgreementConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int'],
  orderBy?: Maybe<AgreementOrder>
};

export enum AgreementOrder {
  IdAsc = 'ID_ASC',
  CreatedatAsc = 'CREATEDAT_ASC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  IdDesc = 'ID_DESC',
  CreatedatDesc = 'CREATEDAT_DESC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  TerminatedatAsc = 'TERMINATEDAT_ASC',
  TerminatedatDesc = 'TERMINATEDAT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type AgreementPublisherRule = {
   __typename?: 'AgreementPublisherRule',
  id: Scalars['Int'],
  mediumId: Scalars['Int'],
  fixedRevenue?: Maybe<Scalars['Int']>,
  minimumRevenue?: Maybe<Scalars['Int']>,
  paymentRate?: Maybe<PaymentRate>,
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
  agreement: Agreement,
  publisher: Publisher,
  organizationId: Scalars['Int'],
};

export type Announcement = {
   __typename?: 'Announcement',
  id: Scalars['Int'],
  enabled: Scalars['Boolean'],
  level: AnnouncementLevel,
  title: Scalars['String'],
  body: Scalars['String'],
  startAt: Scalars['Time'],
  endAt: Scalars['Time'],
  closeButtonText?: Maybe<Scalars['String']>,
  /** 既読フラグ(アクセスしたユーザーのフラグ) */
  isRead: Scalars['Boolean'],
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
};

export type AnnouncementConditionParam = {
  announcementId?: Maybe<Scalars['Int']>,
  enabled?: Maybe<Scalars['Boolean']>,
  startAt?: Maybe<Scalars['Time']>,
  endAt?: Maybe<Scalars['Time']>,
};

export type AnnouncementConnection = {
   __typename?: 'AnnouncementConnection',
  total: Scalars['Int'],
  nodes: Array<Announcement>,
};


export type AnnouncementConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int'],
  orderBy?: Maybe<AnnouncementOrder>
};

export enum AnnouncementLevel {
  Info = 'info',
  Warn = 'warn'
}

export enum AnnouncementOrder {
  IdAsc = 'ID_ASC',
  CreatedatAsc = 'CREATEDAT_ASC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  IdDesc = 'ID_DESC',
  CreatedatDesc = 'CREATEDAT_DESC',
  UpdatedatDesc = 'UPDATEDAT_DESC'
}

export type ArticleClickDemographic = {
   __typename?: 'ArticleClickDemographic',
  genderId: Scalars['Int'],
  ageId: Scalars['Int'],
  click: Scalars['Int'],
};

export enum ArticleOrder {
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  ModifiedatAsc = 'MODIFIEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC',
  ModifiedatDesc = 'MODIFIEDAT_DESC'
}

export type ArticleStats = ArticleStatsGroupByArticleId | ArticleStatsGroupByDate;

export type ArticleStatsConnection = {
   __typename?: 'ArticleStatsConnection',
  total: Scalars['Int'],
  nodes: Array<ArticleStats>,
};


export type ArticleStatsConnectionNodesArgs = {
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>
};

/** 記事スタッツ(一覧) */
export type ArticleStatsGroupByArticleId = {
   __typename?: 'ArticleStatsGroupByArticleID',
  organizationId: Scalars['Int'],
  publisherId: Scalars['Int'],
  articleId: Scalars['Int'],
  title: Scalars['String'],
  url: Scalars['String'],
  /** 全Imp(VideoImp含む) */
  impression: Scalars['Int'],
  /** 全Click(VideoClick含む) */
  click: Scalars['Int'],
  /** click/impression (小数点第三位までround) */
  ctr: Scalars['Float'],
  /** デモグラ別記事クリック */
  articleClickDemographics: Array<ArticleClickDemographic>,
  /** データ生成日時(いらないので消す) */
  createdAt: Scalars['Time'],
  /** 公開日時 */
  publishedAt: Scalars['Time'],
  publisher?: Maybe<Publisher>,
};

/** 記事一覧: DynamoDBのKey: ${MediumID}:${ArticleID}:${PublisherID} publisherまたぎの記事があるため */
export type ArticleStatsGroupByArticleIdConditionParam = {
  organizationId?: Maybe<Scalars['Int']>,
  publisherId?: Maybe<Scalars['Int']>,
  mediumId?: Maybe<Scalars['Int']>,
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  orderBy?: Maybe<ArticleStatsOrder>,
};

/** 記事スタッツ(詳細) */
export type ArticleStatsGroupByDate = {
   __typename?: 'ArticleStatsGroupByDate',
  date: Scalars['Time'],
  organizationId: Scalars['Int'],
  publisherId: Scalars['Int'],
  mediumId: Scalars['Int'],
  articleId: Scalars['Int'],
  title: Scalars['String'],
  url: Scalars['String'],
  /** 全Imp(VideoImp含む) */
  impression: Scalars['Int'],
  /** 全Click(VideoClick含む) */
  click: Scalars['Int'],
  /** click/impression (小数点第三位までround) */
  ctr: Scalars['Float'],
  /** デモグラ別記事クリック */
  articleClickDemographics: Array<ArticleClickDemographic>,
};

/** 記事詳細 */
export type ArticleStatsGroupByDateConditionParam = {
  articleId: Scalars['Int'],
  publisherId: Scalars['Int'],
  mediumIds?: Maybe<Array<Scalars['Int']>>,
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
};

export enum ArticleStatsOrder {
  ImpressionAsc = 'IMPRESSION_ASC',
  ClickAsc = 'CLICK_ASC',
  CtrAsc = 'CTR_ASC',
  ImpressionDesc = 'IMPRESSION_DESC',
  ClickDesc = 'CLICK_DESC',
  CtrDesc = 'CTR_DESC'
}

export enum AvailabilityForPromo {
  All = 'all',
  Part = 'part',
  None = 'none'
}

export type AvailableMediaParam = {
  organizationId?: Maybe<Scalars['Int']>,
  publisherId?: Maybe<Scalars['Int']>,
  agreementIds?: Maybe<Array<Scalars['Int']>>,
};

export type Bank = {
   __typename?: 'Bank',
  id: Scalars['Int'],
  code: Scalars['String'],
  name: Scalars['String'],
  kana: Scalars['String'],
  hira: Scalars['String'],
  roma: Scalars['String'],
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
};

export type BankAccount = {
   __typename?: 'BankAccount',
  id: Scalars['Int'],
  enabled: Scalars['Boolean'],
  typeName: Scalars['String'],
  number: Scalars['String'],
  holder: Scalars['String'],
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
  bank: Bank,
  bankBranch: BankBranch,
  organization: Organization,
};

export type BankAccountConditionParam = {
  month?: Maybe<Scalars['Month']>,
  organizationId?: Maybe<Scalars['Int']>,
  agreementId?: Maybe<Scalars['Int']>,
};

export type BankAccountConnection = {
   __typename?: 'BankAccountConnection',
  total: Scalars['Int'],
  nodes: Array<BankAccount>,
};


export type BankAccountConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int']
};

export type BankBranch = {
   __typename?: 'BankBranch',
  id: Scalars['Int'],
  code: Scalars['String'],
  name: Scalars['String'],
  kana: Scalars['String'],
  hira: Scalars['String'],
  roma: Scalars['String'],
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
};

export type BankBranchConnection = {
   __typename?: 'BankBranchConnection',
  total: Scalars['Int'],
  nodes: Array<BankBranch>,
};


export type BankBranchConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int']
};

export type BankConnection = {
   __typename?: 'BankConnection',
  total: Scalars['Int'],
  nodes: Array<Bank>,
};


export type BankConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int']
};

/** 検索条件 */
export type ConditionParam = {
  organizationId?: Maybe<Scalars['Int']>,
  publisherId?: Maybe<Scalars['Int']>,
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  mediumIds?: Maybe<Array<Scalars['Int']>>,
};

export type CreateAgreementForm = {
  name: Scalars['String'],
  mediumId: Scalars['Int'],
  organizationId: Scalars['Int'],
  code: Scalars['String'],
  feePaidBy: FeePaidBy,
  paymentTiming: PaymentTiming,
  minimumRevenueType: MinimumRevenueType,
  fixedRevenue?: Maybe<Scalars['Int']>,
  minimumRevenue?: Maybe<Scalars['Int']>,
  notes?: Maybe<Scalars['String']>,
  terminatedAt?: Maybe<Scalars['Month']>,
};

export type CreateBankAccount = {
  organizationId: Scalars['Int'],
  bankId: Scalars['Int'],
  bankBranchId: Scalars['Int'],
  typeName: Scalars['String'],
  number: Scalars['String'],
  holder: Scalars['String'],
};

export type CreateDividend = {
  monthlyDividendManualOperationId: Scalars['Int'],
  mediumId: Scalars['Int'],
  dividendMethod: DividendMethod,
  extraRevenue?: Maybe<Scalars['Int']>,
  extraRevenueMonth?: Maybe<Scalars['Month']>,
  notes?: Maybe<Scalars['String']>,
};

export type CreateDividendRawData = {
  agreementId: Scalars['Int'],
  manualAdjustmentRevenue: Scalars['Int'],
};

export type CreateFeedFromStagingForm = {
  stagingFeedId: Scalars['Int'],
  /** enabledだけ上書きする（はじめからtrueは嫌な場合もあるので） */
  enabled: Scalars['Boolean'],
  publisherId: Scalars['Int'],
};

export type CreateOrUpdateAgreementBankAccount = {
  bankAccountId: Scalars['Int'],
  usedFromMonth: Scalars['Month'],
};

export type CreateOrUpdateAgreementForm = {
  /** nullならcreate, nonnullならupdate */
  id?: Maybe<Scalars['Int']>,
  name: Scalars['String'],
  mediumId: Scalars['Int'],
  organizationId: Scalars['Int'],
  code: Scalars['String'],
  feePaidBy: FeePaidBy,
  paymentTiming: PaymentTiming,
  minimumRevenueType: MinimumRevenueType,
  fixedRevenue?: Maybe<Scalars['Int']>,
  minimumRevenue?: Maybe<Scalars['Int']>,
  notes?: Maybe<Scalars['String']>,
  terminatedAt?: Maybe<Scalars['Month']>,
};

export type CreateOrUpdateAgreementPublisherRule = {
  /** nullならcreate, nonnullならupdate */
  id?: Maybe<Scalars['Int']>,
  mediumId: Scalars['Int'],
  fixedRevenue?: Maybe<Scalars['Int']>,
  minimumRevenue?: Maybe<Scalars['Int']>,
  paymentRate: Scalars['Float'],
  sspPaymentRate: Scalars['Float'],
  videoSspPaymentRate: Scalars['Float'],
  liveSspPaymentRate: Scalars['Float'],
  isTargetForSspAllocation: Scalars['Boolean'],
  isTargetForVideoSspAllocation: Scalars['Boolean'],
  isTargetForLiveSspAllocation: Scalars['Boolean'],
  agreementId: Scalars['Int'],
};

export type CreateOrUpdateAnnouncement = {
  /** nullならcreate, nonnullならupdate */
  id?: Maybe<Scalars['Int']>,
  enabled: Scalars['Boolean'],
  level: AnnouncementLevel,
  title: Scalars['String'],
  body: Scalars['String'],
  startAt: Scalars['Time'],
  endAt: Scalars['Time'],
  closeButtonText?: Maybe<Scalars['String']>,
};

export type CreateOrUpdateFeedForm = {
  /** nullならcreate, nonnullならupdate */
  id?: Maybe<Scalars['Int']>,
  enabled: Scalars['Boolean'],
  name: Scalars['String'],
  shortName: Scalars['String'],
  siteUrl: Scalars['String'],
  description: Scalars['String'],
  mediumId: Scalars['Int'],
  /** legacyFeedId: Int */
  url: Scalars['String'],
  logoUrl?: Maybe<Scalars['String']>,
  wideLogoUrl?: Maybe<Scalars['String']>,
  bannerUrl?: Maybe<Scalars['String']>,
  isDeleteFeed: Scalars['Boolean'],
  isSafeForFirstview: Scalars['Boolean'],
  relatedLinkNum: Scalars['Int'],
  articleExpirationDays: Scalars['Int'],
  availabilityForPromo: AvailabilityForPromo,
  type: FeedType,
  allowVideo: Scalars['Boolean'],
  allowContentBody: Scalars['Boolean'],
  ttlMinutes: Scalars['Int'],
  manuallyFeedScore: Scalars['Float'],
  notes?: Maybe<Scalars['String']>,
  publisherId: Scalars['Int'],
};

export type CreateOrUpdateOrganization = {
  name: Scalars['String'],
};

export type CreateOrUpdatePublisherForm = {
  /** nullならcreate, nonnullならupdate */
  id?: Maybe<Scalars['Int']>,
  enabled: Scalars['Boolean'],
  organizationId: Scalars['Int'],
  name: Scalars['String'],
  notes?: Maybe<Scalars['String']>,
};

export type CreatePublisherFromStagingForm = {
  stagingPublisherId: Scalars['Int'],
  /** enabledだけ上書きする（はじめからtrueは嫌な場合もあるので） */
  enabled: Scalars['Boolean'],
  organizationId: Scalars['Int'],
};


export type Dividend = {
   __typename?: 'Dividend',
  id: Scalars['Int'],
  mediumId: Scalars['Int'],
  /** 実行ユーザー */
  userUid: Scalars['String'],
  dividendMethod: DividendMethod,
  /** 調整金原資(+/-) */
  extraRevenue?: Maybe<Scalars['Int']>,
  /** 調整金分配合計(+/-) */
  dividendExtraRevenue?: Maybe<Scalars['Int']>,
  /** 調整金額発生月 */
  extraRevenueMonth?: Maybe<Scalars['Month']>,
  notes?: Maybe<Scalars['String']>,
  jobStatus: DividendJobStatus,
  completedAt?: Maybe<Scalars['Time']>,
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
  user: User,
};

export type DividendConditionParam = {
  month?: Maybe<Scalars['Month']>,
};

export enum DividendJobStatus {
  Pending = 'pending',
  Running = 'running',
  Success = 'success',
  Failed = 'failed'
}

export enum DividendMethod {
  Ssp = 'ssp',
  SspVideo = 'ssp_video',
  SspLive = 'ssp_live',
  Csv = 'csv',
  Reset = 'reset'
}

export type Feed = {
   __typename?: 'Feed',
  id: Scalars['Int'],
  enabled: Scalars['Boolean'],
  name: Scalars['String'],
  shortName: Scalars['String'],
  siteUrl: Scalars['String'],
  description: Scalars['String'],
  mediumId: Scalars['Int'],
  legacyFeedId?: Maybe<Scalars['Int']>,
  url: Scalars['String'],
  logoUrl?: Maybe<Scalars['String']>,
  wideLogoUrl?: Maybe<Scalars['String']>,
  bannerUrl?: Maybe<Scalars['String']>,
  isDeleteFeed: Scalars['Boolean'],
  isSafeForFirstview: Scalars['Boolean'],
  relatedLinkNum: Scalars['Int'],
  articleExpirationDays: Scalars['Int'],
  availabilityForPromo: AvailabilityForPromo,
  type: FeedType,
  allowVideo: Scalars['Boolean'],
  allowContentBody: Scalars['Boolean'],
  ttlMinutes: Scalars['Int'],
  manuallyFeedScore: Scalars['Float'],
  notes?: Maybe<InhouseNote>,
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
  publisher: Publisher,
  agreementId: Scalars['Int'],
};

export type FeedConditionParam = {
  feedId?: Maybe<Scalars['Int']>,
  organizationId?: Maybe<Scalars['Int']>,
  publisherId?: Maybe<Scalars['Int']>,
  mediumId?: Maybe<Scalars['Int']>,
  enabled?: Maybe<Scalars['Boolean']>,
};

export type FeedConnection = {
   __typename?: 'FeedConnection',
  total: Scalars['Int'],
  nodes: Array<Feed>,
};


export type FeedConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int'],
  orderBy?: Maybe<FeedOrder>
};

export enum FeedOrder {
  IdAsc = 'ID_ASC',
  CreatedatAsc = 'CREATEDAT_ASC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  IdDesc = 'ID_DESC',
  CreatedatDesc = 'CREATEDAT_DESC',
  UpdatedatDesc = 'UPDATEDAT_DESC'
}

export enum FeedType {
  Gnf1 = 'gnf1',
  Gnf1atom = 'gnf1atom',
  Gnf1NoEnclosure = 'gnf1_no_enclosure',
  Gnf2 = 'gnf2',
  Ldnrss = 'ldnrss',
  Legacy = 'legacy',
  LegacyDirectLink = 'legacy_direct_link',
  Matome1 = 'matome1',
  Matome2 = 'matome2',
  MatomeScraping = 'matome_scraping',
  Pulit = 'pulit',
  RssFulltext = 'rss_fulltext',
  ScrapingRuleOnly = 'scraping_rule_only',
  Snf2 = 'snf2',
  VideoScraper = 'video_scraper'
}

/** 手数料支払者 */
export enum FeePaidBy {
  Gunosy = 'gunosy',
  Publisher = 'publisher'
}

export type ImageUploadUrlResponse = {
   __typename?: 'ImageUploadURLResponse',
  /** URL to put image */
  uploadUrl: Scalars['String'],
  /** URL to access uploaded image */
  imageUrl: Scalars['String'],
};

export type InhouseAgreementField = {
   __typename?: 'InhouseAgreementField',
  feePaidBy: FeePaidBy,
  paymentTiming: PaymentTiming,
  minimumRevenueType: MinimumRevenueType,
  fixedRevenue?: Maybe<Scalars['Int']>,
  minimumRevenue?: Maybe<Scalars['Int']>,
};

/** 社内メモ */
export type InhouseNote = {
   __typename?: 'InhouseNote',
  notes?: Maybe<Scalars['String']>,
};

/** 社内売上内訳(社外秘) */
export type InhouseRevenueDetail = {
   __typename?: 'InhouseRevenueDetail',
  /** 合計売上 */
  gunosyAdsRevenue: Scalars['Int'],
  sspRevenue: Scalars['Int'],
  videoSSPRevenue: Scalars['Int'],
  liveSSPRevenue: Scalars['Int'],
  /** パブリッシャ売上 */
  publisherGunosyAdsRevenue: Scalars['Int'],
  publisherSSPRevenue: Scalars['Int'],
  publisherVideoSSPRevenue: Scalars['Int'],
  publisherLiveSSPRevenue: Scalars['Int'],
};

/** 検索条件 */
export type InviteUser = {
  organizationId?: Maybe<Scalars['Int']>,
  email: Scalars['String'],
  roles: Array<Scalars['String']>,
};

export type InviteUserForPublisher = {
  organizationId: Scalars['Int'],
  email: Scalars['String'],
  role: Scalars['String'],
  agreementIds?: Maybe<Array<Scalars['Int']>>,
};

export type MediaConnection = {
   __typename?: 'MediaConnection',
  total: Scalars['Int'],
  nodes: Array<Medium>,
};

export type Medium = {
   __typename?: 'Medium',
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type MediumArticle = {
   __typename?: 'MediumArticle',
  articleId: Scalars['Int'],
  mediumId: Scalars['Int'],
  mediumArticleId: Scalars['Int'],
  enabled: Scalars['Boolean'],
  title: Scalars['String'],
  url: Scalars['String'],
  publisherId: Scalars['Int'],
  feedId: Scalars['Int'],
  publishedAt: Scalars['Time'],
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
};

export type MediumArticleConditionParam = {
  organizationId?: Maybe<Scalars['Int']>,
  publisherId?: Maybe<Scalars['Int']>,
  mediumId?: Maybe<Scalars['Int']>,
  agreementIds?: Maybe<Array<Scalars['Int']>>,
};

export type MediumArticleConnection = {
   __typename?: 'MediumArticleConnection',
  total: Scalars['Int'],
  nodes: Array<MediumArticle>,
};


export type MediumArticleConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int']
};

export enum MinimumRevenueType {
  ByAgreement = 'by_agreement',
  ByPublisher = 'by_publisher',
  None = 'none'
}


export type MonthlyAgreementPublisherRuleSnapshot = {
   __typename?: 'MonthlyAgreementPublisherRuleSnapshot',
  id: Scalars['Int'],
  monthlyAgreementSnapshotId: Scalars['Int'],
  publisherId: Scalars['Int'],
  mediumId: Scalars['Int'],
  fixedRevenue?: Maybe<Scalars['Int']>,
  minimumRevenue?: Maybe<Scalars['Int']>,
  paymentRate?: Maybe<PaymentRate>,
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
};

export type MonthlyAgreementSnapshot = {
   __typename?: 'MonthlyAgreementSnapshot',
  id: Scalars['Int'],
  isTerminated: Scalars['Boolean'],
  name: Scalars['String'],
  mediumId: Scalars['Int'],
  organizationId: Scalars['Int'],
  code?: Maybe<Scalars['String']>,
  partnerName?: Maybe<Scalars['String']>,
  qualifiedInvoiceIssuer?: Maybe<Scalars['Boolean']>,
  invoiceRegistrationNumber?: Maybe<Scalars['String']>,
  agreementId: Scalars['Int'],
  inhouseAgreementField?: Maybe<InhouseAgreementField>,
  month: Scalars['Month'],
  notes?: Maybe<InhouseNote>,
  terminatedAt?: Maybe<Scalars['Month']>,
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
  /** areement: Agreement! */
  organization: Organization,
  /** この月に有効な銀行口座 */
  targetAgreementBankAccount?: Maybe<AgreementBankAccount>,
};

export type MonthlyDividendManualOperation = {
   __typename?: 'MonthlyDividendManualOperation',
  id: Scalars['Int'],
  /** 調整金分配対象月 */
  dividendMonth: Scalars['Month'],
  /** 調整金分配合計(+/-) */
  dividendExtraRevenue?: Maybe<Scalars['Int']>,
  /** 確定値かどうか */
  hasBeenFinalized: Scalars['Boolean'],
  finalizedAt?: Maybe<Scalars['Time']>,
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
  dividends: Array<Dividend>,
};

export type MonthlyDividendManualOperationConnection = {
   __typename?: 'MonthlyDividendManualOperationConnection',
  total: Scalars['Int'],
  nodes: Array<MonthlyDividendManualOperation>,
};


export type MonthlyDividendManualOperationConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int']
};

export type MonthlyPayment = {
   __typename?: 'MonthlyPayment',
  id: Scalars['Int'],
  monthlyAgreementSnapshotId: Scalars['Int'],
  agreementId: Scalars['Int'],
  mediumId: Scalars['Int'],
  sspRevenue: Scalars['Int'],
  videoSSPRevenue: Scalars['Int'],
  liveSSPRevenue: Scalars['Int'],
  gunosyAdsRevenue: Scalars['Int'],
  /** 当月パブリッシャー売上合計(fixed_revenue, minimum_revenue考慮後の値) */
  revenue: Scalars['Int'],
  /** 当月パブリッシャー計上合計(当月パブリッシャー売上合計 + 手動売上調整金) */
  revenueIncludingManualAdjustmentRevenue: Scalars['Int'],
  /** 当月パブリッシャー計上合計(当月パブリッシャー売上合計 + 手動売上調整金)(税額) */
  revenueIncludingManualAdjustmentRevenueTax: Scalars['Int'],
  /** 当月パブリッシャー計上合計(当月パブリッシャー売上合計 + 手動売上調整金)(税込) */
  revenueIncludingManualAdjustmentRevenueIncludingTax: Scalars['Int'],
  /** 手動売上調整金 */
  manualAdjustmentRevenue?: Maybe<Scalars['Int']>,
  /** 前月以前からの繰越金 */
  carriedOverRevenue: Scalars['Int'],
  /** 前月以前からの繰越金(税込み) */
  cumulativeCarriedOverRevenueIncludingTax: Scalars['Int'],
  /** 累計売上額(前月以前からの繰越金 + 手動売上調整金 + 当月パブリッシャー売上) */
  cumulativeRevenue: Scalars['Int'],
  /** 支払額 */
  payment: Scalars['Int'],
  /** 税率 */
  taxRate: Scalars['Int'],
  /** 内税額 */
  paymentTax: Scalars['Int'],
  /** 支払額(税込み) */
  paymentIncludingTax: Scalars['Int'],
  /** 確定値かどうか */
  hasBeenFinalized: Scalars['Boolean'],
  /** 売上発生月(monthly_agreement_snapshots.monthと同値) */
  revenueGeneratedAtMonth: Scalars['Month'],
  /** 売上締め日(売上発生月の末日) */
  revenueGeneratedByDate: Scalars['Date'],
  /** 支払い確定月(売上発生の翌月) */
  finalizedAtMonth?: Maybe<Scalars['Month']>,
  /** 支払月(支払いサイトが30だった場合は翌月、60だった場合翌々月) */
  paidAtMonth: Scalars['Month'],
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
  monthlyAgreementSnapshot: MonthlyAgreementSnapshot,
  organizationId: Scalars['Int'],
};

export type MonthlyPaymentConnection = {
   __typename?: 'MonthlyPaymentConnection',
  total: Scalars['Int'],
  /** 合計支払金額(税込み) */
  sum: Scalars['Int'],
  /** 合計支払金額(税抜き) */
  sumWithoutTax: Scalars['Int'],
  /** 合計支払税額(税額のみ) */
  taxSum: Scalars['Int'],
  /** 合計収益額(税込)(当月パブリッシャー計上合計の総額) */
  sumRevenue: Scalars['Int'],
  /** 合計収益額(税額のみ) */
  sumRevenueTax: Scalars['Int'],
  /** 合計収益額(税抜き) */
  sumRevenueWithoutTax: Scalars['Int'],
  nodes: Array<MonthlyPayment>,
};


export type MonthlyPaymentConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int']
};

export type MonthlyPaymentsConditionParam = {
  month?: Maybe<Scalars['Month']>,
  organizationId?: Maybe<Scalars['Int']>,
  agreementId?: Maybe<Scalars['Int']>,
  mediumId?: Maybe<Scalars['Int']>,
};

export type MonthlyPublisherPayment = {
   __typename?: 'MonthlyPublisherPayment',
  id: Scalars['Int'],
  monthlyPaymentId: Scalars['Int'],
  publisherId: Scalars['Int'],
  sspRevenue: Scalars['Int'],
  videoSSPRevenue: Scalars['Int'],
  liveSSPRevenue: Scalars['Int'],
  gunosyAdsRevenue: Scalars['Int'],
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
  publisher: Publisher,
  monthlyPayment: MonthlyPayment,
  monthlyAgreementPublisherRuleSnapshot: MonthlyAgreementPublisherRuleSnapshot,
  organizationId: Scalars['Int'],
  agreementId: Scalars['Int'],
};

export type MonthlyPublisherPaymentConditionParam = {
  month: Scalars['Month'],
  organizationId?: Maybe<Scalars['Int']>,
  agreementId?: Maybe<Scalars['Int']>,
  mediumId?: Maybe<Scalars['Int']>,
};

export type MonthlyPublisherPaymentConnection = {
   __typename?: 'MonthlyPublisherPaymentConnection',
  total: Scalars['Int'],
  nodes: Array<MonthlyPublisherPayment>,
};


export type MonthlyPublisherPaymentConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int']
};

export type Mutation = {
   __typename?: 'Mutation',
  /** returns id of created/updated record */
  upsertAnnouncement: Scalars['Int'],
  upsertPublisher: Scalars['Int'],
  /** prd かつ 新規作成時のみ: ステージングのpublisherIDからコピーしてきて作成する(IDを揃えるため) */
  createPublisherWithAgreementPublisherRulesFromStaging: Scalars['Int'],
  upsertPublisherWithAgreementPublisherRules: Scalars['Int'],
  /** prd かつ 新規作成時のみ: ステージングのfeedIDからコピーしてきて作成する(IDを揃えるため) */
  createFeedFromStaging: Scalars['Int'],
  upsertFeed: Scalars['Int'],
  upsertAgreement: Scalars['Int'],
  createAgreements?: Maybe<Array<Scalars['Int']>>,
  createBankAccount: Scalars['Int'],
  deleteMediumArticle?: Maybe<Scalars['Boolean']>,
  /** 既読 */
  readAnnouncements?: Maybe<Scalars['Boolean']>,
  /** ユーザー管理(Publisher用) */
  inviteUserForPublisher: Scalars['String'],
  updateUserForPublisher?: Maybe<Scalars['Boolean']>,
  createDividend: Scalars['Int'],
  finalizeMonthlyDividendManualOperation?: Maybe<Scalars['Boolean']>,
  inviteUser: Scalars['String'],
  regenerateInviteLink: Scalars['String'],
  updateEmail?: Maybe<Scalars['Boolean']>,
  updateRoles?: Maybe<Scalars['Boolean']>,
  deleteUser?: Maybe<Scalars['Boolean']>,
  createOrganization: Scalars['Int'],
  updateOrganization?: Maybe<Scalars['Boolean']>,
  deleteOrganization?: Maybe<Scalars['Boolean']>,
};


export type MutationUpsertAnnouncementArgs = {
  input: CreateOrUpdateAnnouncement
};


export type MutationUpsertPublisherArgs = {
  publisher: CreateOrUpdatePublisherForm
};


export type MutationCreatePublisherWithAgreementPublisherRulesFromStagingArgs = {
  publisher: CreatePublisherFromStagingForm,
  agreementPublisherRules: Array<CreateOrUpdateAgreementPublisherRule>
};


export type MutationUpsertPublisherWithAgreementPublisherRulesArgs = {
  publisher: CreateOrUpdatePublisherForm,
  agreementPublisherRules: Array<CreateOrUpdateAgreementPublisherRule>
};


export type MutationCreateFeedFromStagingArgs = {
  input: CreateFeedFromStagingForm
};


export type MutationUpsertFeedArgs = {
  input: CreateOrUpdateFeedForm
};


export type MutationUpsertAgreementArgs = {
  input: CreateOrUpdateAgreementForm,
  agreementBankAccounts?: Maybe<Array<CreateOrUpdateAgreementBankAccount>>
};


export type MutationCreateAgreementsArgs = {
  input?: Maybe<Array<CreateAgreementForm>>,
  agreementBankAccounts?: Maybe<Array<CreateOrUpdateAgreementBankAccount>>
};


export type MutationCreateBankAccountArgs = {
  input: CreateBankAccount
};


export type MutationDeleteMediumArticleArgs = {
  mediumId: Scalars['Int'],
  mediumArticleId: Scalars['Int']
};


export type MutationReadAnnouncementsArgs = {
  ids: Array<Scalars['Int']>
};


export type MutationInviteUserForPublisherArgs = {
  input: InviteUserForPublisher
};


export type MutationUpdateUserForPublisherArgs = {
  input: UpdateUserForPublisher
};


export type MutationCreateDividendArgs = {
  input: CreateDividend,
  rawData?: Maybe<Array<CreateDividendRawData>>
};


export type MutationFinalizeMonthlyDividendManualOperationArgs = {
  id: Scalars['Int']
};


export type MutationInviteUserArgs = {
  input: InviteUser
};


export type MutationRegenerateInviteLinkArgs = {
  uid: Scalars['ID']
};


export type MutationUpdateEmailArgs = {
  uid: Scalars['String'],
  email: Scalars['String']
};


export type MutationUpdateRolesArgs = {
  uid: Scalars['String'],
  roles: Array<Scalars['String']>
};


export type MutationDeleteUserArgs = {
  uid: Scalars['ID']
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrUpdateOrganization
};


export type MutationUpdateOrganizationArgs = {
  id: Scalars['Int'],
  input: CreateOrUpdateOrganization
};


export type MutationDeleteOrganizationArgs = {
  id: Scalars['Int']
};

export type Organization = {
   __typename?: 'Organization',
  id: Scalars['Int'],
  name: Scalars['String'],
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
};

export type OrganizationConnection = {
   __typename?: 'OrganizationConnection',
  total: Scalars['Int'],
  nodes: Array<Organization>,
};


export type OrganizationConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int']
};

/** 料率(社外秘) */
export type PaymentRate = {
   __typename?: 'PaymentRate',
  paymentRate: Scalars['Float'],
  sspPaymentRate: Scalars['Float'],
  videoSspPaymentRate: Scalars['Float'],
  liveSspPaymentRate: Scalars['Float'],
  isTargetForSspAllocation: Scalars['Boolean'],
  isTargetForVideoSspAllocation: Scalars['Boolean'],
  isTargetForLiveSspAllocation: Scalars['Boolean'],
};

export enum PaymentTiming {
  AMonthLater = 'a_month_later',
  TwoMonthsLater = 'two_months_later'
}

export type Policy = {
   __typename?: 'Policy',
  actions: Array<Scalars['String']>,
  resources: Array<Resource>,
};

export type Publisher = {
   __typename?: 'Publisher',
  id: Scalars['Int'],
  enabled: Scalars['Boolean'],
  organizationId: Scalars['Int'],
  name: Scalars['String'],
  notes?: Maybe<InhouseNote>,
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
  organization: Organization,
  agreementPublisherRules: Array<AgreementPublisherRule>,
};

export type PublisherConditionParam = {
  organizationId?: Maybe<Scalars['Int']>,
  agreementIds?: Maybe<Array<Scalars['Int']>>,
  publisherIds?: Maybe<Array<Scalars['Int']>>,
  mediumId?: Maybe<Scalars['Int']>,
  enabled?: Maybe<Scalars['Boolean']>,
};

export type PublisherConnection = {
   __typename?: 'PublisherConnection',
  total: Scalars['Int'],
  nodes: Array<Publisher>,
};


export type PublisherConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int'],
  orderBy?: Maybe<PublisherOrder>
};

export enum PublisherOrder {
  IdAsc = 'ID_ASC',
  CreatedatAsc = 'CREATEDAT_ASC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  IdDesc = 'ID_DESC',
  CreatedatDesc = 'CREATEDAT_DESC',
  UpdatedatDesc = 'UPDATEDAT_DESC'
}

export type PublisherRank = {
   __typename?: 'PublisherRank',
  publisherId: Scalars['Int'],
  rank: Scalars['Int'],
  articleImpression: Scalars['Int'],
  articleClick: Scalars['Int'],
  videoImpression: Scalars['Int'],
  videoClick: Scalars['Int'],
  videoWatch_3s: Scalars['Int'],
  videoWatch_97p: Scalars['Int'],
  liveWatch: Scalars['Int'],
  gunosyAdsRevenue: Scalars['Int'],
  sspRevenue: Scalars['Int'],
  videoSspRevenue: Scalars['Int'],
  liveSspRevenue: Scalars['Int'],
  publisherGunosyAdsRevenue: Scalars['Int'],
  publisherSspRevenue: Scalars['Int'],
  publisherVideoSspRevenue: Scalars['Int'],
  publisherLiveSspRevenue: Scalars['Int'],
  publisher: Publisher,
  mediumId: Scalars['Int'],
};

export type PublisherRankConditionParam = {
  organizationId?: Maybe<Scalars['Int']>,
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  mediumId?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<PublisherRankOrder>,
};

export type PublisherRankConnection = {
   __typename?: 'PublisherRankConnection',
  total: Scalars['Int'],
  nodes: Array<PublisherRank>,
};


export type PublisherRankConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int']
};

export enum PublisherRankOrder {
  ArticleImpAsc = 'ARTICLE_IMP_ASC',
  ArticleClickAsc = 'ARTICLE_CLICK_ASC',
  VideoImpAsc = 'VIDEO_IMP_ASC',
  VideoClickAsc = 'VIDEO_CLICK_ASC',
  Videowatch_3SAsc = 'VIDEOWATCH_3S_ASC',
  Videowatch_97PAsc = 'VIDEOWATCH_97P_ASC',
  LivewatchAsc = 'LIVEWATCH_ASC',
  GunosyAdsRevenueAsc = 'GUNOSY_ADS_REVENUE_ASC',
  SsprevenueAsc = 'SSPREVENUE_ASC',
  VideoSsprevenueAsc = 'VIDEO_SSPREVENUE_ASC',
  LiveSsprevenueAsc = 'LIVE_SSPREVENUE_ASC',
  PublisherGunosyAdsRevenueAsc = 'PUBLISHER_GUNOSY_ADS_REVENUE_ASC',
  PublisherSsprevenueAsc = 'PUBLISHER_SSPREVENUE_ASC',
  PublisherVideoSsprevenueAsc = 'PUBLISHER_VIDEO_SSPREVENUE_ASC',
  PublisherLiveSsprevenueAsc = 'PUBLISHER_LIVE_SSPREVENUE_ASC',
  ArticleImpDesc = 'ARTICLE_IMP_DESC',
  ArticleClickDesc = 'ARTICLE_CLICK_DESC',
  VideoImpDesc = 'VIDEO_IMP_DESC',
  VideoClickDesc = 'VIDEO_CLICK_DESC',
  Videowatch_3SDesc = 'VIDEOWATCH_3S_DESC',
  Videowatch_97PDesc = 'VIDEOWATCH_97P_DESC',
  LivewatchDesc = 'LIVEWATCH_DESC',
  GunosyAdsRevenueDesc = 'GUNOSY_ADS_REVENUE_DESC',
  SsprevenueDesc = 'SSPREVENUE_DESC',
  VideoSsprevenueDesc = 'VIDEO_SSPREVENUE_DESC',
  LiveSsprevenueDesc = 'LIVE_SSPREVENUE_DESC',
  PublisherGunosyAdsRevenueDesc = 'PUBLISHER_GUNOSY_ADS_REVENUE_DESC',
  PublisherSsprevenueDesc = 'PUBLISHER_SSPREVENUE_DESC',
  PublisherVideoSsprevenueDesc = 'PUBLISHER_VIDEO_SSPREVENUE_DESC',
  PublisherLiveSsprevenueDesc = 'PUBLISHER_LIVE_SSPREVENUE_DESC'
}

export type Query = {
   __typename?: 'Query',
  /** fetchRSS */
  rss: Rss,
  /** report */
  summaryStats: Array<SummaryStats>,
  /** パブリッシャーランキング */
  publisherRank: PublisherRankConnection,
  /** 記事詳細 */
  articleStats: ArticleStatsConnection,
  /** 動画レポート */
  videoStats: VideoStatsConnection,
  /** パブリッシャー一覧 */
  publishers: PublisherConnection,
  publisher: Publisher,
  /** 契約一覧 */
  agreements: AgreementConnection,
  agreement: Agreement,
  /** フィード */
  feeds: FeedConnection,
  feed: Feed,
  /** 利用可能なメディア一覧 */
  availableMedia: MediaConnection,
  /** 記事一覧 */
  mediumArticles: MediumArticleConnection,
  /** パブリッシャー単位の月別支払額 */
  monthlyPublisherPayments: MonthlyPublisherPaymentConnection,
  /** 契約単位の月別支払額 */
  monthlyPayments: MonthlyPaymentConnection,
  /** お知らせ一覧 */
  announcements: AnnouncementConnection,
  /** お知らせ詳細 */
  announcement: Announcement,
  /** 月次支払手動確定一覧 */
  monthlyDividendManualOperations: MonthlyDividendManualOperationConnection,
  /** 画像アップロード */
  imageUploadUrl: ImageUploadUrlResponse,
  /** 銀行口座一覧 */
  bankAccounts: BankAccountConnection,
  /** 銀行一覧 */
  banks: BankConnection,
  /** 銀行支店一覧 */
  bankBranches: BankBranchConnection,
  /** [本番のみ] ステージングパブリッシャー一覧 */
  stagingPublishers: StagingPublisherConnection,
  /** [本番のみ] ステージングフィード一覧 */
  stagingFeeds: StagingFeedConnection,
  user: User,
  users: UserConnection,
  organization: Organization,
  organizations: OrganizationConnection,
  roles: Array<Role>,
  policies: Array<Policy>,
};


export type QueryRssArgs = {
  rssUrl: Scalars['String']
};


export type QuerySummaryStatsArgs = {
  param?: Maybe<ConditionParam>
};


export type QueryPublisherRankArgs = {
  param?: Maybe<PublisherRankConditionParam>
};


export type QueryArticleStatsArgs = {
  groupByDateParam?: Maybe<ArticleStatsGroupByDateConditionParam>,
  groupByArticleIdParam?: Maybe<ArticleStatsGroupByArticleIdConditionParam>
};


export type QueryVideoStatsArgs = {
  groupByDateParam?: Maybe<VideoStatsGroupByDateConditionParam>,
  groupByVideoIdParam?: Maybe<VideoStatsGroupByVideoIdConditionParam>
};


export type QueryPublishersArgs = {
  param?: Maybe<PublisherConditionParam>,
  q?: Maybe<Scalars['String']>
};


export type QueryPublisherArgs = {
  id: Scalars['Int']
};


export type QueryAgreementsArgs = {
  param?: Maybe<AgreementConditionParam>,
  q?: Maybe<Scalars['String']>
};


export type QueryAgreementArgs = {
  id: Scalars['Int']
};


export type QueryFeedsArgs = {
  param?: Maybe<FeedConditionParam>,
  q?: Maybe<Scalars['String']>
};


export type QueryFeedArgs = {
  id: Scalars['Int']
};


export type QueryAvailableMediaArgs = {
  param?: Maybe<AvailableMediaParam>
};


export type QueryMediumArticlesArgs = {
  param?: Maybe<MediumArticleConditionParam>,
  q?: Maybe<Scalars['String']>
};


export type QueryMonthlyPublisherPaymentsArgs = {
  param?: Maybe<MonthlyPublisherPaymentConditionParam>
};


export type QueryMonthlyPaymentsArgs = {
  param?: Maybe<MonthlyPaymentsConditionParam>
};


export type QueryAnnouncementsArgs = {
  param?: Maybe<AnnouncementConditionParam>
};


export type QueryAnnouncementArgs = {
  id: Scalars['Int']
};


export type QueryImageUploadUrlArgs = {
  mimeType: Scalars['String']
};


export type QueryBankAccountsArgs = {
  param?: Maybe<BankAccountConditionParam>
};


export type QueryBanksArgs = {
  q?: Maybe<Scalars['String']>
};


export type QueryBankBranchesArgs = {
  bankId: Scalars['Int'],
  q?: Maybe<Scalars['String']>
};


export type QueryStagingPublishersArgs = {
  param?: Maybe<PublisherConditionParam>,
  q?: Maybe<Scalars['String']>
};


export type QueryStagingFeedsArgs = {
  param?: Maybe<FeedConditionParam>,
  q?: Maybe<Scalars['String']>
};


export type QueryUserArgs = {
  userID: Scalars['String']
};


export type QueryUsersArgs = {
  userIDs?: Maybe<Array<Scalars['String']>>,
  organizationId?: Maybe<Scalars['Int']>,
  q?: Maybe<Scalars['String']>,
  enabled?: Maybe<Scalars['Boolean']>
};


export type QueryOrganizationArgs = {
  id: Scalars['Int']
};


export type QueryOrganizationsArgs = {
  ids?: Maybe<Array<Scalars['Int']>>,
  q?: Maybe<Scalars['String']>,
  enabled?: Maybe<Scalars['Boolean']>
};


export type QueryPoliciesArgs = {
  role?: Maybe<Scalars['String']>
};

export type Resource = {
   __typename?: 'Resource',
  name: Scalars['String'],
  isSameCond: Array<ResourceCondition>,
};

export type ResourceCondition = {
   __typename?: 'ResourceCondition',
  key: Scalars['String'],
  value: Scalars['Boolean'],
};

export type Role = {
   __typename?: 'Role',
  name: Scalars['String'],
};

export enum RoleName {
  Admin = 'ADMIN',
  Viewer = 'VIEWER'
}

export type Rss = {
   __typename?: 'Rss',
  name: Scalars['String'],
  description: Scalars['String'],
  siteUrl: Scalars['String'],
  logoUrl?: Maybe<Scalars['String']>,
  wideLogoUrl?: Maybe<Scalars['String']>,
};

export type StagingFeedConnection = {
   __typename?: 'StagingFeedConnection',
  total: Scalars['Int'],
  nodes: Array<Feed>,
};


export type StagingFeedConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int'],
  orderBy?: Maybe<FeedOrder>
};

export type StagingPublisherConnection = {
   __typename?: 'StagingPublisherConnection',
  total: Scalars['Int'],
  nodes: Array<Publisher>,
};


export type StagingPublisherConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int'],
  orderBy?: Maybe<PublisherOrder>
};

/** サマリーテーブル */
export type SummaryStats = {
   __typename?: 'SummaryStats',
  date: Scalars['Time'],
  organizationId?: Maybe<Scalars['Int']>,
  publisherId?: Maybe<Scalars['Int']>,
  mediumId: Scalars['Int'],
  follower: Scalars['Int'],
  /** 全Imp(VideoImp含む) */
  articleImpression: Scalars['Int'],
  /** 全Click(VideoClick含む) */
  articleClick: Scalars['Int'],
  videoImpression: Scalars['Int'],
  videoClick: Scalars['Int'],
  /** 動画の3秒視聴完了数 */
  videoWatch3s: Scalars['Int'],
  /** 動画の97%視聴完了数 */
  videoWatch97p: Scalars['Int'],
  /** ライブの詳細面view数 */
  liveWatch: Scalars['Int'],
  /** 社内売上内訳(社外秘) */
  inhouseRevenueDetail?: Maybe<InhouseRevenueDetail>,
  /** 
 * パブリッシャ合計売上
   * = publisherGunosyAdsRevenue + publisherSSPRevenue + publisherVideoSSPRevenue + publisherLiveSSPRevenue
 **/
  publisherTotalRevenue: Scalars['Int'],
  hasBeenFinalized: Scalars['Boolean'],
  /** デモグラ別記事クリック */
  articleClickDemographics: Array<ArticleClickDemographic>,
};


export type UpdateUserForPublisher = {
  uid: Scalars['String'],
  role: Scalars['String'],
  agreementIds?: Maybe<Array<Scalars['Int']>>,
};

export type User = {
   __typename?: 'User',
  uid: Scalars['ID'],
  email: Scalars['String'],
  roles: Array<Scalars['String']>,
  createdAt: Scalars['Time'],
  updatedAt: Scalars['Time'],
  organization: Organization,
};

export type UserConnection = {
   __typename?: 'UserConnection',
  total: Scalars['Int'],
  nodes: Array<User>,
};


export type UserConnectionNodesArgs = {
  limit?: Scalars['Int'],
  offset?: Scalars['Int']
};

export type VideoStats = VideoStatsGroupByVideoId | VideoStatsGroupByDate;

export type VideoStatsConnection = {
   __typename?: 'VideoStatsConnection',
  total: Scalars['Int'],
  nodes: Array<VideoStats>,
};


export type VideoStatsConnectionNodesArgs = {
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>
};

/** 動画スタッツ(詳細) */
export type VideoStatsGroupByDate = {
   __typename?: 'VideoStatsGroupByDate',
  date: Scalars['Time'],
  organizationId: Scalars['Int'],
  publisherId: Scalars['Int'],
  mediumId: Scalars['Int'],
  videoId: Scalars['Int'],
  title: Scalars['String'],
  URL: Scalars['String'],
  thumbnailURL: Scalars['String'],
  /** Imp */
  impression: Scalars['Int'],
  /** Click */
  click: Scalars['Int'],
  /** click/impression (小数点第三位までround) */
  ctr: Scalars['Float'],
  /** 再生数(3秒以上) */
  watch3s: Scalars['Int'],
  /** 再生時間(sec) */
  play_time: Scalars['Int'],
  /** 平均再生時間(sec) */
  average_play_time: Scalars['Float'],
  /** 再生完了数 */
  watch97p: Scalars['Int'],
  /** 平均再生完了率 */
  average_watch97p: Scalars['Float'],
  /** 動画の長さ */
  duration: Scalars['Int'],
  /** デモグラ別3s視聴数 */
  watch3sDemographics: Array<Watch3sDemographic>,
  /** 視聴継続数分布(合計) */
  watchTimeDistribution: Array<WatchTimeDistribution>,
  /** 視聴継続数分布(リスト面) */
  listWatchTimeDistribution: Array<WatchTimeDistribution>,
  /** 視聴継続数分布(詳細面) */
  detailWatchTimeDistribution: Array<WatchTimeDistribution>,
};

/** 記事詳細 */
export type VideoStatsGroupByDateConditionParam = {
  videoId: Scalars['Int'],
  publisherId: Scalars['Int'],
  mediumIds?: Maybe<Array<Scalars['Int']>>,
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
};

/** 動画スタッツ(一覧) */
export type VideoStatsGroupByVideoId = {
   __typename?: 'VideoStatsGroupByVideoID',
  organizationId: Scalars['Int'],
  publisherId: Scalars['Int'],
  videoId: Scalars['Int'],
  title: Scalars['String'],
  URL: Scalars['String'],
  thumbnailURL: Scalars['String'],
  /** Imp */
  impression: Scalars['Int'],
  /** Click */
  click: Scalars['Int'],
  /** click/impression (小数点第三位までround) */
  ctr: Scalars['Float'],
  /** 再生数(3秒以上) */
  watch3s: Scalars['Int'],
  /** 再生時間(sec) */
  play_time: Scalars['Int'],
  /** 平均再生時間(sec) */
  average_play_time: Scalars['Float'],
  /** 再生完了数 */
  watch97p: Scalars['Int'],
  /** 平均再生完了率 */
  average_watch97p: Scalars['Float'],
  /** 動画の長さ */
  duration: Scalars['Int'],
  /** デモグラ別3s視聴数 */
  watch3sDemographics: Array<Watch3sDemographic>,
  /** 視聴継続数分布 */
  watchTimeDistribution: Array<WatchTimeDistribution>,
  /** データ生成日時(いらないので消す) */
  createdAt: Scalars['Time'],
  /** 公開日時 */
  publishedAt: Scalars['Time'],
  publisher?: Maybe<Publisher>,
};

/** 動画一覧: DynamoDBのkey: ${MediumID}:${VideoId} */
export type VideoStatsGroupByVideoIdConditionParam = {
  organizationId?: Maybe<Scalars['Int']>,
  publisherId?: Maybe<Scalars['Int']>,
  mediumId?: Maybe<Scalars['Int']>,
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  orderBy?: Maybe<VideoStatsOrder>,
};

export enum VideoStatsOrder {
  ImpressionAsc = 'IMPRESSION_ASC',
  ClickAsc = 'CLICK_ASC',
  CtrAsc = 'CTR_ASC',
  Watch3SAsc = 'WATCH3S_ASC',
  PlayTimeAsc = 'PLAY_TIME_ASC',
  AveragePlayTimeAsc = 'AVERAGE_PLAY_TIME_ASC',
  Watch97PAsc = 'WATCH97P_ASC',
  AverageWatch97PAsc = 'AVERAGE_WATCH97P_ASC',
  ImpressionDesc = 'IMPRESSION_DESC',
  ClickDesc = 'CLICK_DESC',
  CtrDesc = 'CTR_DESC',
  Watch3SDesc = 'WATCH3S_DESC',
  PlayTimeDesc = 'PLAY_TIME_DESC',
  AveragePlayTimeDesc = 'AVERAGE_PLAY_TIME_DESC',
  Watch97PDesc = 'WATCH97P_DESC',
  AverageWatch97PDesc = 'AVERAGE_WATCH97P_DESC'
}

export type Watch3sDemographic = {
   __typename?: 'Watch3sDemographic',
  genderId: Scalars['Int'],
  ageId: Scalars['Int'],
  watch3s: Scalars['Int'],
};

export type WatchTimeDistribution = {
   __typename?: 'WatchTimeDistribution',
  watchTime: Scalars['Int'],
  count: Scalars['Int'],
};

export type CreateAgreementsMutationVariables = {
  input?: Maybe<Array<CreateAgreementForm>>,
  agreementBankAccounts?: Maybe<Array<CreateOrUpdateAgreementBankAccount>>
};


export type CreateAgreementsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAgreements'>
);

export type CreateBankAccountMutationVariables = {
  input: CreateBankAccount
};


export type CreateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBankAccount'>
);

export type CreateDividendMutationVariables = {
  input: CreateDividend,
  rawData?: Maybe<Array<CreateDividendRawData>>
};


export type CreateDividendMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDividend'>
);

export type CreateFeedFromStagingMutationVariables = {
  input: CreateFeedFromStagingForm
};


export type CreateFeedFromStagingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createFeedFromStaging'>
);

export type CreatePublisherWithAgreementPublisherRulesFromStagingMutationVariables = {
  publisher: CreatePublisherFromStagingForm,
  agreementPublisherRules: Array<CreateOrUpdateAgreementPublisherRule>
};


export type CreatePublisherWithAgreementPublisherRulesFromStagingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPublisherWithAgreementPublisherRulesFromStaging'>
);

export type DeleteMediumArticleMutationVariables = {
  mediumId: Scalars['Int'],
  mediumArticleId: Scalars['Int']
};


export type DeleteMediumArticleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMediumArticle'>
);

export type FinalizeMonthlyDividendManualOperationMutationVariables = {
  id: Scalars['Int']
};


export type FinalizeMonthlyDividendManualOperationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'finalizeMonthlyDividendManualOperation'>
);

export type InviteUserForPublisherMutationVariables = {
  input: InviteUserForPublisher
};


export type InviteUserForPublisherMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteUserForPublisher'>
);

export type ReadAnnouncementsMutationVariables = {
  ids: Array<Scalars['Int']>
};


export type ReadAnnouncementsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'readAnnouncements'>
);

export type UpdateUserForPublisherMutationVariables = {
  input: UpdateUserForPublisher
};


export type UpdateUserForPublisherMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserForPublisher'>
);

export type UpsertAgreementMutationVariables = {
  input: CreateOrUpdateAgreementForm,
  agreementBankAccounts?: Maybe<Array<CreateOrUpdateAgreementBankAccount>>
};


export type UpsertAgreementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertAgreement'>
);

export type UpsertAnnouncementMutationVariables = {
  input: CreateOrUpdateAnnouncement
};


export type UpsertAnnouncementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertAnnouncement'>
);

export type UpsertFeedMutationVariables = {
  input: CreateOrUpdateFeedForm
};


export type UpsertFeedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertFeed'>
);

export type UpsertPublisherMutationVariables = {
  publisher: CreateOrUpdatePublisherForm
};


export type UpsertPublisherMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertPublisher'>
);

export type UpsertPublisherWithAgreementPublisherRulesMutationVariables = {
  publisher: CreateOrUpdatePublisherForm,
  agreementPublisherRules: Array<CreateOrUpdateAgreementPublisherRule>
};


export type UpsertPublisherWithAgreementPublisherRulesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertPublisherWithAgreementPublisherRules'>
);

export type AgreementQueryVariables = {
  id: Scalars['Int'],
  withAgreementBankAccount?: Scalars['Boolean'],
  withInhouseNote?: Scalars['Boolean'],
  withInhouseAgreementField?: Scalars['Boolean']
};


export type AgreementQuery = (
  { __typename?: 'Query' }
  & { agreement: (
    { __typename?: 'Agreement' }
    & Pick<Agreement, 'id' | 'isTerminated' | 'name' | 'mediumId' | 'organizationId' | 'code' | 'terminatedAt' | 'createdAt' | 'updatedAt'>
    & { inhouseAgreementField: Maybe<(
      { __typename?: 'InhouseAgreementField' }
      & Pick<InhouseAgreementField, 'feePaidBy' | 'paymentTiming' | 'minimumRevenueType' | 'fixedRevenue' | 'minimumRevenue'>
    )>, notes: Maybe<(
      { __typename?: 'InhouseNote' }
      & Pick<InhouseNote, 'notes'>
    )>, organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    ), agreementBankAccounts: Array<(
      { __typename?: 'AgreementBankAccount' }
      & Pick<AgreementBankAccount, 'id' | 'agreementId' | 'bankAccountId' | 'usedFromMonth' | 'createdAt' | 'updatedAt'>
      & { bankAccount: (
        { __typename?: 'BankAccount' }
        & Pick<BankAccount, 'id' | 'enabled' | 'typeName' | 'number' | 'holder' | 'createdAt' | 'updatedAt'>
        & { bank: (
          { __typename?: 'Bank' }
          & Pick<Bank, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
        ), bankBranch: (
          { __typename?: 'BankBranch' }
          & Pick<BankBranch, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
        ) }
      ) }
    )>, inUseAgreementBankAccount: Maybe<(
      { __typename?: 'AgreementBankAccount' }
      & Pick<AgreementBankAccount, 'id' | 'agreementId' | 'bankAccountId' | 'usedFromMonth' | 'createdAt' | 'updatedAt'>
      & { bankAccount: (
        { __typename?: 'BankAccount' }
        & Pick<BankAccount, 'id' | 'enabled' | 'typeName' | 'number' | 'holder' | 'createdAt' | 'updatedAt'>
        & { bank: (
          { __typename?: 'Bank' }
          & Pick<Bank, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
        ), bankBranch: (
          { __typename?: 'BankBranch' }
          & Pick<BankBranch, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
        ) }
      ) }
    )> }
  ) }
);

export type AgreementsQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  orderBy?: Maybe<AgreementOrder>,
  param?: Maybe<AgreementConditionParam>,
  q?: Maybe<Scalars['String']>,
  withAgreementBankAccount?: Scalars['Boolean'],
  withInhouseNote?: Scalars['Boolean'],
  withInhouseAgreementField?: Scalars['Boolean']
};


export type AgreementsQuery = (
  { __typename?: 'Query' }
  & { agreements: (
    { __typename?: 'AgreementConnection' }
    & Pick<AgreementConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'Agreement' }
      & Pick<Agreement, 'id' | 'isTerminated' | 'name' | 'mediumId' | 'organizationId' | 'code' | 'terminatedAt' | 'createdAt' | 'updatedAt'>
      & { inhouseAgreementField: Maybe<(
        { __typename?: 'InhouseAgreementField' }
        & Pick<InhouseAgreementField, 'feePaidBy' | 'paymentTiming' | 'minimumRevenueType' | 'fixedRevenue' | 'minimumRevenue'>
      )>, notes: Maybe<(
        { __typename?: 'InhouseNote' }
        & Pick<InhouseNote, 'notes'>
      )>, organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
      ), agreementBankAccounts: Array<(
        { __typename?: 'AgreementBankAccount' }
        & Pick<AgreementBankAccount, 'id' | 'agreementId' | 'bankAccountId' | 'usedFromMonth' | 'createdAt' | 'updatedAt'>
        & { bankAccount: (
          { __typename?: 'BankAccount' }
          & Pick<BankAccount, 'id' | 'enabled' | 'typeName' | 'number' | 'holder' | 'createdAt' | 'updatedAt'>
          & { bank: (
            { __typename?: 'Bank' }
            & Pick<Bank, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
          ), bankBranch: (
            { __typename?: 'BankBranch' }
            & Pick<BankBranch, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
          ) }
        ) }
      )>, inUseAgreementBankAccount: Maybe<(
        { __typename?: 'AgreementBankAccount' }
        & Pick<AgreementBankAccount, 'id' | 'agreementId' | 'bankAccountId' | 'usedFromMonth' | 'createdAt' | 'updatedAt'>
        & { bankAccount: (
          { __typename?: 'BankAccount' }
          & Pick<BankAccount, 'id' | 'enabled' | 'typeName' | 'number' | 'holder' | 'createdAt' | 'updatedAt'>
          & { bank: (
            { __typename?: 'Bank' }
            & Pick<Bank, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
          ), bankBranch: (
            { __typename?: 'BankBranch' }
            & Pick<BankBranch, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
          ) }
        ) }
      )> }
    )> }
  ) }
);

export type AnnouncementQueryVariables = {
  id: Scalars['Int']
};


export type AnnouncementQuery = (
  { __typename?: 'Query' }
  & { announcement: (
    { __typename?: 'Announcement' }
    & Pick<Announcement, 'id' | 'enabled' | 'level' | 'title' | 'body' | 'startAt' | 'endAt' | 'closeButtonText' | 'isRead' | 'createdAt' | 'updatedAt'>
  ) }
);

export type AnnouncementsQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  orderBy?: Maybe<AnnouncementOrder>,
  param?: Maybe<AnnouncementConditionParam>
};


export type AnnouncementsQuery = (
  { __typename?: 'Query' }
  & { announcements: (
    { __typename?: 'AnnouncementConnection' }
    & Pick<AnnouncementConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'Announcement' }
      & Pick<Announcement, 'id' | 'enabled' | 'level' | 'title' | 'body' | 'startAt' | 'endAt' | 'closeButtonText' | 'isRead' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type ArticleStatsGroupByArticleIdQueryVariables = {
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  groupByArticleIdParam?: Maybe<ArticleStatsGroupByArticleIdConditionParam>
};


export type ArticleStatsGroupByArticleIdQuery = (
  { __typename?: 'Query' }
  & { articleStats: (
    { __typename?: 'ArticleStatsConnection' }
    & Pick<ArticleStatsConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'ArticleStatsGroupByArticleID' }
      & Pick<ArticleStatsGroupByArticleId, 'organizationId' | 'publisherId' | 'articleId' | 'title' | 'url' | 'impression' | 'click' | 'ctr' | 'createdAt' | 'publishedAt'>
      & { articleClickDemographics: Array<(
        { __typename?: 'ArticleClickDemographic' }
        & Pick<ArticleClickDemographic, 'genderId' | 'ageId' | 'click'>
      )>, publisher: Maybe<(
        { __typename?: 'Publisher' }
        & Pick<Publisher, 'id' | 'enabled' | 'name'>
      )> }
    )> }
  ) }
);

export type ArticleStatsGroupByDateQueryVariables = {
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  groupByDateParam?: Maybe<ArticleStatsGroupByDateConditionParam>
};


export type ArticleStatsGroupByDateQuery = (
  { __typename?: 'Query' }
  & { articleStats: (
    { __typename?: 'ArticleStatsConnection' }
    & Pick<ArticleStatsConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'ArticleStatsGroupByDate' }
      & Pick<ArticleStatsGroupByDate, 'date' | 'organizationId' | 'publisherId' | 'mediumId' | 'articleId' | 'title' | 'url' | 'impression' | 'click' | 'ctr'>
      & { articleClickDemographics: Array<(
        { __typename?: 'ArticleClickDemographic' }
        & Pick<ArticleClickDemographic, 'genderId' | 'ageId' | 'click'>
      )> }
    )> }
  ) }
);

export type AvailableMediaQueryVariables = {
  param?: Maybe<AvailableMediaParam>
};


export type AvailableMediaQuery = (
  { __typename?: 'Query' }
  & { availableMedia: (
    { __typename?: 'MediaConnection' }
    & Pick<MediaConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'Medium' }
      & Pick<Medium, 'id' | 'name'>
    )> }
  ) }
);

export type BankAccountsQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  param?: Maybe<BankAccountConditionParam>
};


export type BankAccountsQuery = (
  { __typename?: 'Query' }
  & { bankAccounts: (
    { __typename?: 'BankAccountConnection' }
    & Pick<BankAccountConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'BankAccount' }
      & Pick<BankAccount, 'id' | 'enabled' | 'typeName' | 'number' | 'holder' | 'createdAt' | 'updatedAt'>
      & { organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
      ), bank: (
        { __typename?: 'Bank' }
        & Pick<Bank, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
      ), bankBranch: (
        { __typename?: 'BankBranch' }
        & Pick<BankBranch, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
      ) }
    )> }
  ) }
);

export type BankBranchesQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  bankId: Scalars['Int'],
  q?: Maybe<Scalars['String']>
};


export type BankBranchesQuery = (
  { __typename?: 'Query' }
  & { bankBranches: (
    { __typename?: 'BankBranchConnection' }
    & Pick<BankBranchConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'BankBranch' }
      & Pick<BankBranch, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type BanksQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  q?: Maybe<Scalars['String']>
};


export type BanksQuery = (
  { __typename?: 'Query' }
  & { banks: (
    { __typename?: 'BankConnection' }
    & Pick<BankConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'Bank' }
      & Pick<Bank, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type FeedQueryVariables = {
  id: Scalars['Int'],
  withInhouseNote?: Scalars['Boolean']
};


export type FeedQuery = (
  { __typename?: 'Query' }
  & { feed: (
    { __typename?: 'Feed' }
    & Pick<Feed, 'id' | 'enabled' | 'name' | 'shortName' | 'siteUrl' | 'description' | 'mediumId' | 'legacyFeedId' | 'url' | 'logoUrl' | 'wideLogoUrl' | 'bannerUrl' | 'isDeleteFeed' | 'isSafeForFirstview' | 'relatedLinkNum' | 'articleExpirationDays' | 'availabilityForPromo' | 'type' | 'allowVideo' | 'allowContentBody' | 'ttlMinutes' | 'manuallyFeedScore' | 'createdAt' | 'updatedAt'>
    & { notes: Maybe<(
      { __typename?: 'InhouseNote' }
      & Pick<InhouseNote, 'notes'>
    )>, publisher: (
      { __typename?: 'Publisher' }
      & Pick<Publisher, 'id' | 'enabled' | 'organizationId' | 'name'>
      & { organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
      ) }
    ) }
  ) }
);

export type FeedsQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  orderBy?: Maybe<FeedOrder>,
  param?: Maybe<FeedConditionParam>,
  q?: Maybe<Scalars['String']>,
  withInhouseNote?: Scalars['Boolean']
};


export type FeedsQuery = (
  { __typename?: 'Query' }
  & { feeds: (
    { __typename?: 'FeedConnection' }
    & Pick<FeedConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'Feed' }
      & Pick<Feed, 'id' | 'enabled' | 'name' | 'shortName' | 'siteUrl' | 'description' | 'mediumId' | 'legacyFeedId' | 'url' | 'logoUrl' | 'wideLogoUrl' | 'bannerUrl' | 'isDeleteFeed' | 'isSafeForFirstview' | 'relatedLinkNum' | 'articleExpirationDays' | 'availabilityForPromo' | 'type' | 'allowVideo' | 'allowContentBody' | 'ttlMinutes' | 'manuallyFeedScore' | 'createdAt' | 'updatedAt'>
      & { notes: Maybe<(
        { __typename?: 'InhouseNote' }
        & Pick<InhouseNote, 'notes'>
      )>, publisher: (
        { __typename?: 'Publisher' }
        & Pick<Publisher, 'id' | 'enabled' | 'organizationId' | 'name'>
        & { organization: (
          { __typename?: 'Organization' }
          & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
        ) }
      ) }
    )> }
  ) }
);

export type ImageUploadUrlQueryVariables = {
  mimeType: Scalars['String']
};


export type ImageUploadUrlQuery = (
  { __typename?: 'Query' }
  & { imageUploadUrl: (
    { __typename?: 'ImageUploadURLResponse' }
    & Pick<ImageUploadUrlResponse, 'uploadUrl' | 'imageUrl'>
  ) }
);

export type MediumArticlesQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  param?: Maybe<MediumArticleConditionParam>,
  q?: Maybe<Scalars['String']>
};


export type MediumArticlesQuery = (
  { __typename?: 'Query' }
  & { mediumArticles: (
    { __typename?: 'MediumArticleConnection' }
    & Pick<MediumArticleConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'MediumArticle' }
      & Pick<MediumArticle, 'articleId' | 'mediumId' | 'mediumArticleId' | 'enabled' | 'title' | 'url' | 'feedId' | 'publishedAt' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type MonthlyDividendManualOperationsQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int']
};


export type MonthlyDividendManualOperationsQuery = (
  { __typename?: 'Query' }
  & { monthlyDividendManualOperations: (
    { __typename?: 'MonthlyDividendManualOperationConnection' }
    & Pick<MonthlyDividendManualOperationConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'MonthlyDividendManualOperation' }
      & Pick<MonthlyDividendManualOperation, 'id' | 'dividendMonth' | 'dividendExtraRevenue' | 'hasBeenFinalized' | 'finalizedAt' | 'createdAt' | 'updatedAt'>
      & { dividends: Array<(
        { __typename?: 'Dividend' }
        & Pick<Dividend, 'id' | 'mediumId' | 'userUid' | 'dividendMethod' | 'extraRevenue' | 'dividendExtraRevenue' | 'extraRevenueMonth' | 'notes' | 'jobStatus' | 'completedAt' | 'createdAt' | 'updatedAt'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'uid' | 'email'>
        ) }
      )> }
    )> }
  ) }
);

export type MonthlyPaymentsQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  param?: Maybe<MonthlyPaymentsConditionParam>,
  withAgreementBankAccount?: Scalars['Boolean'],
  withInhouseNote?: Scalars['Boolean'],
  withInhouseAgreementField?: Scalars['Boolean']
};


export type MonthlyPaymentsQuery = (
  { __typename?: 'Query' }
  & { monthlyPayments: (
    { __typename?: 'MonthlyPaymentConnection' }
    & Pick<MonthlyPaymentConnection, 'total' | 'sum' | 'sumWithoutTax' | 'taxSum' | 'sumRevenue' | 'sumRevenueTax' | 'sumRevenueWithoutTax'>
    & { nodes: Array<(
      { __typename?: 'MonthlyPayment' }
      & Pick<MonthlyPayment, 'id' | 'monthlyAgreementSnapshotId' | 'agreementId' | 'mediumId' | 'sspRevenue' | 'videoSSPRevenue' | 'liveSSPRevenue' | 'gunosyAdsRevenue' | 'revenue' | 'revenueIncludingManualAdjustmentRevenue' | 'revenueIncludingManualAdjustmentRevenueTax' | 'revenueIncludingManualAdjustmentRevenueIncludingTax' | 'manualAdjustmentRevenue' | 'carriedOverRevenue' | 'cumulativeCarriedOverRevenueIncludingTax' | 'cumulativeRevenue' | 'payment' | 'taxRate' | 'paymentTax' | 'paymentIncludingTax' | 'hasBeenFinalized' | 'revenueGeneratedAtMonth' | 'revenueGeneratedByDate' | 'finalizedAtMonth' | 'paidAtMonth' | 'createdAt' | 'updatedAt'>
      & { monthlyAgreementSnapshot: (
        { __typename?: 'MonthlyAgreementSnapshot' }
        & Pick<MonthlyAgreementSnapshot, 'id' | 'isTerminated' | 'name' | 'mediumId' | 'organizationId' | 'code' | 'partnerName' | 'qualifiedInvoiceIssuer' | 'invoiceRegistrationNumber' | 'agreementId' | 'month' | 'terminatedAt' | 'createdAt' | 'updatedAt'>
        & { inhouseAgreementField: Maybe<(
          { __typename?: 'InhouseAgreementField' }
          & Pick<InhouseAgreementField, 'feePaidBy' | 'paymentTiming' | 'minimumRevenueType' | 'fixedRevenue' | 'minimumRevenue'>
        )>, notes: Maybe<(
          { __typename?: 'InhouseNote' }
          & Pick<InhouseNote, 'notes'>
        )>, organization: (
          { __typename?: 'Organization' }
          & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
        ), targetAgreementBankAccount: Maybe<(
          { __typename?: 'AgreementBankAccount' }
          & Pick<AgreementBankAccount, 'id' | 'agreementId' | 'bankAccountId' | 'usedFromMonth' | 'createdAt' | 'updatedAt'>
          & { bankAccount: (
            { __typename?: 'BankAccount' }
            & Pick<BankAccount, 'id' | 'enabled' | 'typeName' | 'number' | 'holder' | 'createdAt' | 'updatedAt'>
            & { bank: (
              { __typename?: 'Bank' }
              & Pick<Bank, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
            ), bankBranch: (
              { __typename?: 'BankBranch' }
              & Pick<BankBranch, 'id' | 'code' | 'name' | 'kana' | 'hira' | 'roma' | 'createdAt' | 'updatedAt'>
            ) }
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type MonthlyPublisherPaymentsQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  param?: Maybe<MonthlyPublisherPaymentConditionParam>,
  withMonthlyPayment?: Scalars['Boolean'],
  withAgreementBankAccountPublisherRuleSnapshot?: Scalars['Boolean']
};


export type MonthlyPublisherPaymentsQuery = (
  { __typename?: 'Query' }
  & { monthlyPublisherPayments: (
    { __typename?: 'MonthlyPublisherPaymentConnection' }
    & Pick<MonthlyPublisherPaymentConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'MonthlyPublisherPayment' }
      & Pick<MonthlyPublisherPayment, 'id' | 'monthlyPaymentId' | 'publisherId' | 'sspRevenue' | 'videoSSPRevenue' | 'liveSSPRevenue' | 'gunosyAdsRevenue' | 'createdAt' | 'updatedAt'>
      & { publisher: (
        { __typename?: 'Publisher' }
        & Pick<Publisher, 'id' | 'enabled' | 'organizationId' | 'name'>
      ), monthlyPayment: (
        { __typename?: 'MonthlyPayment' }
        & Pick<MonthlyPayment, 'id' | 'monthlyAgreementSnapshotId' | 'agreementId' | 'mediumId' | 'hasBeenFinalized'>
        & { monthlyAgreementSnapshot: (
          { __typename?: 'MonthlyAgreementSnapshot' }
          & Pick<MonthlyAgreementSnapshot, 'id' | 'isTerminated' | 'name' | 'agreementId' | 'month'>
        ) }
      ), monthlyAgreementPublisherRuleSnapshot: (
        { __typename?: 'MonthlyAgreementPublisherRuleSnapshot' }
        & Pick<MonthlyAgreementPublisherRuleSnapshot, 'id' | 'monthlyAgreementSnapshotId' | 'publisherId' | 'mediumId' | 'fixedRevenue' | 'minimumRevenue'>
        & { paymentRate: Maybe<(
          { __typename?: 'PaymentRate' }
          & Pick<PaymentRate, 'paymentRate' | 'sspPaymentRate' | 'videoSspPaymentRate' | 'liveSspPaymentRate' | 'isTargetForSspAllocation' | 'isTargetForVideoSspAllocation' | 'isTargetForLiveSspAllocation'>
        )> }
      ) }
    )> }
  ) }
);

export type PublisherQueryVariables = {
  id: Scalars['Int'],
  withAgreementPublisherRule?: Scalars['Boolean'],
  withInhouseNote?: Scalars['Boolean'],
  withOrganization?: Scalars['Boolean']
};


export type PublisherQuery = (
  { __typename?: 'Query' }
  & { publisher: (
    { __typename?: 'Publisher' }
    & Pick<Publisher, 'id' | 'enabled' | 'organizationId' | 'name' | 'createdAt' | 'updatedAt'>
    & { notes: Maybe<(
      { __typename?: 'InhouseNote' }
      & Pick<InhouseNote, 'notes'>
    )>, organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    ), agreementPublisherRules: Array<(
      { __typename?: 'AgreementPublisherRule' }
      & Pick<AgreementPublisherRule, 'id' | 'mediumId' | 'fixedRevenue' | 'minimumRevenue' | 'createdAt' | 'updatedAt'>
      & { paymentRate: Maybe<(
        { __typename?: 'PaymentRate' }
        & Pick<PaymentRate, 'paymentRate' | 'sspPaymentRate' | 'videoSspPaymentRate' | 'liveSspPaymentRate' | 'isTargetForSspAllocation' | 'isTargetForVideoSspAllocation' | 'isTargetForLiveSspAllocation'>
      )>, agreement: (
        { __typename?: 'Agreement' }
        & Pick<Agreement, 'id' | 'isTerminated' | 'terminatedAt' | 'name'>
        & { inhouseAgreementField: Maybe<(
          { __typename?: 'InhouseAgreementField' }
          & Pick<InhouseAgreementField, 'feePaidBy' | 'paymentTiming' | 'minimumRevenueType' | 'fixedRevenue' | 'minimumRevenue'>
        )> }
      ) }
    )> }
  ) }
);

export type PublisherRankQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  param?: Maybe<PublisherRankConditionParam>
};


export type PublisherRankQuery = (
  { __typename?: 'Query' }
  & { publisherRank: (
    { __typename?: 'PublisherRankConnection' }
    & Pick<PublisherRankConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'PublisherRank' }
      & Pick<PublisherRank, 'publisherId' | 'mediumId' | 'rank' | 'articleImpression' | 'articleClick' | 'videoImpression' | 'videoClick' | 'videoWatch_3s' | 'videoWatch_97p' | 'liveWatch' | 'gunosyAdsRevenue' | 'sspRevenue' | 'videoSspRevenue' | 'liveSspRevenue' | 'publisherGunosyAdsRevenue' | 'publisherSspRevenue' | 'publisherVideoSspRevenue' | 'publisherLiveSspRevenue'>
      & { publisher: (
        { __typename?: 'Publisher' }
        & Pick<Publisher, 'id' | 'enabled' | 'organizationId' | 'name' | 'createdAt' | 'updatedAt'>
        & { notes: Maybe<(
          { __typename?: 'InhouseNote' }
          & Pick<InhouseNote, 'notes'>
        )> }
      ) }
    )> }
  ) }
);

export type PublishersQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  orderBy?: Maybe<PublisherOrder>,
  param?: Maybe<PublisherConditionParam>,
  q?: Maybe<Scalars['String']>,
  withAgreementPublisherRule?: Scalars['Boolean'],
  withInhouseNote?: Scalars['Boolean'],
  withOrganization?: Scalars['Boolean']
};


export type PublishersQuery = (
  { __typename?: 'Query' }
  & { publishers: (
    { __typename?: 'PublisherConnection' }
    & Pick<PublisherConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'Publisher' }
      & Pick<Publisher, 'id' | 'enabled' | 'organizationId' | 'name' | 'createdAt' | 'updatedAt'>
      & { notes: Maybe<(
        { __typename?: 'InhouseNote' }
        & Pick<InhouseNote, 'notes'>
      )>, organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
      ), agreementPublisherRules: Array<(
        { __typename?: 'AgreementPublisherRule' }
        & Pick<AgreementPublisherRule, 'id' | 'mediumId' | 'fixedRevenue' | 'minimumRevenue' | 'createdAt' | 'updatedAt'>
        & { paymentRate: Maybe<(
          { __typename?: 'PaymentRate' }
          & Pick<PaymentRate, 'paymentRate' | 'sspPaymentRate' | 'videoSspPaymentRate' | 'liveSspPaymentRate' | 'isTargetForSspAllocation' | 'isTargetForVideoSspAllocation' | 'isTargetForLiveSspAllocation'>
        )>, agreement: (
          { __typename?: 'Agreement' }
          & Pick<Agreement, 'id' | 'isTerminated' | 'terminatedAt' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type RssQueryVariables = {
  rssUrl: Scalars['String']
};


export type RssQuery = (
  { __typename?: 'Query' }
  & { rss: (
    { __typename?: 'Rss' }
    & Pick<Rss, 'name' | 'description' | 'siteUrl' | 'logoUrl' | 'wideLogoUrl'>
  ) }
);

export type StagingFeedsQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  orderBy?: Maybe<FeedOrder>,
  param?: Maybe<FeedConditionParam>,
  q?: Maybe<Scalars['String']>
};


export type StagingFeedsQuery = (
  { __typename?: 'Query' }
  & { stagingFeeds: (
    { __typename?: 'StagingFeedConnection' }
    & Pick<StagingFeedConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'Feed' }
      & Pick<Feed, 'id' | 'enabled' | 'name' | 'shortName' | 'siteUrl' | 'description' | 'mediumId' | 'legacyFeedId' | 'url' | 'logoUrl' | 'wideLogoUrl' | 'bannerUrl' | 'isDeleteFeed' | 'isSafeForFirstview' | 'relatedLinkNum' | 'articleExpirationDays' | 'availabilityForPromo' | 'type' | 'allowVideo' | 'allowContentBody' | 'ttlMinutes' | 'manuallyFeedScore' | 'createdAt' | 'updatedAt'>
      & { notes: Maybe<(
        { __typename?: 'InhouseNote' }
        & Pick<InhouseNote, 'notes'>
      )> }
    )> }
  ) }
);

export type StagingPublishersQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  orderBy?: Maybe<PublisherOrder>,
  param?: Maybe<PublisherConditionParam>,
  q?: Maybe<Scalars['String']>
};


export type StagingPublishersQuery = (
  { __typename?: 'Query' }
  & { stagingPublishers: (
    { __typename?: 'StagingPublisherConnection' }
    & Pick<StagingPublisherConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'Publisher' }
      & Pick<Publisher, 'id' | 'enabled' | 'name' | 'createdAt' | 'updatedAt'>
      & { notes: Maybe<(
        { __typename?: 'InhouseNote' }
        & Pick<InhouseNote, 'notes'>
      )> }
    )> }
  ) }
);

export type SummaryStatsQueryVariables = {
  param?: Maybe<ConditionParam>,
  withInhouseRevenueDetail: Scalars['Boolean']
};


export type SummaryStatsQuery = (
  { __typename?: 'Query' }
  & { summaryStats: Array<(
    { __typename?: 'SummaryStats' }
    & Pick<SummaryStats, 'date' | 'organizationId' | 'publisherId' | 'mediumId' | 'follower' | 'articleImpression' | 'articleClick' | 'videoImpression' | 'videoClick' | 'videoWatch3s' | 'videoWatch97p' | 'liveWatch' | 'publisherTotalRevenue' | 'hasBeenFinalized'>
    & { inhouseRevenueDetail: Maybe<(
      { __typename?: 'InhouseRevenueDetail' }
      & Pick<InhouseRevenueDetail, 'gunosyAdsRevenue' | 'sspRevenue' | 'videoSSPRevenue' | 'liveSSPRevenue' | 'publisherGunosyAdsRevenue' | 'publisherSSPRevenue' | 'publisherVideoSSPRevenue' | 'publisherLiveSSPRevenue'>
    )>, articleClickDemographics: Array<(
      { __typename?: 'ArticleClickDemographic' }
      & Pick<ArticleClickDemographic, 'genderId' | 'ageId' | 'click'>
    )> }
  )> }
);

export type VideoStatsGroupByDateQueryVariables = {
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  groupByDateParam?: Maybe<VideoStatsGroupByDateConditionParam>
};


export type VideoStatsGroupByDateQuery = (
  { __typename?: 'Query' }
  & { videoStats: (
    { __typename?: 'VideoStatsConnection' }
    & Pick<VideoStatsConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'VideoStatsGroupByDate' }
      & Pick<VideoStatsGroupByDate, 'date' | 'organizationId' | 'publisherId' | 'mediumId' | 'videoId' | 'title' | 'URL' | 'thumbnailURL' | 'impression' | 'click' | 'ctr' | 'watch3s' | 'play_time' | 'average_play_time' | 'watch97p' | 'average_watch97p' | 'duration'>
      & { watch3sDemographics: Array<(
        { __typename?: 'Watch3sDemographic' }
        & Pick<Watch3sDemographic, 'genderId' | 'ageId' | 'watch3s'>
      )>, watchTimeDistribution: Array<(
        { __typename?: 'WatchTimeDistribution' }
        & Pick<WatchTimeDistribution, 'watchTime' | 'count'>
      )>, listWatchTimeDistribution: Array<(
        { __typename?: 'WatchTimeDistribution' }
        & Pick<WatchTimeDistribution, 'watchTime' | 'count'>
      )>, detailWatchTimeDistribution: Array<(
        { __typename?: 'WatchTimeDistribution' }
        & Pick<WatchTimeDistribution, 'watchTime' | 'count'>
      )> }
    )> }
  ) }
);

export type VideoStatsGroupByVideoIdQueryVariables = {
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  groupByVideoIdParam?: Maybe<VideoStatsGroupByVideoIdConditionParam>
};


export type VideoStatsGroupByVideoIdQuery = (
  { __typename?: 'Query' }
  & { videoStats: (
    { __typename?: 'VideoStatsConnection' }
    & Pick<VideoStatsConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'VideoStatsGroupByVideoID' }
      & Pick<VideoStatsGroupByVideoId, 'organizationId' | 'publisherId' | 'videoId' | 'title' | 'URL' | 'thumbnailURL' | 'impression' | 'click' | 'ctr' | 'watch3s' | 'play_time' | 'average_play_time' | 'watch97p' | 'average_watch97p' | 'duration' | 'createdAt' | 'publishedAt'>
      & { watch3sDemographics: Array<(
        { __typename?: 'Watch3sDemographic' }
        & Pick<Watch3sDemographic, 'genderId' | 'ageId' | 'watch3s'>
      )>, watchTimeDistribution: Array<(
        { __typename?: 'WatchTimeDistribution' }
        & Pick<WatchTimeDistribution, 'watchTime' | 'count'>
      )>, publisher: Maybe<(
        { __typename?: 'Publisher' }
        & Pick<Publisher, 'id' | 'enabled' | 'name'>
      )> }
    )> }
  ) }
);

export type CreateOrganizationMutationVariables = {
  input: CreateOrUpdateOrganization
};


export type CreateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrganization'>
);

export type DeleteOrganizationMutationVariables = {
  id: Scalars['Int']
};


export type DeleteOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOrganization'>
);

export type DeleteUserMutationVariables = {
  uid: Scalars['ID']
};


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type InviteUserMutationVariables = {
  input: InviteUser
};


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteUser'>
);

export type RegenerateInviteLinkMutationVariables = {
  uid: Scalars['ID']
};


export type RegenerateInviteLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'regenerateInviteLink'>
);

export type UpdateEmailMutationVariables = {
  uid: Scalars['String'],
  email: Scalars['String']
};


export type UpdateEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateEmail'>
);

export type UpdateOrganizationMutationVariables = {
  id: Scalars['Int'],
  input: CreateOrUpdateOrganization
};


export type UpdateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrganization'>
);

export type UpdateRolesMutationVariables = {
  uid: Scalars['String'],
  roles: Array<Scalars['String']>
};


export type UpdateRolesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRoles'>
);

export type OrganizationQueryVariables = {
  id: Scalars['Int']
};


export type OrganizationQuery = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  ) }
);

export type OrganizationsQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  ids?: Maybe<Array<Scalars['Int']>>,
  q?: Maybe<Scalars['String']>,
  enabled?: Maybe<Scalars['Boolean']>
};


export type OrganizationsQuery = (
  { __typename?: 'Query' }
  & { organizations: (
    { __typename?: 'OrganizationConnection' }
    & Pick<OrganizationConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type PoliciesQueryVariables = {
  role?: Maybe<Scalars['String']>
};


export type PoliciesQuery = (
  { __typename?: 'Query' }
  & { policies: Array<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'actions'>
    & { resources: Array<(
      { __typename?: 'Resource' }
      & Pick<Resource, 'name'>
      & { isSameCond: Array<(
        { __typename?: 'ResourceCondition' }
        & Pick<ResourceCondition, 'key' | 'value'>
      )> }
    )> }
  )> }
);

export type RolesQueryVariables = {};


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'name'>
  )> }
);

export type UserQueryVariables = {
  userID: Scalars['String']
};


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'uid' | 'email' | 'roles' | 'createdAt' | 'updatedAt'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    ) }
  ) }
);

export type UsersQueryVariables = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  userIDs?: Maybe<Array<Scalars['String']>>,
  organizationId?: Maybe<Scalars['Int']>,
  q?: Maybe<Scalars['String']>,
  enabled?: Maybe<Scalars['Boolean']>
};


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserConnection' }
    & Pick<UserConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'User' }
      & Pick<User, 'uid' | 'email' | 'roles' | 'createdAt' | 'updatedAt'>
      & { organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'createdAt' | 'updatedAt'>
      ) }
    )> }
  ) }
);


export const CreateAgreementsDocument = gql`
    mutation createAgreements($input: [CreateAgreementForm!], $agreementBankAccounts: [CreateOrUpdateAgreementBankAccount!]) {
  createAgreements(input: $input, agreementBankAccounts: $agreementBankAccounts)
}
    `;
export type CreateAgreementsMutationFn = ApolloReactCommon.MutationFunction<CreateAgreementsMutation, CreateAgreementsMutationVariables>;

/**
 * __useCreateAgreementsMutation__
 *
 * To run a mutation, you first call `useCreateAgreementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAgreementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAgreementsMutation, { data, loading, error }] = useCreateAgreementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      agreementBankAccounts: // value for 'agreementBankAccounts'
 *   },
 * });
 */
export function useCreateAgreementsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAgreementsMutation, CreateAgreementsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAgreementsMutation, CreateAgreementsMutationVariables>(CreateAgreementsDocument, baseOptions);
      }
export type CreateAgreementsMutationHookResult = ReturnType<typeof useCreateAgreementsMutation>;
export type CreateAgreementsMutationResult = ApolloReactCommon.MutationResult<CreateAgreementsMutation>;
export type CreateAgreementsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAgreementsMutation, CreateAgreementsMutationVariables>;
export const CreateBankAccountDocument = gql`
    mutation createBankAccount($input: CreateBankAccount!) {
  createBankAccount(input: $input)
}
    `;
export type CreateBankAccountMutationFn = ApolloReactCommon.MutationFunction<CreateBankAccountMutation, CreateBankAccountMutationVariables>;

/**
 * __useCreateBankAccountMutation__
 *
 * To run a mutation, you first call `useCreateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankAccountMutation, { data, loading, error }] = useCreateBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBankAccountMutation, CreateBankAccountMutationVariables>(CreateBankAccountDocument, baseOptions);
      }
export type CreateBankAccountMutationHookResult = ReturnType<typeof useCreateBankAccountMutation>;
export type CreateBankAccountMutationResult = ApolloReactCommon.MutationResult<CreateBankAccountMutation>;
export type CreateBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>;
export const CreateDividendDocument = gql`
    mutation createDividend($input: CreateDividend!, $rawData: [CreateDividendRawData!]) {
  createDividend(input: $input, rawData: $rawData)
}
    `;
export type CreateDividendMutationFn = ApolloReactCommon.MutationFunction<CreateDividendMutation, CreateDividendMutationVariables>;

/**
 * __useCreateDividendMutation__
 *
 * To run a mutation, you first call `useCreateDividendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDividendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDividendMutation, { data, loading, error }] = useCreateDividendMutation({
 *   variables: {
 *      input: // value for 'input'
 *      rawData: // value for 'rawData'
 *   },
 * });
 */
export function useCreateDividendMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDividendMutation, CreateDividendMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDividendMutation, CreateDividendMutationVariables>(CreateDividendDocument, baseOptions);
      }
export type CreateDividendMutationHookResult = ReturnType<typeof useCreateDividendMutation>;
export type CreateDividendMutationResult = ApolloReactCommon.MutationResult<CreateDividendMutation>;
export type CreateDividendMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDividendMutation, CreateDividendMutationVariables>;
export const CreateFeedFromStagingDocument = gql`
    mutation createFeedFromStaging($input: CreateFeedFromStagingForm!) {
  createFeedFromStaging(input: $input)
}
    `;
export type CreateFeedFromStagingMutationFn = ApolloReactCommon.MutationFunction<CreateFeedFromStagingMutation, CreateFeedFromStagingMutationVariables>;

/**
 * __useCreateFeedFromStagingMutation__
 *
 * To run a mutation, you first call `useCreateFeedFromStagingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedFromStagingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedFromStagingMutation, { data, loading, error }] = useCreateFeedFromStagingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFeedFromStagingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFeedFromStagingMutation, CreateFeedFromStagingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFeedFromStagingMutation, CreateFeedFromStagingMutationVariables>(CreateFeedFromStagingDocument, baseOptions);
      }
export type CreateFeedFromStagingMutationHookResult = ReturnType<typeof useCreateFeedFromStagingMutation>;
export type CreateFeedFromStagingMutationResult = ApolloReactCommon.MutationResult<CreateFeedFromStagingMutation>;
export type CreateFeedFromStagingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFeedFromStagingMutation, CreateFeedFromStagingMutationVariables>;
export const CreatePublisherWithAgreementPublisherRulesFromStagingDocument = gql`
    mutation createPublisherWithAgreementPublisherRulesFromStaging($publisher: CreatePublisherFromStagingForm!, $agreementPublisherRules: [CreateOrUpdateAgreementPublisherRule!]!) {
  createPublisherWithAgreementPublisherRulesFromStaging(publisher: $publisher, agreementPublisherRules: $agreementPublisherRules)
}
    `;
export type CreatePublisherWithAgreementPublisherRulesFromStagingMutationFn = ApolloReactCommon.MutationFunction<CreatePublisherWithAgreementPublisherRulesFromStagingMutation, CreatePublisherWithAgreementPublisherRulesFromStagingMutationVariables>;

/**
 * __useCreatePublisherWithAgreementPublisherRulesFromStagingMutation__
 *
 * To run a mutation, you first call `useCreatePublisherWithAgreementPublisherRulesFromStagingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublisherWithAgreementPublisherRulesFromStagingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublisherWithAgreementPublisherRulesFromStagingMutation, { data, loading, error }] = useCreatePublisherWithAgreementPublisherRulesFromStagingMutation({
 *   variables: {
 *      publisher: // value for 'publisher'
 *      agreementPublisherRules: // value for 'agreementPublisherRules'
 *   },
 * });
 */
export function useCreatePublisherWithAgreementPublisherRulesFromStagingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePublisherWithAgreementPublisherRulesFromStagingMutation, CreatePublisherWithAgreementPublisherRulesFromStagingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePublisherWithAgreementPublisherRulesFromStagingMutation, CreatePublisherWithAgreementPublisherRulesFromStagingMutationVariables>(CreatePublisherWithAgreementPublisherRulesFromStagingDocument, baseOptions);
      }
export type CreatePublisherWithAgreementPublisherRulesFromStagingMutationHookResult = ReturnType<typeof useCreatePublisherWithAgreementPublisherRulesFromStagingMutation>;
export type CreatePublisherWithAgreementPublisherRulesFromStagingMutationResult = ApolloReactCommon.MutationResult<CreatePublisherWithAgreementPublisherRulesFromStagingMutation>;
export type CreatePublisherWithAgreementPublisherRulesFromStagingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePublisherWithAgreementPublisherRulesFromStagingMutation, CreatePublisherWithAgreementPublisherRulesFromStagingMutationVariables>;
export const DeleteMediumArticleDocument = gql`
    mutation deleteMediumArticle($mediumId: Int!, $mediumArticleId: Int!) {
  deleteMediumArticle(mediumId: $mediumId, mediumArticleId: $mediumArticleId)
}
    `;
export type DeleteMediumArticleMutationFn = ApolloReactCommon.MutationFunction<DeleteMediumArticleMutation, DeleteMediumArticleMutationVariables>;

/**
 * __useDeleteMediumArticleMutation__
 *
 * To run a mutation, you first call `useDeleteMediumArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMediumArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMediumArticleMutation, { data, loading, error }] = useDeleteMediumArticleMutation({
 *   variables: {
 *      mediumId: // value for 'mediumId'
 *      mediumArticleId: // value for 'mediumArticleId'
 *   },
 * });
 */
export function useDeleteMediumArticleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMediumArticleMutation, DeleteMediumArticleMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMediumArticleMutation, DeleteMediumArticleMutationVariables>(DeleteMediumArticleDocument, baseOptions);
      }
export type DeleteMediumArticleMutationHookResult = ReturnType<typeof useDeleteMediumArticleMutation>;
export type DeleteMediumArticleMutationResult = ApolloReactCommon.MutationResult<DeleteMediumArticleMutation>;
export type DeleteMediumArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMediumArticleMutation, DeleteMediumArticleMutationVariables>;
export const FinalizeMonthlyDividendManualOperationDocument = gql`
    mutation finalizeMonthlyDividendManualOperation($id: Int!) {
  finalizeMonthlyDividendManualOperation(id: $id)
}
    `;
export type FinalizeMonthlyDividendManualOperationMutationFn = ApolloReactCommon.MutationFunction<FinalizeMonthlyDividendManualOperationMutation, FinalizeMonthlyDividendManualOperationMutationVariables>;

/**
 * __useFinalizeMonthlyDividendManualOperationMutation__
 *
 * To run a mutation, you first call `useFinalizeMonthlyDividendManualOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeMonthlyDividendManualOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeMonthlyDividendManualOperationMutation, { data, loading, error }] = useFinalizeMonthlyDividendManualOperationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinalizeMonthlyDividendManualOperationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FinalizeMonthlyDividendManualOperationMutation, FinalizeMonthlyDividendManualOperationMutationVariables>) {
        return ApolloReactHooks.useMutation<FinalizeMonthlyDividendManualOperationMutation, FinalizeMonthlyDividendManualOperationMutationVariables>(FinalizeMonthlyDividendManualOperationDocument, baseOptions);
      }
export type FinalizeMonthlyDividendManualOperationMutationHookResult = ReturnType<typeof useFinalizeMonthlyDividendManualOperationMutation>;
export type FinalizeMonthlyDividendManualOperationMutationResult = ApolloReactCommon.MutationResult<FinalizeMonthlyDividendManualOperationMutation>;
export type FinalizeMonthlyDividendManualOperationMutationOptions = ApolloReactCommon.BaseMutationOptions<FinalizeMonthlyDividendManualOperationMutation, FinalizeMonthlyDividendManualOperationMutationVariables>;
export const InviteUserForPublisherDocument = gql`
    mutation inviteUserForPublisher($input: InviteUserForPublisher!) {
  inviteUserForPublisher(input: $input)
}
    `;
export type InviteUserForPublisherMutationFn = ApolloReactCommon.MutationFunction<InviteUserForPublisherMutation, InviteUserForPublisherMutationVariables>;

/**
 * __useInviteUserForPublisherMutation__
 *
 * To run a mutation, you first call `useInviteUserForPublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserForPublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserForPublisherMutation, { data, loading, error }] = useInviteUserForPublisherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserForPublisherMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteUserForPublisherMutation, InviteUserForPublisherMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteUserForPublisherMutation, InviteUserForPublisherMutationVariables>(InviteUserForPublisherDocument, baseOptions);
      }
export type InviteUserForPublisherMutationHookResult = ReturnType<typeof useInviteUserForPublisherMutation>;
export type InviteUserForPublisherMutationResult = ApolloReactCommon.MutationResult<InviteUserForPublisherMutation>;
export type InviteUserForPublisherMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteUserForPublisherMutation, InviteUserForPublisherMutationVariables>;
export const ReadAnnouncementsDocument = gql`
    mutation readAnnouncements($ids: [Int!]!) {
  readAnnouncements(ids: $ids)
}
    `;
export type ReadAnnouncementsMutationFn = ApolloReactCommon.MutationFunction<ReadAnnouncementsMutation, ReadAnnouncementsMutationVariables>;

/**
 * __useReadAnnouncementsMutation__
 *
 * To run a mutation, you first call `useReadAnnouncementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAnnouncementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAnnouncementsMutation, { data, loading, error }] = useReadAnnouncementsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useReadAnnouncementsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReadAnnouncementsMutation, ReadAnnouncementsMutationVariables>) {
        return ApolloReactHooks.useMutation<ReadAnnouncementsMutation, ReadAnnouncementsMutationVariables>(ReadAnnouncementsDocument, baseOptions);
      }
export type ReadAnnouncementsMutationHookResult = ReturnType<typeof useReadAnnouncementsMutation>;
export type ReadAnnouncementsMutationResult = ApolloReactCommon.MutationResult<ReadAnnouncementsMutation>;
export type ReadAnnouncementsMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadAnnouncementsMutation, ReadAnnouncementsMutationVariables>;
export const UpdateUserForPublisherDocument = gql`
    mutation updateUserForPublisher($input: UpdateUserForPublisher!) {
  updateUserForPublisher(input: $input)
}
    `;
export type UpdateUserForPublisherMutationFn = ApolloReactCommon.MutationFunction<UpdateUserForPublisherMutation, UpdateUserForPublisherMutationVariables>;

/**
 * __useUpdateUserForPublisherMutation__
 *
 * To run a mutation, you first call `useUpdateUserForPublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserForPublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserForPublisherMutation, { data, loading, error }] = useUpdateUserForPublisherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserForPublisherMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserForPublisherMutation, UpdateUserForPublisherMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserForPublisherMutation, UpdateUserForPublisherMutationVariables>(UpdateUserForPublisherDocument, baseOptions);
      }
export type UpdateUserForPublisherMutationHookResult = ReturnType<typeof useUpdateUserForPublisherMutation>;
export type UpdateUserForPublisherMutationResult = ApolloReactCommon.MutationResult<UpdateUserForPublisherMutation>;
export type UpdateUserForPublisherMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserForPublisherMutation, UpdateUserForPublisherMutationVariables>;
export const UpsertAgreementDocument = gql`
    mutation upsertAgreement($input: CreateOrUpdateAgreementForm!, $agreementBankAccounts: [CreateOrUpdateAgreementBankAccount!]) {
  upsertAgreement(input: $input, agreementBankAccounts: $agreementBankAccounts)
}
    `;
export type UpsertAgreementMutationFn = ApolloReactCommon.MutationFunction<UpsertAgreementMutation, UpsertAgreementMutationVariables>;

/**
 * __useUpsertAgreementMutation__
 *
 * To run a mutation, you first call `useUpsertAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAgreementMutation, { data, loading, error }] = useUpsertAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *      agreementBankAccounts: // value for 'agreementBankAccounts'
 *   },
 * });
 */
export function useUpsertAgreementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertAgreementMutation, UpsertAgreementMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertAgreementMutation, UpsertAgreementMutationVariables>(UpsertAgreementDocument, baseOptions);
      }
export type UpsertAgreementMutationHookResult = ReturnType<typeof useUpsertAgreementMutation>;
export type UpsertAgreementMutationResult = ApolloReactCommon.MutationResult<UpsertAgreementMutation>;
export type UpsertAgreementMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertAgreementMutation, UpsertAgreementMutationVariables>;
export const UpsertAnnouncementDocument = gql`
    mutation upsertAnnouncement($input: CreateOrUpdateAnnouncement!) {
  upsertAnnouncement(input: $input)
}
    `;
export type UpsertAnnouncementMutationFn = ApolloReactCommon.MutationFunction<UpsertAnnouncementMutation, UpsertAnnouncementMutationVariables>;

/**
 * __useUpsertAnnouncementMutation__
 *
 * To run a mutation, you first call `useUpsertAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAnnouncementMutation, { data, loading, error }] = useUpsertAnnouncementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertAnnouncementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertAnnouncementMutation, UpsertAnnouncementMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertAnnouncementMutation, UpsertAnnouncementMutationVariables>(UpsertAnnouncementDocument, baseOptions);
      }
export type UpsertAnnouncementMutationHookResult = ReturnType<typeof useUpsertAnnouncementMutation>;
export type UpsertAnnouncementMutationResult = ApolloReactCommon.MutationResult<UpsertAnnouncementMutation>;
export type UpsertAnnouncementMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertAnnouncementMutation, UpsertAnnouncementMutationVariables>;
export const UpsertFeedDocument = gql`
    mutation upsertFeed($input: CreateOrUpdateFeedForm!) {
  upsertFeed(input: $input)
}
    `;
export type UpsertFeedMutationFn = ApolloReactCommon.MutationFunction<UpsertFeedMutation, UpsertFeedMutationVariables>;

/**
 * __useUpsertFeedMutation__
 *
 * To run a mutation, you first call `useUpsertFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFeedMutation, { data, loading, error }] = useUpsertFeedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertFeedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertFeedMutation, UpsertFeedMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertFeedMutation, UpsertFeedMutationVariables>(UpsertFeedDocument, baseOptions);
      }
export type UpsertFeedMutationHookResult = ReturnType<typeof useUpsertFeedMutation>;
export type UpsertFeedMutationResult = ApolloReactCommon.MutationResult<UpsertFeedMutation>;
export type UpsertFeedMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertFeedMutation, UpsertFeedMutationVariables>;
export const UpsertPublisherDocument = gql`
    mutation upsertPublisher($publisher: CreateOrUpdatePublisherForm!) {
  upsertPublisher(publisher: $publisher)
}
    `;
export type UpsertPublisherMutationFn = ApolloReactCommon.MutationFunction<UpsertPublisherMutation, UpsertPublisherMutationVariables>;

/**
 * __useUpsertPublisherMutation__
 *
 * To run a mutation, you first call `useUpsertPublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPublisherMutation, { data, loading, error }] = useUpsertPublisherMutation({
 *   variables: {
 *      publisher: // value for 'publisher'
 *   },
 * });
 */
export function useUpsertPublisherMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertPublisherMutation, UpsertPublisherMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertPublisherMutation, UpsertPublisherMutationVariables>(UpsertPublisherDocument, baseOptions);
      }
export type UpsertPublisherMutationHookResult = ReturnType<typeof useUpsertPublisherMutation>;
export type UpsertPublisherMutationResult = ApolloReactCommon.MutationResult<UpsertPublisherMutation>;
export type UpsertPublisherMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertPublisherMutation, UpsertPublisherMutationVariables>;
export const UpsertPublisherWithAgreementPublisherRulesDocument = gql`
    mutation upsertPublisherWithAgreementPublisherRules($publisher: CreateOrUpdatePublisherForm!, $agreementPublisherRules: [CreateOrUpdateAgreementPublisherRule!]!) {
  upsertPublisherWithAgreementPublisherRules(publisher: $publisher, agreementPublisherRules: $agreementPublisherRules)
}
    `;
export type UpsertPublisherWithAgreementPublisherRulesMutationFn = ApolloReactCommon.MutationFunction<UpsertPublisherWithAgreementPublisherRulesMutation, UpsertPublisherWithAgreementPublisherRulesMutationVariables>;

/**
 * __useUpsertPublisherWithAgreementPublisherRulesMutation__
 *
 * To run a mutation, you first call `useUpsertPublisherWithAgreementPublisherRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPublisherWithAgreementPublisherRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPublisherWithAgreementPublisherRulesMutation, { data, loading, error }] = useUpsertPublisherWithAgreementPublisherRulesMutation({
 *   variables: {
 *      publisher: // value for 'publisher'
 *      agreementPublisherRules: // value for 'agreementPublisherRules'
 *   },
 * });
 */
export function useUpsertPublisherWithAgreementPublisherRulesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertPublisherWithAgreementPublisherRulesMutation, UpsertPublisherWithAgreementPublisherRulesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertPublisherWithAgreementPublisherRulesMutation, UpsertPublisherWithAgreementPublisherRulesMutationVariables>(UpsertPublisherWithAgreementPublisherRulesDocument, baseOptions);
      }
export type UpsertPublisherWithAgreementPublisherRulesMutationHookResult = ReturnType<typeof useUpsertPublisherWithAgreementPublisherRulesMutation>;
export type UpsertPublisherWithAgreementPublisherRulesMutationResult = ApolloReactCommon.MutationResult<UpsertPublisherWithAgreementPublisherRulesMutation>;
export type UpsertPublisherWithAgreementPublisherRulesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertPublisherWithAgreementPublisherRulesMutation, UpsertPublisherWithAgreementPublisherRulesMutationVariables>;
export const AgreementDocument = gql`
    query agreement($id: Int!, $withAgreementBankAccount: Boolean! = false, $withInhouseNote: Boolean! = false, $withInhouseAgreementField: Boolean! = false) {
  agreement(id: $id) {
    id
    isTerminated
    name
    mediumId
    organizationId
    code
    inhouseAgreementField @include(if: $withInhouseAgreementField) {
      feePaidBy
      paymentTiming
      minimumRevenueType
      fixedRevenue
      minimumRevenue
    }
    notes @include(if: $withInhouseNote) {
      notes
    }
    terminatedAt
    createdAt
    updatedAt
    organization {
      id
      name
      createdAt
      updatedAt
    }
    agreementBankAccounts @include(if: $withAgreementBankAccount) {
      id
      agreementId
      bankAccountId
      bankAccount {
        id
        enabled
        typeName
        number
        holder
        createdAt
        updatedAt
        bank {
          id
          code
          name
          kana
          hira
          roma
          createdAt
          updatedAt
        }
        bankBranch {
          id
          code
          name
          kana
          hira
          roma
          createdAt
          updatedAt
        }
      }
      usedFromMonth
      createdAt
      updatedAt
    }
    inUseAgreementBankAccount @include(if: $withAgreementBankAccount) {
      id
      agreementId
      bankAccountId
      bankAccount {
        id
        enabled
        typeName
        number
        holder
        createdAt
        updatedAt
        bank {
          id
          code
          name
          kana
          hira
          roma
          createdAt
          updatedAt
        }
        bankBranch {
          id
          code
          name
          kana
          hira
          roma
          createdAt
          updatedAt
        }
      }
      usedFromMonth
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useAgreementQuery__
 *
 * To run a query within a React component, call `useAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgreementQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withAgreementBankAccount: // value for 'withAgreementBankAccount'
 *      withInhouseNote: // value for 'withInhouseNote'
 *      withInhouseAgreementField: // value for 'withInhouseAgreementField'
 *   },
 * });
 */
export function useAgreementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AgreementQuery, AgreementQueryVariables>) {
        return ApolloReactHooks.useQuery<AgreementQuery, AgreementQueryVariables>(AgreementDocument, baseOptions);
      }
export function useAgreementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AgreementQuery, AgreementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AgreementQuery, AgreementQueryVariables>(AgreementDocument, baseOptions);
        }
export type AgreementQueryHookResult = ReturnType<typeof useAgreementQuery>;
export type AgreementLazyQueryHookResult = ReturnType<typeof useAgreementLazyQuery>;
export type AgreementQueryResult = ApolloReactCommon.QueryResult<AgreementQuery, AgreementQueryVariables>;
export const AgreementsDocument = gql`
    query agreements($limit: Int!, $offset: Int!, $orderBy: AgreementOrder, $param: AgreementConditionParam, $q: String, $withAgreementBankAccount: Boolean! = false, $withInhouseNote: Boolean! = false, $withInhouseAgreementField: Boolean! = false) {
  agreements(param: $param, q: $q) {
    total
    nodes(limit: $limit, offset: $offset, orderBy: $orderBy) {
      id
      isTerminated
      name
      mediumId
      organizationId
      code
      inhouseAgreementField @include(if: $withInhouseAgreementField) {
        feePaidBy
        paymentTiming
        minimumRevenueType
        fixedRevenue
        minimumRevenue
      }
      notes @include(if: $withInhouseNote) {
        notes
      }
      terminatedAt
      createdAt
      updatedAt
      organization {
        id
        name
        createdAt
        updatedAt
      }
      agreementBankAccounts @include(if: $withAgreementBankAccount) {
        id
        agreementId
        bankAccountId
        bankAccount {
          id
          enabled
          typeName
          number
          holder
          createdAt
          updatedAt
          bank {
            id
            code
            name
            kana
            hira
            roma
            createdAt
            updatedAt
          }
          bankBranch {
            id
            code
            name
            kana
            hira
            roma
            createdAt
            updatedAt
          }
        }
        usedFromMonth
        createdAt
        updatedAt
      }
      inUseAgreementBankAccount @include(if: $withAgreementBankAccount) {
        id
        agreementId
        bankAccountId
        bankAccount {
          id
          enabled
          typeName
          number
          holder
          createdAt
          updatedAt
          bank {
            id
            code
            name
            kana
            hira
            roma
            createdAt
            updatedAt
          }
          bankBranch {
            id
            code
            name
            kana
            hira
            roma
            createdAt
            updatedAt
          }
        }
        usedFromMonth
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useAgreementsQuery__
 *
 * To run a query within a React component, call `useAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgreementsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      param: // value for 'param'
 *      q: // value for 'q'
 *      withAgreementBankAccount: // value for 'withAgreementBankAccount'
 *      withInhouseNote: // value for 'withInhouseNote'
 *      withInhouseAgreementField: // value for 'withInhouseAgreementField'
 *   },
 * });
 */
export function useAgreementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AgreementsQuery, AgreementsQueryVariables>) {
        return ApolloReactHooks.useQuery<AgreementsQuery, AgreementsQueryVariables>(AgreementsDocument, baseOptions);
      }
export function useAgreementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AgreementsQuery, AgreementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AgreementsQuery, AgreementsQueryVariables>(AgreementsDocument, baseOptions);
        }
export type AgreementsQueryHookResult = ReturnType<typeof useAgreementsQuery>;
export type AgreementsLazyQueryHookResult = ReturnType<typeof useAgreementsLazyQuery>;
export type AgreementsQueryResult = ApolloReactCommon.QueryResult<AgreementsQuery, AgreementsQueryVariables>;
export const AnnouncementDocument = gql`
    query announcement($id: Int!) {
  announcement(id: $id) {
    id
    enabled
    level
    title
    body
    startAt
    endAt
    closeButtonText
    isRead
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useAnnouncementQuery__
 *
 * To run a query within a React component, call `useAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnnouncementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>) {
        return ApolloReactHooks.useQuery<AnnouncementQuery, AnnouncementQueryVariables>(AnnouncementDocument, baseOptions);
      }
export function useAnnouncementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AnnouncementQuery, AnnouncementQueryVariables>(AnnouncementDocument, baseOptions);
        }
export type AnnouncementQueryHookResult = ReturnType<typeof useAnnouncementQuery>;
export type AnnouncementLazyQueryHookResult = ReturnType<typeof useAnnouncementLazyQuery>;
export type AnnouncementQueryResult = ApolloReactCommon.QueryResult<AnnouncementQuery, AnnouncementQueryVariables>;
export const AnnouncementsDocument = gql`
    query announcements($limit: Int!, $offset: Int!, $orderBy: AnnouncementOrder, $param: AnnouncementConditionParam) {
  announcements(param: $param) {
    total
    nodes(limit: $limit, offset: $offset, orderBy: $orderBy) {
      id
      enabled
      level
      title
      body
      startAt
      endAt
      closeButtonText
      isRead
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useAnnouncementsQuery__
 *
 * To run a query within a React component, call `useAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      param: // value for 'param'
 *   },
 * });
 */
export function useAnnouncementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AnnouncementsQuery, AnnouncementsQueryVariables>) {
        return ApolloReactHooks.useQuery<AnnouncementsQuery, AnnouncementsQueryVariables>(AnnouncementsDocument, baseOptions);
      }
export function useAnnouncementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnnouncementsQuery, AnnouncementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AnnouncementsQuery, AnnouncementsQueryVariables>(AnnouncementsDocument, baseOptions);
        }
export type AnnouncementsQueryHookResult = ReturnType<typeof useAnnouncementsQuery>;
export type AnnouncementsLazyQueryHookResult = ReturnType<typeof useAnnouncementsLazyQuery>;
export type AnnouncementsQueryResult = ApolloReactCommon.QueryResult<AnnouncementsQuery, AnnouncementsQueryVariables>;
export const ArticleStatsGroupByArticleIdDocument = gql`
    query articleStatsGroupByArticleID($limit: Int, $offset: Int, $groupByArticleIdParam: ArticleStatsGroupByArticleIDConditionParam) {
  articleStats(groupByArticleIdParam: $groupByArticleIdParam) {
    total
    nodes(limit: $limit, offset: $offset) {
      ... on ArticleStatsGroupByArticleID {
        organizationId
        publisherId
        articleId
        title
        url
        impression
        click
        ctr
        articleClickDemographics {
          genderId
          ageId
          click
        }
        createdAt
        publishedAt
        publisher {
          id
          enabled
          name
        }
      }
    }
  }
}
    `;

/**
 * __useArticleStatsGroupByArticleIdQuery__
 *
 * To run a query within a React component, call `useArticleStatsGroupByArticleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleStatsGroupByArticleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleStatsGroupByArticleIdQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      groupByArticleIdParam: // value for 'groupByArticleIdParam'
 *   },
 * });
 */
export function useArticleStatsGroupByArticleIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArticleStatsGroupByArticleIdQuery, ArticleStatsGroupByArticleIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ArticleStatsGroupByArticleIdQuery, ArticleStatsGroupByArticleIdQueryVariables>(ArticleStatsGroupByArticleIdDocument, baseOptions);
      }
export function useArticleStatsGroupByArticleIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleStatsGroupByArticleIdQuery, ArticleStatsGroupByArticleIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArticleStatsGroupByArticleIdQuery, ArticleStatsGroupByArticleIdQueryVariables>(ArticleStatsGroupByArticleIdDocument, baseOptions);
        }
export type ArticleStatsGroupByArticleIdQueryHookResult = ReturnType<typeof useArticleStatsGroupByArticleIdQuery>;
export type ArticleStatsGroupByArticleIdLazyQueryHookResult = ReturnType<typeof useArticleStatsGroupByArticleIdLazyQuery>;
export type ArticleStatsGroupByArticleIdQueryResult = ApolloReactCommon.QueryResult<ArticleStatsGroupByArticleIdQuery, ArticleStatsGroupByArticleIdQueryVariables>;
export const ArticleStatsGroupByDateDocument = gql`
    query articleStatsGroupByDate($limit: Int, $offset: Int, $groupByDateParam: ArticleStatsGroupByDateConditionParam) {
  articleStats(groupByDateParam: $groupByDateParam) {
    total
    nodes(limit: $limit, offset: $offset) {
      ... on ArticleStatsGroupByDate {
        date
        organizationId
        publisherId
        mediumId
        articleId
        title
        url
        impression
        click
        ctr
        articleClickDemographics {
          genderId
          ageId
          click
        }
      }
    }
  }
}
    `;

/**
 * __useArticleStatsGroupByDateQuery__
 *
 * To run a query within a React component, call `useArticleStatsGroupByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleStatsGroupByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleStatsGroupByDateQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      groupByDateParam: // value for 'groupByDateParam'
 *   },
 * });
 */
export function useArticleStatsGroupByDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArticleStatsGroupByDateQuery, ArticleStatsGroupByDateQueryVariables>) {
        return ApolloReactHooks.useQuery<ArticleStatsGroupByDateQuery, ArticleStatsGroupByDateQueryVariables>(ArticleStatsGroupByDateDocument, baseOptions);
      }
export function useArticleStatsGroupByDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleStatsGroupByDateQuery, ArticleStatsGroupByDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArticleStatsGroupByDateQuery, ArticleStatsGroupByDateQueryVariables>(ArticleStatsGroupByDateDocument, baseOptions);
        }
export type ArticleStatsGroupByDateQueryHookResult = ReturnType<typeof useArticleStatsGroupByDateQuery>;
export type ArticleStatsGroupByDateLazyQueryHookResult = ReturnType<typeof useArticleStatsGroupByDateLazyQuery>;
export type ArticleStatsGroupByDateQueryResult = ApolloReactCommon.QueryResult<ArticleStatsGroupByDateQuery, ArticleStatsGroupByDateQueryVariables>;
export const AvailableMediaDocument = gql`
    query availableMedia($param: AvailableMediaParam) {
  availableMedia(param: $param) {
    total
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useAvailableMediaQuery__
 *
 * To run a query within a React component, call `useAvailableMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableMediaQuery({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useAvailableMediaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableMediaQuery, AvailableMediaQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailableMediaQuery, AvailableMediaQueryVariables>(AvailableMediaDocument, baseOptions);
      }
export function useAvailableMediaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableMediaQuery, AvailableMediaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailableMediaQuery, AvailableMediaQueryVariables>(AvailableMediaDocument, baseOptions);
        }
export type AvailableMediaQueryHookResult = ReturnType<typeof useAvailableMediaQuery>;
export type AvailableMediaLazyQueryHookResult = ReturnType<typeof useAvailableMediaLazyQuery>;
export type AvailableMediaQueryResult = ApolloReactCommon.QueryResult<AvailableMediaQuery, AvailableMediaQueryVariables>;
export const BankAccountsDocument = gql`
    query bankAccounts($limit: Int!, $offset: Int!, $param: BankAccountConditionParam) {
  bankAccounts(param: $param) {
    total
    nodes(limit: $limit, offset: $offset) {
      id
      enabled
      typeName
      number
      holder
      createdAt
      updatedAt
      organization {
        id
        name
        createdAt
        updatedAt
      }
      bank {
        id
        code
        name
        kana
        hira
        roma
        createdAt
        updatedAt
      }
      bankBranch {
        id
        code
        name
        kana
        hira
        roma
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useBankAccountsQuery__
 *
 * To run a query within a React component, call `useBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankAccountsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      param: // value for 'param'
 *   },
 * });
 */
export function useBankAccountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BankAccountsQuery, BankAccountsQueryVariables>) {
        return ApolloReactHooks.useQuery<BankAccountsQuery, BankAccountsQueryVariables>(BankAccountsDocument, baseOptions);
      }
export function useBankAccountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BankAccountsQuery, BankAccountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BankAccountsQuery, BankAccountsQueryVariables>(BankAccountsDocument, baseOptions);
        }
export type BankAccountsQueryHookResult = ReturnType<typeof useBankAccountsQuery>;
export type BankAccountsLazyQueryHookResult = ReturnType<typeof useBankAccountsLazyQuery>;
export type BankAccountsQueryResult = ApolloReactCommon.QueryResult<BankAccountsQuery, BankAccountsQueryVariables>;
export const BankBranchesDocument = gql`
    query bankBranches($limit: Int!, $offset: Int!, $bankId: Int!, $q: String) {
  bankBranches(bankId: $bankId, q: $q) {
    total
    nodes(limit: $limit, offset: $offset) {
      id
      code
      name
      kana
      hira
      roma
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useBankBranchesQuery__
 *
 * To run a query within a React component, call `useBankBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankBranchesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      bankId: // value for 'bankId'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useBankBranchesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BankBranchesQuery, BankBranchesQueryVariables>) {
        return ApolloReactHooks.useQuery<BankBranchesQuery, BankBranchesQueryVariables>(BankBranchesDocument, baseOptions);
      }
export function useBankBranchesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BankBranchesQuery, BankBranchesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BankBranchesQuery, BankBranchesQueryVariables>(BankBranchesDocument, baseOptions);
        }
export type BankBranchesQueryHookResult = ReturnType<typeof useBankBranchesQuery>;
export type BankBranchesLazyQueryHookResult = ReturnType<typeof useBankBranchesLazyQuery>;
export type BankBranchesQueryResult = ApolloReactCommon.QueryResult<BankBranchesQuery, BankBranchesQueryVariables>;
export const BanksDocument = gql`
    query banks($limit: Int!, $offset: Int!, $q: String) {
  banks(q: $q) {
    total
    nodes(limit: $limit, offset: $offset) {
      id
      code
      name
      kana
      hira
      roma
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useBanksQuery__
 *
 * To run a query within a React component, call `useBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBanksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useBanksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BanksQuery, BanksQueryVariables>) {
        return ApolloReactHooks.useQuery<BanksQuery, BanksQueryVariables>(BanksDocument, baseOptions);
      }
export function useBanksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BanksQuery, BanksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BanksQuery, BanksQueryVariables>(BanksDocument, baseOptions);
        }
export type BanksQueryHookResult = ReturnType<typeof useBanksQuery>;
export type BanksLazyQueryHookResult = ReturnType<typeof useBanksLazyQuery>;
export type BanksQueryResult = ApolloReactCommon.QueryResult<BanksQuery, BanksQueryVariables>;
export const FeedDocument = gql`
    query feed($id: Int!, $withInhouseNote: Boolean! = false) {
  feed(id: $id) {
    id
    enabled
    name
    shortName
    siteUrl
    description
    mediumId
    legacyFeedId
    url
    logoUrl
    wideLogoUrl
    bannerUrl
    isDeleteFeed
    isSafeForFirstview
    relatedLinkNum
    articleExpirationDays
    availabilityForPromo
    type
    allowVideo
    allowContentBody
    ttlMinutes
    manuallyFeedScore
    notes @include(if: $withInhouseNote) {
      notes
    }
    createdAt
    updatedAt
    publisher {
      id
      enabled
      organizationId
      name
      organization {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useFeedQuery__
 *
 * To run a query within a React component, call `useFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withInhouseNote: // value for 'withInhouseNote'
 *   },
 * });
 */
export function useFeedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FeedQuery, FeedQueryVariables>) {
        return ApolloReactHooks.useQuery<FeedQuery, FeedQueryVariables>(FeedDocument, baseOptions);
      }
export function useFeedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FeedQuery, FeedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FeedQuery, FeedQueryVariables>(FeedDocument, baseOptions);
        }
export type FeedQueryHookResult = ReturnType<typeof useFeedQuery>;
export type FeedLazyQueryHookResult = ReturnType<typeof useFeedLazyQuery>;
export type FeedQueryResult = ApolloReactCommon.QueryResult<FeedQuery, FeedQueryVariables>;
export const FeedsDocument = gql`
    query feeds($limit: Int!, $offset: Int!, $orderBy: FeedOrder, $param: FeedConditionParam, $q: String, $withInhouseNote: Boolean! = false) {
  feeds(param: $param, q: $q) {
    total
    nodes(limit: $limit, offset: $offset, orderBy: $orderBy) {
      id
      enabled
      name
      shortName
      siteUrl
      description
      mediumId
      legacyFeedId
      url
      logoUrl
      wideLogoUrl
      bannerUrl
      isDeleteFeed
      isSafeForFirstview
      relatedLinkNum
      articleExpirationDays
      availabilityForPromo
      type
      allowVideo
      allowContentBody
      ttlMinutes
      manuallyFeedScore
      notes @include(if: $withInhouseNote) {
        notes
      }
      createdAt
      updatedAt
      publisher {
        id
        enabled
        organizationId
        name
        organization {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
}
    `;

/**
 * __useFeedsQuery__
 *
 * To run a query within a React component, call `useFeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      param: // value for 'param'
 *      q: // value for 'q'
 *      withInhouseNote: // value for 'withInhouseNote'
 *   },
 * });
 */
export function useFeedsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FeedsQuery, FeedsQueryVariables>) {
        return ApolloReactHooks.useQuery<FeedsQuery, FeedsQueryVariables>(FeedsDocument, baseOptions);
      }
export function useFeedsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FeedsQuery, FeedsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FeedsQuery, FeedsQueryVariables>(FeedsDocument, baseOptions);
        }
export type FeedsQueryHookResult = ReturnType<typeof useFeedsQuery>;
export type FeedsLazyQueryHookResult = ReturnType<typeof useFeedsLazyQuery>;
export type FeedsQueryResult = ApolloReactCommon.QueryResult<FeedsQuery, FeedsQueryVariables>;
export const ImageUploadUrlDocument = gql`
    query imageUploadUrl($mimeType: String!) {
  imageUploadUrl(mimeType: $mimeType) {
    uploadUrl
    imageUrl
  }
}
    `;

/**
 * __useImageUploadUrlQuery__
 *
 * To run a query within a React component, call `useImageUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageUploadUrlQuery({
 *   variables: {
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useImageUploadUrlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ImageUploadUrlQuery, ImageUploadUrlQueryVariables>) {
        return ApolloReactHooks.useQuery<ImageUploadUrlQuery, ImageUploadUrlQueryVariables>(ImageUploadUrlDocument, baseOptions);
      }
export function useImageUploadUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ImageUploadUrlQuery, ImageUploadUrlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ImageUploadUrlQuery, ImageUploadUrlQueryVariables>(ImageUploadUrlDocument, baseOptions);
        }
export type ImageUploadUrlQueryHookResult = ReturnType<typeof useImageUploadUrlQuery>;
export type ImageUploadUrlLazyQueryHookResult = ReturnType<typeof useImageUploadUrlLazyQuery>;
export type ImageUploadUrlQueryResult = ApolloReactCommon.QueryResult<ImageUploadUrlQuery, ImageUploadUrlQueryVariables>;
export const MediumArticlesDocument = gql`
    query mediumArticles($limit: Int!, $offset: Int!, $param: MediumArticleConditionParam, $q: String) {
  mediumArticles(param: $param, q: $q) {
    total
    nodes(limit: $limit, offset: $offset) {
      articleId
      mediumId
      mediumArticleId
      enabled
      title
      url
      feedId
      publishedAt
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useMediumArticlesQuery__
 *
 * To run a query within a React component, call `useMediumArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediumArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediumArticlesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      param: // value for 'param'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useMediumArticlesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MediumArticlesQuery, MediumArticlesQueryVariables>) {
        return ApolloReactHooks.useQuery<MediumArticlesQuery, MediumArticlesQueryVariables>(MediumArticlesDocument, baseOptions);
      }
export function useMediumArticlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MediumArticlesQuery, MediumArticlesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MediumArticlesQuery, MediumArticlesQueryVariables>(MediumArticlesDocument, baseOptions);
        }
export type MediumArticlesQueryHookResult = ReturnType<typeof useMediumArticlesQuery>;
export type MediumArticlesLazyQueryHookResult = ReturnType<typeof useMediumArticlesLazyQuery>;
export type MediumArticlesQueryResult = ApolloReactCommon.QueryResult<MediumArticlesQuery, MediumArticlesQueryVariables>;
export const MonthlyDividendManualOperationsDocument = gql`
    query monthlyDividendManualOperations($limit: Int!, $offset: Int!) {
  monthlyDividendManualOperations {
    total
    nodes(limit: $limit, offset: $offset) {
      id
      dividendMonth
      dividendExtraRevenue
      hasBeenFinalized
      finalizedAt
      createdAt
      updatedAt
      dividends {
        id
        mediumId
        userUid
        dividendMethod
        extraRevenue
        dividendExtraRevenue
        extraRevenueMonth
        notes
        jobStatus
        completedAt
        createdAt
        updatedAt
        user {
          uid
          email
        }
      }
    }
  }
}
    `;

/**
 * __useMonthlyDividendManualOperationsQuery__
 *
 * To run a query within a React component, call `useMonthlyDividendManualOperationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyDividendManualOperationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyDividendManualOperationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useMonthlyDividendManualOperationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MonthlyDividendManualOperationsQuery, MonthlyDividendManualOperationsQueryVariables>) {
        return ApolloReactHooks.useQuery<MonthlyDividendManualOperationsQuery, MonthlyDividendManualOperationsQueryVariables>(MonthlyDividendManualOperationsDocument, baseOptions);
      }
export function useMonthlyDividendManualOperationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MonthlyDividendManualOperationsQuery, MonthlyDividendManualOperationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MonthlyDividendManualOperationsQuery, MonthlyDividendManualOperationsQueryVariables>(MonthlyDividendManualOperationsDocument, baseOptions);
        }
export type MonthlyDividendManualOperationsQueryHookResult = ReturnType<typeof useMonthlyDividendManualOperationsQuery>;
export type MonthlyDividendManualOperationsLazyQueryHookResult = ReturnType<typeof useMonthlyDividendManualOperationsLazyQuery>;
export type MonthlyDividendManualOperationsQueryResult = ApolloReactCommon.QueryResult<MonthlyDividendManualOperationsQuery, MonthlyDividendManualOperationsQueryVariables>;
export const MonthlyPaymentsDocument = gql`
    query monthlyPayments($limit: Int!, $offset: Int!, $param: MonthlyPaymentsConditionParam, $withAgreementBankAccount: Boolean! = false, $withInhouseNote: Boolean! = false, $withInhouseAgreementField: Boolean! = false) {
  monthlyPayments(param: $param) {
    total
    sum
    sumWithoutTax
    taxSum
    sumRevenue
    sumRevenueTax
    sumRevenueWithoutTax
    nodes(limit: $limit, offset: $offset) {
      id
      monthlyAgreementSnapshotId
      agreementId
      mediumId
      sspRevenue
      videoSSPRevenue
      liveSSPRevenue
      gunosyAdsRevenue
      revenue
      revenueIncludingManualAdjustmentRevenue
      revenueIncludingManualAdjustmentRevenueTax
      revenueIncludingManualAdjustmentRevenueIncludingTax
      manualAdjustmentRevenue
      carriedOverRevenue
      cumulativeCarriedOverRevenueIncludingTax
      cumulativeRevenue
      payment
      taxRate
      paymentTax
      paymentIncludingTax
      hasBeenFinalized
      revenueGeneratedAtMonth
      revenueGeneratedByDate
      finalizedAtMonth
      paidAtMonth
      createdAt
      updatedAt
      monthlyAgreementSnapshot {
        id
        isTerminated
        name
        mediumId
        organizationId
        code
        partnerName
        qualifiedInvoiceIssuer
        invoiceRegistrationNumber
        agreementId
        inhouseAgreementField @include(if: $withInhouseAgreementField) {
          feePaidBy
          paymentTiming
          minimumRevenueType
          fixedRevenue
          minimumRevenue
        }
        month
        notes @include(if: $withInhouseNote) {
          notes
        }
        terminatedAt
        createdAt
        updatedAt
        organization {
          id
          name
          createdAt
          updatedAt
        }
        targetAgreementBankAccount @include(if: $withAgreementBankAccount) {
          id
          agreementId
          bankAccountId
          bankAccount {
            id
            enabled
            typeName
            number
            holder
            createdAt
            updatedAt
            bank {
              id
              code
              name
              kana
              hira
              roma
              createdAt
              updatedAt
            }
            bankBranch {
              id
              code
              name
              kana
              hira
              roma
              createdAt
              updatedAt
            }
          }
          usedFromMonth
          createdAt
          updatedAt
        }
      }
    }
  }
}
    `;

/**
 * __useMonthlyPaymentsQuery__
 *
 * To run a query within a React component, call `useMonthlyPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyPaymentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      param: // value for 'param'
 *      withAgreementBankAccount: // value for 'withAgreementBankAccount'
 *      withInhouseNote: // value for 'withInhouseNote'
 *      withInhouseAgreementField: // value for 'withInhouseAgreementField'
 *   },
 * });
 */
export function useMonthlyPaymentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MonthlyPaymentsQuery, MonthlyPaymentsQueryVariables>) {
        return ApolloReactHooks.useQuery<MonthlyPaymentsQuery, MonthlyPaymentsQueryVariables>(MonthlyPaymentsDocument, baseOptions);
      }
export function useMonthlyPaymentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MonthlyPaymentsQuery, MonthlyPaymentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MonthlyPaymentsQuery, MonthlyPaymentsQueryVariables>(MonthlyPaymentsDocument, baseOptions);
        }
export type MonthlyPaymentsQueryHookResult = ReturnType<typeof useMonthlyPaymentsQuery>;
export type MonthlyPaymentsLazyQueryHookResult = ReturnType<typeof useMonthlyPaymentsLazyQuery>;
export type MonthlyPaymentsQueryResult = ApolloReactCommon.QueryResult<MonthlyPaymentsQuery, MonthlyPaymentsQueryVariables>;
export const MonthlyPublisherPaymentsDocument = gql`
    query monthlyPublisherPayments($limit: Int!, $offset: Int!, $param: MonthlyPublisherPaymentConditionParam, $withMonthlyPayment: Boolean! = false, $withAgreementBankAccountPublisherRuleSnapshot: Boolean! = false) {
  monthlyPublisherPayments(param: $param) {
    total
    nodes(limit: $limit, offset: $offset) {
      id
      monthlyPaymentId
      publisherId
      sspRevenue
      videoSSPRevenue
      liveSSPRevenue
      gunosyAdsRevenue
      createdAt
      updatedAt
      publisher {
        id
        enabled
        organizationId
        name
      }
      monthlyPayment @include(if: $withMonthlyPayment) {
        id
        monthlyAgreementSnapshotId
        agreementId
        mediumId
        hasBeenFinalized
        monthlyAgreementSnapshot {
          id
          isTerminated
          name
          agreementId
          month
        }
      }
      monthlyAgreementPublisherRuleSnapshot @include(if: $withAgreementBankAccountPublisherRuleSnapshot) {
        id
        monthlyAgreementSnapshotId
        publisherId
        mediumId
        fixedRevenue
        minimumRevenue
        paymentRate {
          paymentRate
          sspPaymentRate
          videoSspPaymentRate
          liveSspPaymentRate
          isTargetForSspAllocation
          isTargetForVideoSspAllocation
          isTargetForLiveSspAllocation
        }
      }
    }
  }
}
    `;

/**
 * __useMonthlyPublisherPaymentsQuery__
 *
 * To run a query within a React component, call `useMonthlyPublisherPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyPublisherPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyPublisherPaymentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      param: // value for 'param'
 *      withMonthlyPayment: // value for 'withMonthlyPayment'
 *      withAgreementBankAccountPublisherRuleSnapshot: // value for 'withAgreementBankAccountPublisherRuleSnapshot'
 *   },
 * });
 */
export function useMonthlyPublisherPaymentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MonthlyPublisherPaymentsQuery, MonthlyPublisherPaymentsQueryVariables>) {
        return ApolloReactHooks.useQuery<MonthlyPublisherPaymentsQuery, MonthlyPublisherPaymentsQueryVariables>(MonthlyPublisherPaymentsDocument, baseOptions);
      }
export function useMonthlyPublisherPaymentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MonthlyPublisherPaymentsQuery, MonthlyPublisherPaymentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MonthlyPublisherPaymentsQuery, MonthlyPublisherPaymentsQueryVariables>(MonthlyPublisherPaymentsDocument, baseOptions);
        }
export type MonthlyPublisherPaymentsQueryHookResult = ReturnType<typeof useMonthlyPublisherPaymentsQuery>;
export type MonthlyPublisherPaymentsLazyQueryHookResult = ReturnType<typeof useMonthlyPublisherPaymentsLazyQuery>;
export type MonthlyPublisherPaymentsQueryResult = ApolloReactCommon.QueryResult<MonthlyPublisherPaymentsQuery, MonthlyPublisherPaymentsQueryVariables>;
export const PublisherDocument = gql`
    query publisher($id: Int!, $withAgreementPublisherRule: Boolean! = false, $withInhouseNote: Boolean! = false, $withOrganization: Boolean! = false) {
  publisher(id: $id) {
    id
    enabled
    organizationId
    name
    notes @include(if: $withInhouseNote) {
      notes
    }
    createdAt
    updatedAt
    organization @include(if: $withOrganization) {
      id
      name
      createdAt
      updatedAt
    }
    agreementPublisherRules @include(if: $withAgreementPublisherRule) {
      id
      mediumId
      fixedRevenue
      minimumRevenue
      paymentRate {
        paymentRate
        sspPaymentRate
        videoSspPaymentRate
        liveSspPaymentRate
        isTargetForSspAllocation
        isTargetForVideoSspAllocation
        isTargetForLiveSspAllocation
      }
      createdAt
      updatedAt
      agreement {
        id
        isTerminated
        terminatedAt
        name
        inhouseAgreementField {
          feePaidBy
          paymentTiming
          minimumRevenueType
          fixedRevenue
          minimumRevenue
        }
      }
    }
  }
}
    `;

/**
 * __usePublisherQuery__
 *
 * To run a query within a React component, call `usePublisherQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withAgreementPublisherRule: // value for 'withAgreementPublisherRule'
 *      withInhouseNote: // value for 'withInhouseNote'
 *      withOrganization: // value for 'withOrganization'
 *   },
 * });
 */
export function usePublisherQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublisherQuery, PublisherQueryVariables>) {
        return ApolloReactHooks.useQuery<PublisherQuery, PublisherQueryVariables>(PublisherDocument, baseOptions);
      }
export function usePublisherLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublisherQuery, PublisherQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PublisherQuery, PublisherQueryVariables>(PublisherDocument, baseOptions);
        }
export type PublisherQueryHookResult = ReturnType<typeof usePublisherQuery>;
export type PublisherLazyQueryHookResult = ReturnType<typeof usePublisherLazyQuery>;
export type PublisherQueryResult = ApolloReactCommon.QueryResult<PublisherQuery, PublisherQueryVariables>;
export const PublisherRankDocument = gql`
    query publisherRank($limit: Int!, $offset: Int!, $param: PublisherRankConditionParam) {
  publisherRank(param: $param) {
    total
    nodes(limit: $limit, offset: $offset) {
      publisherId
      mediumId
      rank
      articleImpression
      articleClick
      videoImpression
      videoClick
      videoWatch_3s
      videoWatch_97p
      liveWatch
      gunosyAdsRevenue
      sspRevenue
      videoSspRevenue
      liveSspRevenue
      publisherGunosyAdsRevenue
      publisherSspRevenue
      publisherVideoSspRevenue
      publisherLiveSspRevenue
      publisher {
        id
        enabled
        organizationId
        name
        notes {
          notes
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __usePublisherRankQuery__
 *
 * To run a query within a React component, call `usePublisherRankQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherRankQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherRankQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      param: // value for 'param'
 *   },
 * });
 */
export function usePublisherRankQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublisherRankQuery, PublisherRankQueryVariables>) {
        return ApolloReactHooks.useQuery<PublisherRankQuery, PublisherRankQueryVariables>(PublisherRankDocument, baseOptions);
      }
export function usePublisherRankLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublisherRankQuery, PublisherRankQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PublisherRankQuery, PublisherRankQueryVariables>(PublisherRankDocument, baseOptions);
        }
export type PublisherRankQueryHookResult = ReturnType<typeof usePublisherRankQuery>;
export type PublisherRankLazyQueryHookResult = ReturnType<typeof usePublisherRankLazyQuery>;
export type PublisherRankQueryResult = ApolloReactCommon.QueryResult<PublisherRankQuery, PublisherRankQueryVariables>;
export const PublishersDocument = gql`
    query publishers($limit: Int!, $offset: Int!, $orderBy: PublisherOrder, $param: PublisherConditionParam, $q: String, $withAgreementPublisherRule: Boolean! = false, $withInhouseNote: Boolean! = false, $withOrganization: Boolean! = false) {
  publishers(param: $param, q: $q) {
    total
    nodes(limit: $limit, offset: $offset, orderBy: $orderBy) {
      id
      enabled
      organizationId
      name
      notes @include(if: $withInhouseNote) {
        notes
      }
      createdAt
      updatedAt
      organization @include(if: $withOrganization) {
        id
        name
        createdAt
        updatedAt
      }
      agreementPublisherRules @include(if: $withAgreementPublisherRule) {
        id
        mediumId
        fixedRevenue
        minimumRevenue
        paymentRate {
          paymentRate
          sspPaymentRate
          videoSspPaymentRate
          liveSspPaymentRate
          isTargetForSspAllocation
          isTargetForVideoSspAllocation
          isTargetForLiveSspAllocation
        }
        createdAt
        updatedAt
        agreement {
          id
          isTerminated
          terminatedAt
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePublishersQuery__
 *
 * To run a query within a React component, call `usePublishersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      param: // value for 'param'
 *      q: // value for 'q'
 *      withAgreementPublisherRule: // value for 'withAgreementPublisherRule'
 *      withInhouseNote: // value for 'withInhouseNote'
 *      withOrganization: // value for 'withOrganization'
 *   },
 * });
 */
export function usePublishersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublishersQuery, PublishersQueryVariables>) {
        return ApolloReactHooks.useQuery<PublishersQuery, PublishersQueryVariables>(PublishersDocument, baseOptions);
      }
export function usePublishersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublishersQuery, PublishersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PublishersQuery, PublishersQueryVariables>(PublishersDocument, baseOptions);
        }
export type PublishersQueryHookResult = ReturnType<typeof usePublishersQuery>;
export type PublishersLazyQueryHookResult = ReturnType<typeof usePublishersLazyQuery>;
export type PublishersQueryResult = ApolloReactCommon.QueryResult<PublishersQuery, PublishersQueryVariables>;
export const RssDocument = gql`
    query rss($rssUrl: String!) {
  rss(rssUrl: $rssUrl) {
    name
    description
    siteUrl
    logoUrl
    wideLogoUrl
  }
}
    `;

/**
 * __useRssQuery__
 *
 * To run a query within a React component, call `useRssQuery` and pass it any options that fit your needs.
 * When your component renders, `useRssQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRssQuery({
 *   variables: {
 *      rssUrl: // value for 'rssUrl'
 *   },
 * });
 */
export function useRssQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RssQuery, RssQueryVariables>) {
        return ApolloReactHooks.useQuery<RssQuery, RssQueryVariables>(RssDocument, baseOptions);
      }
export function useRssLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RssQuery, RssQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RssQuery, RssQueryVariables>(RssDocument, baseOptions);
        }
export type RssQueryHookResult = ReturnType<typeof useRssQuery>;
export type RssLazyQueryHookResult = ReturnType<typeof useRssLazyQuery>;
export type RssQueryResult = ApolloReactCommon.QueryResult<RssQuery, RssQueryVariables>;
export const StagingFeedsDocument = gql`
    query stagingFeeds($limit: Int!, $offset: Int!, $orderBy: FeedOrder, $param: FeedConditionParam, $q: String) {
  stagingFeeds(param: $param, q: $q) {
    total
    nodes(limit: $limit, offset: $offset, orderBy: $orderBy) {
      id
      enabled
      name
      shortName
      siteUrl
      description
      mediumId
      legacyFeedId
      url
      logoUrl
      wideLogoUrl
      bannerUrl
      isDeleteFeed
      isSafeForFirstview
      relatedLinkNum
      articleExpirationDays
      availabilityForPromo
      type
      allowVideo
      allowContentBody
      ttlMinutes
      manuallyFeedScore
      notes {
        notes
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useStagingFeedsQuery__
 *
 * To run a query within a React component, call `useStagingFeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStagingFeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStagingFeedsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      param: // value for 'param'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useStagingFeedsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StagingFeedsQuery, StagingFeedsQueryVariables>) {
        return ApolloReactHooks.useQuery<StagingFeedsQuery, StagingFeedsQueryVariables>(StagingFeedsDocument, baseOptions);
      }
export function useStagingFeedsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StagingFeedsQuery, StagingFeedsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StagingFeedsQuery, StagingFeedsQueryVariables>(StagingFeedsDocument, baseOptions);
        }
export type StagingFeedsQueryHookResult = ReturnType<typeof useStagingFeedsQuery>;
export type StagingFeedsLazyQueryHookResult = ReturnType<typeof useStagingFeedsLazyQuery>;
export type StagingFeedsQueryResult = ApolloReactCommon.QueryResult<StagingFeedsQuery, StagingFeedsQueryVariables>;
export const StagingPublishersDocument = gql`
    query stagingPublishers($limit: Int!, $offset: Int!, $orderBy: PublisherOrder, $param: PublisherConditionParam, $q: String) {
  stagingPublishers(param: $param, q: $q) {
    total
    nodes(limit: $limit, offset: $offset, orderBy: $orderBy) {
      id
      enabled
      name
      notes {
        notes
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useStagingPublishersQuery__
 *
 * To run a query within a React component, call `useStagingPublishersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStagingPublishersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStagingPublishersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      param: // value for 'param'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useStagingPublishersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StagingPublishersQuery, StagingPublishersQueryVariables>) {
        return ApolloReactHooks.useQuery<StagingPublishersQuery, StagingPublishersQueryVariables>(StagingPublishersDocument, baseOptions);
      }
export function useStagingPublishersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StagingPublishersQuery, StagingPublishersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StagingPublishersQuery, StagingPublishersQueryVariables>(StagingPublishersDocument, baseOptions);
        }
export type StagingPublishersQueryHookResult = ReturnType<typeof useStagingPublishersQuery>;
export type StagingPublishersLazyQueryHookResult = ReturnType<typeof useStagingPublishersLazyQuery>;
export type StagingPublishersQueryResult = ApolloReactCommon.QueryResult<StagingPublishersQuery, StagingPublishersQueryVariables>;
export const SummaryStatsDocument = gql`
    query summaryStats($param: ConditionParam, $withInhouseRevenueDetail: Boolean!) {
  summaryStats(param: $param) {
    date
    organizationId
    publisherId
    mediumId
    follower
    articleImpression
    articleClick
    videoImpression
    videoClick
    videoWatch3s
    videoWatch97p
    liveWatch
    inhouseRevenueDetail @include(if: $withInhouseRevenueDetail) {
      gunosyAdsRevenue
      sspRevenue
      videoSSPRevenue
      liveSSPRevenue
      publisherGunosyAdsRevenue
      publisherSSPRevenue
      publisherVideoSSPRevenue
      publisherLiveSSPRevenue
    }
    publisherTotalRevenue
    hasBeenFinalized
    articleClickDemographics {
      genderId
      ageId
      click
    }
  }
}
    `;

/**
 * __useSummaryStatsQuery__
 *
 * To run a query within a React component, call `useSummaryStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummaryStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummaryStatsQuery({
 *   variables: {
 *      param: // value for 'param'
 *      withInhouseRevenueDetail: // value for 'withInhouseRevenueDetail'
 *   },
 * });
 */
export function useSummaryStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SummaryStatsQuery, SummaryStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<SummaryStatsQuery, SummaryStatsQueryVariables>(SummaryStatsDocument, baseOptions);
      }
export function useSummaryStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SummaryStatsQuery, SummaryStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SummaryStatsQuery, SummaryStatsQueryVariables>(SummaryStatsDocument, baseOptions);
        }
export type SummaryStatsQueryHookResult = ReturnType<typeof useSummaryStatsQuery>;
export type SummaryStatsLazyQueryHookResult = ReturnType<typeof useSummaryStatsLazyQuery>;
export type SummaryStatsQueryResult = ApolloReactCommon.QueryResult<SummaryStatsQuery, SummaryStatsQueryVariables>;
export const VideoStatsGroupByDateDocument = gql`
    query videoStatsGroupByDate($limit: Int, $offset: Int, $groupByDateParam: VideoStatsGroupByDateConditionParam) {
  videoStats(groupByDateParam: $groupByDateParam) {
    total
    nodes(limit: $limit, offset: $offset) {
      ... on VideoStatsGroupByDate {
        date
        organizationId
        publisherId
        mediumId
        videoId
        title
        URL
        thumbnailURL
        impression
        click
        ctr
        watch3s
        play_time
        average_play_time
        watch97p
        average_watch97p
        duration
        watch3sDemographics {
          genderId
          ageId
          watch3s
        }
        watchTimeDistribution {
          watchTime
          count
        }
        listWatchTimeDistribution {
          watchTime
          count
        }
        detailWatchTimeDistribution {
          watchTime
          count
        }
      }
    }
  }
}
    `;

/**
 * __useVideoStatsGroupByDateQuery__
 *
 * To run a query within a React component, call `useVideoStatsGroupByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoStatsGroupByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoStatsGroupByDateQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      groupByDateParam: // value for 'groupByDateParam'
 *   },
 * });
 */
export function useVideoStatsGroupByDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VideoStatsGroupByDateQuery, VideoStatsGroupByDateQueryVariables>) {
        return ApolloReactHooks.useQuery<VideoStatsGroupByDateQuery, VideoStatsGroupByDateQueryVariables>(VideoStatsGroupByDateDocument, baseOptions);
      }
export function useVideoStatsGroupByDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VideoStatsGroupByDateQuery, VideoStatsGroupByDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VideoStatsGroupByDateQuery, VideoStatsGroupByDateQueryVariables>(VideoStatsGroupByDateDocument, baseOptions);
        }
export type VideoStatsGroupByDateQueryHookResult = ReturnType<typeof useVideoStatsGroupByDateQuery>;
export type VideoStatsGroupByDateLazyQueryHookResult = ReturnType<typeof useVideoStatsGroupByDateLazyQuery>;
export type VideoStatsGroupByDateQueryResult = ApolloReactCommon.QueryResult<VideoStatsGroupByDateQuery, VideoStatsGroupByDateQueryVariables>;
export const VideoStatsGroupByVideoIdDocument = gql`
    query videoStatsGroupByVideoId($limit: Int, $offset: Int, $groupByVideoIdParam: VideoStatsGroupByVideoIDConditionParam) {
  videoStats(groupByVideoIdParam: $groupByVideoIdParam) {
    total
    nodes(limit: $limit, offset: $offset) {
      ... on VideoStatsGroupByVideoID {
        organizationId
        publisherId
        videoId
        title
        URL
        thumbnailURL
        impression
        click
        ctr
        watch3s
        play_time
        average_play_time
        watch97p
        average_watch97p
        duration
        watch3sDemographics {
          genderId
          ageId
          watch3s
        }
        watchTimeDistribution {
          watchTime
          count
        }
        createdAt
        publishedAt
        publisher {
          id
          enabled
          name
        }
      }
    }
  }
}
    `;

/**
 * __useVideoStatsGroupByVideoIdQuery__
 *
 * To run a query within a React component, call `useVideoStatsGroupByVideoIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoStatsGroupByVideoIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoStatsGroupByVideoIdQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      groupByVideoIdParam: // value for 'groupByVideoIdParam'
 *   },
 * });
 */
export function useVideoStatsGroupByVideoIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VideoStatsGroupByVideoIdQuery, VideoStatsGroupByVideoIdQueryVariables>) {
        return ApolloReactHooks.useQuery<VideoStatsGroupByVideoIdQuery, VideoStatsGroupByVideoIdQueryVariables>(VideoStatsGroupByVideoIdDocument, baseOptions);
      }
export function useVideoStatsGroupByVideoIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VideoStatsGroupByVideoIdQuery, VideoStatsGroupByVideoIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VideoStatsGroupByVideoIdQuery, VideoStatsGroupByVideoIdQueryVariables>(VideoStatsGroupByVideoIdDocument, baseOptions);
        }
export type VideoStatsGroupByVideoIdQueryHookResult = ReturnType<typeof useVideoStatsGroupByVideoIdQuery>;
export type VideoStatsGroupByVideoIdLazyQueryHookResult = ReturnType<typeof useVideoStatsGroupByVideoIdLazyQuery>;
export type VideoStatsGroupByVideoIdQueryResult = ApolloReactCommon.QueryResult<VideoStatsGroupByVideoIdQuery, VideoStatsGroupByVideoIdQueryVariables>;
export const CreateOrganizationDocument = gql`
    mutation createOrganization($input: CreateOrUpdateOrganization!) {
  createOrganization(input: $input)
}
    `;
export type CreateOrganizationMutationFn = ApolloReactCommon.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, baseOptions);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = ApolloReactCommon.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($id: Int!) {
  deleteOrganization(id: $id)
}
    `;
export type DeleteOrganizationMutationFn = ApolloReactCommon.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, baseOptions);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = ApolloReactCommon.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($uid: ID!) {
  deleteUser(uid: $uid)
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const InviteUserDocument = gql`
    mutation inviteUser($input: InviteUser!) {
  inviteUser(input: $input)
}
    `;
export type InviteUserMutationFn = ApolloReactCommon.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, baseOptions);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = ApolloReactCommon.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const RegenerateInviteLinkDocument = gql`
    mutation regenerateInviteLink($uid: ID!) {
  regenerateInviteLink(uid: $uid)
}
    `;
export type RegenerateInviteLinkMutationFn = ApolloReactCommon.MutationFunction<RegenerateInviteLinkMutation, RegenerateInviteLinkMutationVariables>;

/**
 * __useRegenerateInviteLinkMutation__
 *
 * To run a mutation, you first call `useRegenerateInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateInviteLinkMutation, { data, loading, error }] = useRegenerateInviteLinkMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useRegenerateInviteLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegenerateInviteLinkMutation, RegenerateInviteLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<RegenerateInviteLinkMutation, RegenerateInviteLinkMutationVariables>(RegenerateInviteLinkDocument, baseOptions);
      }
export type RegenerateInviteLinkMutationHookResult = ReturnType<typeof useRegenerateInviteLinkMutation>;
export type RegenerateInviteLinkMutationResult = ApolloReactCommon.MutationResult<RegenerateInviteLinkMutation>;
export type RegenerateInviteLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<RegenerateInviteLinkMutation, RegenerateInviteLinkMutationVariables>;
export const UpdateEmailDocument = gql`
    mutation updateEmail($uid: String!, $email: String!) {
  updateEmail(uid: $uid, email: $email)
}
    `;
export type UpdateEmailMutationFn = ApolloReactCommon.MutationFunction<UpdateEmailMutation, UpdateEmailMutationVariables>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmailMutation, UpdateEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument, baseOptions);
      }
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = ApolloReactCommon.MutationResult<UpdateEmailMutation>;
export type UpdateEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmailMutation, UpdateEmailMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($id: Int!, $input: CreateOrUpdateOrganization!) {
  updateOrganization(id: $id, input: $input)
}
    `;
export type UpdateOrganizationMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, baseOptions);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const UpdateRolesDocument = gql`
    mutation updateRoles($uid: String!, $roles: [String!]!) {
  updateRoles(uid: $uid, roles: $roles)
}
    `;
export type UpdateRolesMutationFn = ApolloReactCommon.MutationFunction<UpdateRolesMutation, UpdateRolesMutationVariables>;

/**
 * __useUpdateRolesMutation__
 *
 * To run a mutation, you first call `useUpdateRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolesMutation, { data, loading, error }] = useUpdateRolesMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useUpdateRolesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRolesMutation, UpdateRolesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRolesMutation, UpdateRolesMutationVariables>(UpdateRolesDocument, baseOptions);
      }
export type UpdateRolesMutationHookResult = ReturnType<typeof useUpdateRolesMutation>;
export type UpdateRolesMutationResult = ApolloReactCommon.MutationResult<UpdateRolesMutation>;
export type UpdateRolesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRolesMutation, UpdateRolesMutationVariables>;
export const OrganizationDocument = gql`
    query organization($id: Int!) {
  organization(id: $id) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, baseOptions);
      }
export function useOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, baseOptions);
        }
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = ApolloReactCommon.QueryResult<OrganizationQuery, OrganizationQueryVariables>;
export const OrganizationsDocument = gql`
    query organizations($limit: Int!, $offset: Int!, $ids: [Int!], $q: String, $enabled: Boolean) {
  organizations(ids: $ids, q: $q, enabled: $enabled) {
    total
    nodes(limit: $limit, offset: $offset) {
      id
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      ids: // value for 'ids'
 *      q: // value for 'q'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, baseOptions);
      }
export function useOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, baseOptions);
        }
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsQueryResult = ApolloReactCommon.QueryResult<OrganizationsQuery, OrganizationsQueryVariables>;
export const PoliciesDocument = gql`
    query policies($role: String) {
  policies(role: $role) {
    actions
    resources {
      name
      isSameCond {
        key
        value
      }
    }
  }
}
    `;

/**
 * __usePoliciesQuery__
 *
 * To run a query within a React component, call `usePoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoliciesQuery({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function usePoliciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PoliciesQuery, PoliciesQueryVariables>) {
        return ApolloReactHooks.useQuery<PoliciesQuery, PoliciesQueryVariables>(PoliciesDocument, baseOptions);
      }
export function usePoliciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PoliciesQuery, PoliciesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PoliciesQuery, PoliciesQueryVariables>(PoliciesDocument, baseOptions);
        }
export type PoliciesQueryHookResult = ReturnType<typeof usePoliciesQuery>;
export type PoliciesLazyQueryHookResult = ReturnType<typeof usePoliciesLazyQuery>;
export type PoliciesQueryResult = ApolloReactCommon.QueryResult<PoliciesQuery, PoliciesQueryVariables>;
export const RolesDocument = gql`
    query roles {
  roles {
    name
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        return ApolloReactHooks.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
      }
export function useRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = ApolloReactCommon.QueryResult<RolesQuery, RolesQueryVariables>;
export const UserDocument = gql`
    query user($userID: String!) {
  user(userID: $userID) {
    uid
    email
    roles
    createdAt
    updatedAt
    organization {
      id
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query users($limit: Int!, $offset: Int!, $userIDs: [String!], $organizationId: Int, $q: String, $enabled: Boolean) {
  users(userIDs: $userIDs, organizationId: $organizationId, q: $q, enabled: $enabled) {
    total
    nodes(limit: $limit, offset: $offset) {
      uid
      email
      roles
      createdAt
      updatedAt
      organization {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      userIDs: // value for 'userIDs'
 *      organizationId: // value for 'organizationId'
 *      q: // value for 'q'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "ArticleStats",
        "possibleTypes": [
          {
            "name": "ArticleStatsGroupByArticleID"
          },
          {
            "name": "ArticleStatsGroupByDate"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "VideoStats",
        "possibleTypes": [
          {
            "name": "VideoStatsGroupByVideoID"
          },
          {
            "name": "VideoStatsGroupByDate"
          }
        ]
      }
    ]
  }
};
      export default result;
    