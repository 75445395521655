import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { UIInitialState, UIStateActions } from '.'

export default reducerWithInitialState(UIInitialState)
  .case(UIStateActions.setEnableMobileMenu, (state, enableMobileMenu) => {
    return {
      ...state,
      enableMobileMenu,
    }
  })
  .case(UIStateActions.setEnableMobileMenuSmall, (state, enableMobileMenuSmall) => {
    return {
      ...state,
      enableMobileMenuSmall,
    }
  })
  .case(UIStateActions.setEnableClosedSidebar, (state, enableClosedSidebar) => {
    return {
      ...state,
      enableClosedSidebar,
    }
  })
