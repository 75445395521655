import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUserQuery, usePoliciesQuery, useAgreementsQuery } from './gql/graphql-client-api'
import { LoggedInUserActions, SelectLoggedInUser } from './store/loggedInUser'
import { IamApolloClient } from './withApollo'
import { Abac, AbacContext } from '@gunosy/media-admin-components'
import { toast } from 'react-toastify'
import * as firebase from 'firebase/app'

const withAuthZ = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => (props: P) => {
  const dispatch = useDispatch()
  const loggedInUser = useSelector(SelectLoggedInUser)
  const userQ = useUserQuery({
    variables: {
      userID: loggedInUser.id,
    },
    skip: loggedInUser.id == '',
    client: IamApolloClient,
  })
  useEffect(() => {
    if (userQ.data) {
      dispatch(
        LoggedInUserActions.setLoggedInUser({
          roles: userQ.data.user.roles,
          organizationId: userQ.data.user.organization.id,
        })
      )
    }
  }, [userQ.data])
  const policyQ = usePoliciesQuery({
    client: IamApolloClient,
  })
  useEffect(() => {
    if (policyQ.data) {
      const policies = policyQ.data ? (policyQ.data.policies as Abac.Policy[]) : []
      const abac = new Abac.Abac(policies)
      dispatch(LoggedInUserActions.setLoggedInUser({ abac }))
    }
  }, [policyQ.data])
  useEffect(() => {
    if (policyQ.error) {
      const { graphQLErrors, networkError } = policyQ.error
      console.log('onError: ', graphQLErrors, networkError)
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          switch (err.extensions?.code.toUpperCase()) {
            case 'BAD_REQUEST':
              toast.warn(`リクエストが不正です: ${err.extensions.message}`)
              break
            case 'FORBIDDEN':
              alert(`権限がありません`)
              firebase.auth().signOut()
              window.location.href = `/sso#/signin`
              return
              break
            case 'INTERNAL_SERVER_ERROR':
              toast.error(`サーバーエラーが発生しました: ${err.extensions.message}`)
              break
          }
        }
      } else if (networkError) {
        toast.warn(`通信エラー`)
      }
    }
  }, [policyQ.error])
  const agreementsQ = useAgreementsQuery({
    variables: {
      param: {
        userUid: loggedInUser.id,
      },
      limit: 100, // NOTE: これ以上増える想定はないが、増えるのであれば設計自体を見直す.
      offset: 0,
    },
    skip: loggedInUser.id == '',
  })
  useEffect(() => {
    if (agreementsQ.data) {
      dispatch(LoggedInUserActions.setLoggedInUser({ agreementIds: agreementsQ.data.agreements.nodes.map(a => a.id) }))
    }
  }, [agreementsQ.data])

  // すべて揃ったら画面描画開始
  useEffect(() => {
    if (userQ.data && policyQ.data && agreementsQ.data) {
      dispatch(LoggedInUserActions.setLoggedInUser({ isStateReady: true }))
    }
  }, [userQ.data, policyQ.data, agreementsQ.data])

  return (
    <AbacContext.Provider value={loggedInUser.abac}>
      <Component {...props} />
    </AbacContext.Provider>
  )
}

export default withAuthZ
