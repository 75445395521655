import { Abac } from '@gunosy/media-admin-components'

export interface UserState {
  id: string // ユーザーid
  email: string // メールアドレス
  roles: string[] // ロール
  abac: Abac.Abac // ポリシー
  photoURL?: string | null
  hasEmailPassAccount: boolean // providerId: "password" があるか? => メアド/パスワード変更を用意する必要がある
  organizationId: number
  agreementIds: number[]
  isStateReady: boolean // policy/role/agreementIdsの初回取得が終わったらtrue (= 画面描画するタイミング)
}

export const UserInitialState: UserState = {
  id: '',
  email: '',
  roles: [],
  abac: new Abac.Abac([] as Abac.Policy[]),
  photoURL: null,
  hasEmailPassAccount: false,
  organizationId: 0,
  agreementIds: [],
  isStateReady: false,
}
