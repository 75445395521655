import actionCreatorFactory from 'typescript-fsa'

import { UserState } from '.'
import { Optional } from '../../utils'

const actionCreator = actionCreatorFactory()

export const LoggedInUserActions = {
  setLoggedInUser: actionCreator<Optional<UserState>>('SET_LOGGED_IN_USER'),
}
