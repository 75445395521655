export default {
  appTitlePrefix: '',
  firebaseConfig: {
    apiKey: 'AIzaSyDfWXHjUbpN95_TqTfiXY9OnhnMxLluMbo',
    authDomain: 'admin.coupon.gunosy.link',
    databaseURL: 'https://prd-media-admin.firebaseio.com',
    projectId: 'prd-media-admin',
    storageBucket: 'prd-media-admin.appspot.com',
    messagingSenderId: '822334581195',
    appId: '1:822334581195:web:3275bfb7ec94a3c2',
  },
  googleOAuthClientID:
    '822334581195-2np3e01rjrs4g127697jgkq5fvqmiiqu.apps.googleusercontent.com',
};
