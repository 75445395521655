import React from 'react';
import { CustomInput } from 'reactstrap';

interface Props {
  value: Period
  onChange: (key: string) => void
}
export enum Period {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

const periodOptions = [
  { label: '日別', value: Period.Daily },
  { label: '週別', value: Period.Weekly },
  { label: '月別', value: Period.Monthly },
]

const PeriodSelector: React.FC<Props> = (props: Props) => {
  return (
    <CustomInput
      id="custom-inp-top"
      type="select"
      value={props.value}
      onChange={v => {
        props.onChange(v.target.value)
      }}
    >
      {periodOptions.map(o => (
        <option value={o.value}>{o.label}</option>
      ))}
    </CustomInput>
  )
}

export default PeriodSelector
