import cx from 'classnames'
import React, { Fragment } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { SelectUIState, UIStateActions } from '../../store/uiState'

interface Props {
  NavContent: any
}

const AppSidebar: React.FC<Props> = ({ NavContent }: Props) => {
  const dispatch = useDispatch()
  const uiState = useSelector(SelectUIState)
  const {
    enableMobileMenu,
    enableBackgroundImage,
    backgroundImage,
    backgroundImageOpacity,
    backgroundColor,
    enableSidebarShadow,
  } = uiState

  return (
    <Fragment>
      <div
        className="sidebar-mobile-overlay"
        onClick={() => dispatch(UIStateActions.setEnableMobileMenu(!enableMobileMenu))}
      />
      <ReactCSSTransitionGroup
        component="div"
        className={cx('app-sidebar', backgroundColor, { 'sidebar-shadow': enableSidebarShadow })}
        transitionName="SidebarAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PerfectScrollbar>
          <div className="app-sidebar__inner">
            <NavContent />
          </div>
        </PerfectScrollbar>
        <div
          className={cx('app-sidebar-bg', backgroundImageOpacity)}
          style={{
            backgroundImage: enableBackgroundImage ? 'url(' + backgroundImage + ')' : undefined,
          }}
        ></div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}

export default AppSidebar
