import React from 'react'
import Loader from 'react-loaders'

const PageLoader: React.FC = () => (
  <div className="loader-container">
    <div className="loader-container-inner">
      <div className="text-center">
        <Loader type="ball-grid-beat" active />
      </div>
      <h6 className="mt-3">
        読み込み中
      </h6>
    </div>
  </div>
)

export default PageLoader
