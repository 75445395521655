import { Abac } from '@gunosy/media-admin-components'

export const User = new Abac.Resource('iam:User')
export const UserRole = new Abac.Resource('iam:UserRole')
export const Org = new Abac.Resource('iam:Org')

export const Agreement = new Abac.Resource('insight:Agreement')
export const AgreementPublisherRule = new Abac.Resource('insight:AgreementPublisherRule')
export const Publisher = new Abac.Resource('insight:Publisher')
export const Feed = new Abac.Resource('insight:Feed')
export const MediumArticle = new Abac.Resource('insight:MediumArticle')
export const MonthlyAgreementSnapshot = new Abac.Resource('insight:MonthlyAgreementSnapshot')
export const MonthlyPayment = new Abac.Resource('insight:MonthlyPayment')
export const MonthlyPublisherPayment = new Abac.Resource('insight:MonthlyPublisherPayment')
export const MonthlyAgreementPublisherRuleSnapshot = new Abac.Resource('insight:MonthlyAgreementPublisherRuleSnapshot')
export const Announce = new Abac.Resource('insight:Announce')
export const PublisherRank = new Abac.Resource('insight:PublisherRank')
export const BankAccount = new Abac.Resource('insight:BankAccount')
export const AgreementBankAccount = new Abac.Resource('insight:AgreementBankAccount')
export const SummaryStats = new Abac.Resource('insight:SummaryStats')
export const ArticleStats = new Abac.Resource('insight:ArticleStats')
export const VideoStats = new Abac.Resource('insight:VideoStats')
export const PaymentRate = new Abac.Resource('insight:PaymentRate')
export const InhouseRevenueDetail = new Abac.Resource('insight:InhouseRevenueDetail')
export const InhouseAgreementField = new Abac.Resource('insight:InhouseAgreementField')
export const InhouseNote = new Abac.Resource('insight:InhouseNote')
export const Dividend = new Abac.Resource('insight:Dividend')

export const Field__Inhouse = new Abac.Resource('insight:Field__Inhouse')
