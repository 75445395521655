// 比較
export function isAfter(base: any, target: any): boolean {
  return new Date(base).getTime() < new Date(target).getTime()
}
export function isBefore(base: any, target: any): boolean {
  return new Date(base).getTime() > new Date(target).getTime()
}
export function Equal(base: any, target: any): boolean {
  return new Date(base).getTime() === new Date(target).getTime()
}

// 月初を取得
export function getFirstDayOfMonth(dt: Date = new Date()): Date {
  return new Date(dt.getFullYear(), dt.getMonth(), 1)
}
// 月末を取得
export function getLastDayOfMonth(dt: Date = new Date()): Date {
  return new Date(dt.getFullYear(), dt.getMonth() + 1, 0)
}
// 昨月初を取得
export function getFirstDayOfLastMonth(dt: Date = new Date()): Date {
  let ret = new Date(dt.getFullYear(), dt.getMonth() - 1, 1)
  // リリース日前には遡れない
  const reDate = new Date(ReleaseDateTime)
  if (reDate.getTime() > ret.getTime()) {
    ret = reDate
  }

  return ret
}

export const ReleaseMonth = '2020-12'
export const ReleaseDate = '2020-12-01'
export const ReleaseDateTime = '2020-12-01 00:00:00'
const OneDayMillisec = 1000 * 60 * 60 * 24
const LastDayDataReadyTime = 1000 * 60 * 60 * 7 // 昨日のデータが準備される時間(昨日分の集計を毎朝6時に集計するので7時以降から昨日分を検索できるようにする)

// 日付レンジ取得
export function getInitialDateRange(daysAgo: number = 7): [Date, Date] {
  const yesterday = new Date(new Date().getTime() - LastDayDataReadyTime)
  const yesterdayMidnight = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0)
  let endDate = new Date(yesterdayMidnight.getTime() - 1) // 23:59:59
  let startDate = new Date(yesterdayMidnight.getTime() - OneDayMillisec * daysAgo)

  // リリース日前には遡れない
  const reDate = new Date(ReleaseDateTime)
  if (reDate.getTime() > startDate.getTime()) {
    startDate = reDate
    endDate = new Date(startDate.getTime() + OneDayMillisec * daysAgo)
  }

  return [startDate, endDate]
}
