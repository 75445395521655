import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient from 'apollo-boost'
import * as firebase from 'firebase/app'
import { toast } from 'react-toastify'

export function getToken(): Promise<string> {
  const user = firebase.auth().currentUser
  if (user == null) {
    throw new Error('session expired')
  }

  return user.getIdToken().then((token: string) => token)
}

function createApolloClient(uri?: string): ApolloClient<unknown> {
  const client = new ApolloClient({
    uri,
    credentials: 'include',
    headers: {
      'X-Client-Version': process.env.REACT_APP_BUILD_VERSION || 'local',
    },
    request: async operation => {
      const token = await getToken()
      operation.setContext({
        headers: {
          // Authorization
          Authorization: `Bearer ${token}`,
        },
      })
    },
    // Apollo Boost allows you to specify a custom error link for your client
    onError: ({ graphQLErrors, networkError, operation, forward }) => {
      // FIXME: ちゃんとエラーハンドルする
      console.log('onError: ', graphQLErrors, networkError)
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          switch (err.extensions?.code) {
            case 'BAD_REQUEST':
              toast.warn(`リクエストが不正です: ${err.extensions.message}`)
              break;
            // NOTE: graphqErrorHandler で出す (パブリッシャ編集者だと403リクエストするタイミングが多いため)
            // case 'FORBIDDEN':
            //   toast.warn(`権限がありません: ${err.extensions.message}`)
            //   break;
            case 'INTERNAL_SERVER_ERROR':
              console.error(err.extensions.message)
              toast.error(`サーバーエラーが発生しました: ${err.extensions.message}`)
              break;
          }
        }
      } else if(networkError) {
        toast.warn(`通信エラー`)
      }
    },
  })
  client.defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
  }
  return client
}
const client = createApolloClient(process.env.REACT_APP_GRAPHQL_INSIGHT_ENDPOINT)
export const IamApolloClient = createApolloClient(process.env.REACT_APP_GRAPHQL_IAM_ENDPOINT)

const withApollo = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => (props: P) => (
  <ApolloProvider client={client}>
    <Component {...props} />
  </ApolloProvider>
)

export default withApollo
