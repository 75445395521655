import { Abac } from '@gunosy/media-admin-components'
import React, { Fragment } from 'react'
import MetisMenu from 'react-metismenu'
import { useDispatch, useSelector } from 'react-redux'
import { SelectIsInhouse, SelectLoggedInUser } from '../../store/loggedInUser'
import { UIStateActions } from '../../store/uiState'
import { Menu } from './NavItems'

const Nav: React.FC = () => {
  const dispatch = useDispatch()
  const loggedInUser = useSelector(SelectLoggedInUser)
  const isInhouse = useSelector(SelectIsInhouse)

  return (
    <Fragment>
      <h5
        className="app-sidebar__heading"
        style={{
          color: '#222',
          padding: '2px 0 8px',
          fontSize: '1rem',
          textTransform: 'initial',
        }}
      >
        {'Gunosy Publisher Center'}
      </h5>
      {Menu.map(ms => {
        const viewableItems = ms.items.filter(i => {
          if (!i.resource) {
            return true
          }
          const hide = i.hide ? i.hide : () => false
          return loggedInUser.abac.can(Abac.Action.List, i.resource) && !hide(isInhouse, loggedInUser)
        })
        if (viewableItems.length > 0) {
          return (
            <>
              <h5 className="app-sidebar__heading">{ms.title}</h5>
              <div
                onClick={() => {
                  dispatch(UIStateActions.setEnableClosedSidebar(false))
                  dispatch(UIStateActions.setEnableMobileMenu(false))
                }}
              >
                <MetisMenu
                  content={viewableItems}
                  activeLinkFromLocation
                  className="vertical-nav-menu"
                  iconNamePrefix=""
                  classNameStateIcon="pe-7s-angle-down"
                />
              </div>
            </>
          )
        }
      })}
    </Fragment>
  )
}

export default Nav
